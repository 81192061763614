import React, { useEffect } from "react";
import useDeviceDimensions from "hooks/useWindowDimensions";
import WrongDevice from "components/wrongDevice";
import { IS_PRODUCTION, IS_STAGING } from "constants/constants";
import { AnimatePresence } from "framer-motion";
import CacheBuster from "react-cache-buster";
import appDetails from "../package.json";
import { Route, Routes, useLocation } from "react-router-dom";
import ComponentLoader from "components/loaders/ComponentLoader";
import { mainRoutes } from "routes/main";
import ProtectedRoute from "utilities/ProtectedRoute";
import BottomNavigation from "components/bottomNavigation";
import useMainSocket, { AppSocket } from "sockets";
import { getAuthCookie } from "utilities/helpers";
import useUserLocation from "hooks/useLocation";
import { LocationStoreType, useLocationStore } from "zustandStore/locationStore";
import useGeocode from "hooks/useGeocode";
import Toast from "components/responsePopup/Toast";
const token = getAuthCookie();

const App: React.FC = (): any => {
  const { largerThanTablets } = useDeviceDimensions();
  const location = useLocation();
  const ENABLE_CACHE_BURSTER = IS_PRODUCTION || IS_STAGING;
  const {
    locationData,
    // handleLocation,
    lat,
    lng,
    speed,
    timestamp,
    accuracy,
    heading,
    error,
  } = useUserLocation();
  const { address, addressComponents } = useGeocode(lat, lng);
  const { setLocation }: LocationStoreType = useLocationStore();
  useMainSocket();
  useEffect(() => {
    setLocation({
      locationData,
      // handleLocation,
      lat,
      lng,
      speed,
      timestamp,
      accuracy,
      heading,
      error,
      address,
      addressComponents,
    });

    token &&
      AppSocket.on("connect", () => {
        console.log("Connected to socket server");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lng, address]);
  // const ENABLE_CACHE_BURSTER = true;
  if (largerThanTablets) {
    return <WrongDevice />;
  }
  return (
    <>
      <Toast />
      <AnimatePresence>
        <CacheBuster
          currentVersion={appDetails.version}
          isEnabled={ENABLE_CACHE_BURSTER}
          isVerboseMode={false}
          loadingComponent={<ComponentLoader />}
          metaFileDirectory={"."}
        >
          {/* <audio id="notifAudio" src={NotificationSound} /> */}
          <Routes location={location} key={location?.pathname}>
            {mainRoutes.map(
              ({ path, Component, id, isProtected, showBottomNav }) => (
                <Route
                  key={id}
                  path={path}
                  element={
                    <ProtectedRoute isProtected={isProtected}>
                      <Component />
                      <BottomNavigation isProtected={showBottomNav} />
                    </ProtectedRoute>
                  }
                  // exact={exact}
                />
              )
            )}
          </Routes>
        </CacheBuster>
      </AnimatePresence>
    </>
  );
};

export default App;

// {
//   "src": "logo192.png",
//   "type": "image/png",
//   "sizes": "192x192"
// },
