import React from "react";
import { ModalContent, ModalOverlay } from "./style";
import { Portal } from "react-portal";

const Modal = ({ children, showModal }: any) => {
  console.log({ showModal });
  return (
    <Portal>
      <ModalOverlay display={showModal ? "flex" : "none"}>
        <ModalContent fitContent={true}>{children}</ModalContent>
      </ModalOverlay>
    </Portal>
  );
};

export default Modal;
