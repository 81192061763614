import { useNavigate } from "react-router-dom";
import { BackDivArea, BackWithIconContainer } from "./style";
import { BACKICON } from "assets/assets";
import { TLink } from "globalStyles/triggerStyles/style";

interface BackWithIconTypes {
  backText: string;
  backUrl: string | any;
  linkIcon?: string;
  linkUrl?: string | any;
}

export function BackWithIcon({
  backText,
  backUrl,
  linkIcon,
  linkUrl,
}: BackWithIconTypes) {
  const navigate = useNavigate();
  return (
    <BackWithIconContainer>
      <BackDivArea style={{ margin: 0 }} onClick={() => navigate(backUrl)}>
        <img src={BACKICON} alt="back" />
        <h2>{backText}</h2>
      </BackDivArea>
      {linkIcon && (
        <TLink to={linkUrl}>
          <img src={linkIcon} alt="settingsIcon" />
        </TLink>
      )}
    </BackWithIconContainer>
  );
}
