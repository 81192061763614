import { useEffect, useState } from "react";
import { OTPContainer, OptionsArea, OtpHolder } from "./style";
import LogoHeader from "../logoHeader/LogoHeader";
import OtpInput from "react-otp-input";
import BrandColors from "globalStyles/utils/colors";
import LoadingButton from "components/loaders/MainLoadingButton";
import { useAuthStore } from "pages/authPages/store";
import { SIGNUP_URL } from "routes/frontend";
import useToast from "hooks/useToast";
// import { useNavigate } from "react-router-dom";
import useAppNavigator from "hooks/useAppNavigator";

export default function OTP() {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const auth: any = useAuthStore();
  const { toast } = useToast();
  // const navigate = useNavigate();
  const { appNavigator, browserState } = useAppNavigator();

  useEffect(() => {
    !auth.otp && appNavigator(SIGNUP_URL);
  }, [appNavigator, auth.otp]);

  function handleResendOtp() {
    auth.handleOtp(null, setLoading, null);
    return;
  }

  console.log({ browserState });

  function handleChangeDetails() {
    auth.setOtp(null);
    appNavigator(SIGNUP_URL);
    return;
  }

  return (
    <OTPContainer>
      <LogoHeader logoWidth={50} text="Verify your trigger account" />
      <h3>Please type in the 4-digit code we just sent to +234{browserState?.form?.phone}</h3>
      <OtpHolder>
        <OtpInput
          value={otp}
          onChange={(otp: string) => setOtp(otp)}
          shouldAutoFocus={true}
          numInputs={4}
          separator={<span> </span>}
          hasErrored={error}
          containerStyle={{
            marginRight: 10,
          }}
          errorStyle={{
            border: "2px solid #F44336",
          }}
          focusStyle={{
            border: `2px solid ${BrandColors.main}`,
          }}
          inputStyle={{
            width: 50,
            height: 60,
            backgroundColor: BrandColors.background,
            borderRadius: 8,
            border: "1px solid #41474F",
            marginRight: 10,
            color: "#ffff",
            fontSize: 30,
          }}
        />
        <LoadingButton
          disabled={otp.length < 4}
          buttonText="ALMOST DONE!"
          onClickFn={(e: any) =>
            auth.handleSignUp(e, setLoading, setError, otp, appNavigator, toast)
          }
          loading={loading}
        />
        <OptionsArea>
          <p onClick={handleResendOtp}>Resend code</p>
          {/* <SpanLink to={SIGNUP_URL}> */}
          <p onClick={handleChangeDetails}>Change details</p>
          {/* </SpanLink> */}
        </OptionsArea>
      </OtpHolder>
    </OTPContainer>
  );
}
