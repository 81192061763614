export const handleOpenFilePicker = async (e: any) => {
  try {
    e.preventDefault();
    const file = (await document.getElementById("file")) as HTMLInputElement;
    file.click();
  } catch (err) {
    console.clear();
  }
}; 
export const ImagePicker = ({ setImage, setCamera }: any) => {
  const handleFileInputChange = async (event: any) => {
    event.preventDefault();
    const file: File = await event.target.files[0];
    if (file?.size > 1024 * 6024) {
      alert('File size exceeds the limit of 6MB');
      return;
    }
    const imageUrl = URL.createObjectURL(file);
    setImage({ imageUrl, file });
    if (setCamera) {
      setCamera(false);
    }
  };

  return (
    <>
      <input
        type="file"
        id="file"
        style={{ display: "none" }}
        accept="image/*"
        onChange={(e) => handleFileInputChange(e)}
      />
    </>
  );
};