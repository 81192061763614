export default function ContactUncheckedIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 5C0 2.23858 2.23858 0 5 0H15C17.7614 0 20 2.23858 20 5V15C20 17.7614 17.7614 20 15 20H5C2.23858 20 0 17.7614 0 15V5Z"
        fill="#44A4BE"
      />
      <path
        d="M7.00148 10.0523C6.79366 9.81477 6.43265 9.79071 6.19515 9.99853C5.95764 10.2063 5.93357 10.5674 6.14139 10.8049L8.14139 13.0906C8.36333 13.3442 8.75545 13.3517 8.98687 13.1066L13.844 7.96379C14.0607 7.73435 14.0504 7.37269 13.8209 7.15599C13.5915 6.9393 13.2298 6.94963 13.0131 7.17907L8.58757 11.865L7.00148 10.0523Z"
        fill="white"
      />
    </svg>
  );
}
