import create, { StoreApi, UseBoundStore } from "zustand";
import {
  closeResponse,
  closeResponseTimeout,
  showBannerToast,
  showFailedToast,
  showInformationToast,
  showSuccessToast,
  showWarningToast,
} from "./actions";

export const toastObj = {
  isOpen: false,
  isError: false,
  heading: null,
  messages: [],
  // information || warning || error
  toastType: null,
  linkText: null,
  link: null,
};
const useToastStore: UseBoundStore<StoreApi<any>> = create(
  (set, get) => ({
    ...toastObj,
    closeResponse: () => closeResponse(set),
    closeResponseTimeout: () => closeResponseTimeout(get),
    showBannerToast: () => showBannerToast(set),
    showInformationToast: (
      header: string,
      message: string,
      linkText: string,
      link: string
    ) => showInformationToast(set, get, header, message, linkText, link),
    showWarningToast: (header: string, message: string) =>
      showWarningToast(set, get, header, message),
    showSuccessToast: (header: string, message: string) =>
      showSuccessToast(set, get, header, message),
    showFailedToast: (header: string, message: string) =>
      showFailedToast(set, get, header, message),
  })
);

export default useToastStore;
