import { useLocation, useNavigate } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import { GOOGLEAPIKEY } from "constants/constants";
import useGeocode from "hooks/useGeocode";
import {
  ActivityArea,
  ActivityImage,
  ActivityImageCircle,
  ActivityProfileArea,
  BackButtonArea,
  MapInfoAreaContainer,
} from "./style";
import { mapStyles } from "./mapstyle";
import { TimeArea } from "pages/alerts/style";
import { getTimeAgo } from "utilities/helpers";
import {
  BACKICON,
  CARACCIDENT,
  DANGERICON,
  FIREICON,
  GUNICON,
  PROFILEIMAGEICON,
  TIMEICON,
} from "assets/assets";
import { ActivityOptions } from "zustandStore/activityStore/type";
import { ALERT_TYPE } from "pages/alerts/data";

export const MapInfoArea = ({ text }: any) => {
  const location = useLocation();
  const { state } = location;
  const timeAgo: string = getTimeAgo(state.createdAt) + " ago";
console.log(text)
  function getActivityImage(): string {
    switch (state.type) {
      case ActivityOptions.FIRE_ACCIDENT:
        return FIREICON;
      case ActivityOptions.ARMED_ROBBERY:
        return GUNICON;
      case ActivityOptions.VEHICLE_ACCIDENT:
        return CARACCIDENT;
      default:
        return DANGERICON;
    }
  }

  const img = getActivityImage();
  const { addressComponents } = useGeocode(
    state.activityLocation[state.activityLocation.length - 1].lat,
    state.activityLocation[state.activityLocation.length - 1].lng
  );

  console.log({ state, addressComponents });
  return (
    <MapInfoAreaContainer style={{ color: "black" }}>
      {state.type !== ALERT_TYPE.LOCATION && (
        <ActivityArea>
          <ActivityImageCircle>
            <img src={img} alt="activity" />
          </ActivityImageCircle>
          <div>
            <h1>PANIC ALERT</h1>
            <TimeArea>
              <small>{timeAgo}</small>
              <img src={TIMEICON} alt="userIcon" />
            </TimeArea>
          </div>
        </ActivityArea>
      )}
      <ActivityProfileArea>
        <ActivityImage
          src={state?.createdBy?.profilePhoto?.secure_url || PROFILEIMAGEICON}
          alt="activity"
        />
        <div>
          <h1>{`${state.createdBy.firstName} ${state.createdBy.lastName}`}</h1>
          {addressComponents.length > 6 && (
            <h1>
              {addressComponents[1].short_name}, {addressComponents[5].long_name}
            </h1>
          )}
        </div>
      </ActivityProfileArea>
    </MapInfoAreaContainer>
  );
};

export default function PanicAlertMap() {
  const location = useLocation();
  const { state }: any = location;
  console.log({ state });
  const { address } = useGeocode(
    state.activityLocation[state.activityLocation.length - 1].lat,
    state.activityLocation[state.activityLocation.length - 1].lng
  );
  const navigate = useNavigate();
  const defaultProps = {
    center: {
      lng: state?.activityLocation[state.activityLocation.length - 1]?.lng,
      lat: state?.activityLocation[state.activityLocation.length - 1]?.lat,
    },
    zoom: 14,
  };

  const center = {
    lng: state?.activityLocation[state.activityLocation.length - 1]?.lng,
    lat: state?.activityLocation[state.activityLocation.length - 1]?.lat,
  };

  const handleApiLoaded = (map: google.maps.Map, maps: any) => {
    // use map and maps objects
    new maps.Marker({
      position: center,
      map: map,
    });

    // const marker = maps?.Marker?.Ne?.getplace;
    // console.log({ map, maps, marker: marker.position.lat() });
  };

  const mapOptions = {
    // disableDefaultUI: true,
    styles: mapStyles,
  };

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <BackButtonArea onClick={() => navigate(-1)}>
        <img src={BACKICON} alt="icon" />
      </BackButtonArea>
      <MapInfoArea text={address} />
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLEAPIKEY }} 
        defaultCenter={center}
        options={mapOptions}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      ></GoogleMapReact>
    </div>
  );
}
