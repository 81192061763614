import { PROFILEIMAGEICON } from "assets/assets";
import { useEffect, useState } from "react";
import { ContactsTypes } from "zustandStore/profile/type";
import { ContactsDiv, ContactsNameDiv } from "./style";
import useCircleStore from "zustandStore/circle";
import { CircleStoreType } from "zustandStore/circle/type";
import ContactUncheckedIcon from "../contacts/svgs/ContactUncheckedIcon";

export interface ContactProps extends ContactsTypes {
  isSelected?: boolean;
  showMarker?: boolean;
}

export default function Contact({
  isSelected = false,
  firstName,
  lastName,
  _id,
  showMarker = true,
}: ContactProps) {
  const [isChecked, setIsChecked] = useState(false);
  const { addToSelectedContacts, removeFromSelectedContacts }: CircleStoreType =
    useCircleStore();

  function toggleContact() {
    setIsChecked(!isChecked);
    if (!isChecked) {
      addToSelectedContacts(_id);
    } else {
      removeFromSelectedContacts(_id);
    }
  }

  useEffect(() => {
    if (isSelected) {
      toggleContact();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContactsDiv>
      {showMarker && (
        <div onClick={toggleContact}>
          {isChecked ? (
            <ContactUncheckedIcon />
          ) : (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="19"
                height="19"
                rx="4.5"
                stroke="#ABB7C6"
              />
            </svg>
          )}
        </div>
      )}
      <ContactsNameDiv>
        <img src={PROFILEIMAGEICON} alt="iconImage" />
        <h2>{`${firstName} ${lastName}`}</h2>
      </ContactsNameDiv>
    </ContactsDiv>
  );
}
