import styled from 'styled-components/macro';

export const LogoHeaderDiv = styled.div`
  width: 100%;
  color: white;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    margin-top: 20px;
  }
`;
