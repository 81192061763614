import { ALERT_TYPE, SendAlertPayloadType } from "pages/alerts/data";
import { useState } from "react";
import { SendCheckinAlert } from "sockets/useAlerts";

export enum CHECKIN_STATUS {
  PENDING = "pending",
  STARTED = "started",
  ENDED = "ended",
}

export interface AddCheckinType {
  message: string;
  receivers: any[];
  isActive?: CHECKIN_STATUS;
  endTime: Date;
  showLocation: boolean;
  firstLocation: any;
  pavingData: any[];
}

export default function useCheckin() {
  const [sendingRequest, setSendingRequest] = useState<boolean>(false);

  async function createCheckin(data: AddCheckinType) {
    const payload: SendAlertPayloadType = {
      data,
      alertType: ALERT_TYPE.CHECKIN_ALERT,
    };
    const res: boolean = await SendCheckinAlert(payload, setSendingRequest);
    console.log({ res });
    return res;
  }

  return {
    createCheckin,
    sendingRequest,
  };
}
