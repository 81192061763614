import { FEATURES } from "hooks/useFeatureGate";
import {
  ACTIVITY_URL,
  CHECKIN_URL,
  HOME_URL,
  MAP_URL,
  getProfileLink,
} from "routes/frontend";

const profileUrl = getProfileLink();
export const navbarData: any = [
  {
    id: 1,
    name: "HOME",
    path: HOME_URL,
    sub: HOME_URL,
    hasFeature: false,
    activeSvg: `<svg
        width="23"
        height="20"
        viewBox="0 0 23 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.531 9.97589V15.7129C18.531 17.5285 17.0595 19 15.244 19H7.0263C5.21074 19 3.73923 17.5285 3.73923 15.7129V9.97589M1 8.30278L11.2261 1L21.4522 8.30278"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="7.30005" y="15.4" width="7.2" height="1.8" rx="0.9" fill="white" />
      </svg>`,
    inactiveSvg: `<svg
                width="23"
                height="20"
                viewBox="0 0 23 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.531 9.97589V15.7129C18.531 17.5285 17.0595 19 15.244 19H7.0263C5.21074 19 3.73923 17.5285 3.73923 15.7129V9.97589M1 8.30278L11.2261 1L21.4522 8.30278"
                  stroke="#55677C"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <rect
                  x="7.30005"
                  y="15.4"
                  width="7.2"
                  height="1.8"
                  rx="0.9"
                  fill="#55677C"
                />
              </svg>`,
  },
  {
    id: 2,
    name: "ACTIVITY",
    path: ACTIVITY_URL,
    activeSvg: `<svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 2.99994V2.99994C5.22876 2.99994 3.34315 2.99994 2.17157 4.17151C1 5.34308 1 7.2287 1 10.9999V16.9999C1 17.9427 1 18.4142 1.29289 18.707C1.58579 18.9999 2.05719 18.9999 3 18.9999H9C12.7712 18.9999 14.6569 18.9999 15.8284 17.8284C17 16.6568 17 14.7712 17 10.9999V10.9999"
                  stroke="white"
                  strokeWidth="2"
                />
                <path
                  d="M6.00012 8.99982L12.0001 8.99982"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.00012 13.0001H9.00012"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.9999 7L15.9999 1M12.9999 4H18.9999"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>`,
    inactiveSvg: `<svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 2.99994V2.99994C5.22876 2.99994 3.34315 2.99994 2.17157 4.17151C1 5.34308 1 7.2287 1 10.9999V16.9999C1 17.9427 1 18.4142 1.29289 18.707C1.58579 18.9999 2.05719 18.9999 3 18.9999H9C12.7712 18.9999 14.6569 18.9999 15.8284 17.8284C17 16.6568 17 14.7712 17 10.9999V10.9999"
                  stroke="#55677C"
                  strokeWidth="2"
                />
                <path
                  d="M6.00012 8.99982L12.0001 8.99982"
                  stroke="#55677C"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.00012 13.0001H9.00012"
                  stroke="#55677C"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.9999 7L15.9999 1M12.9999 4H18.9999"
                  stroke="#55677C"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>`,
  },
  {
    id: 3,
    name: "MAP",
    path: MAP_URL,
    hasFeature: false,
    activeSvg: `<svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.3031 5.59694C3.3031 8.68129 6.2731 13.7647 8.59722 13.7647C10.9213 13.7647 13.8913 8.68129 13.8913 5.59694C13.8913 2.52 11.5376 0 8.59722 0C5.65687 0 3.3031 2.52106 3.3031 5.59694ZM11.7737 5.59694C11.7737 7.66588 9.44745 11.6471 8.59722 11.6471C7.74698 11.6471 5.42075 7.66588 5.42075 5.59694C5.42075 3.65929 6.86075 2.11765 8.59722 2.11765C10.3337 2.11765 11.7737 3.65929 11.7737 5.59694Z"
                  fill="white"
                />
                <path
                  d="M12.5465 8.47055C12.2657 8.47055 11.9964 8.359 11.7978 8.16043C11.5992 7.96186 11.4877 7.69255 11.4877 7.41173C11.4877 7.13091 11.5992 6.8616 11.7978 6.66303C11.9964 6.46446 12.2657 6.35291 12.5465 6.35291C13.2407 6.35303 13.9157 6.58054 14.4683 7.00064C15.0209 7.42074 15.4207 8.0103 15.6065 8.67914L17.0783 13.9733C17.2094 14.4452 17.2296 14.9411 17.1372 15.4221C17.0449 15.9032 16.8426 16.3564 16.5461 16.7462C16.2496 17.1361 15.867 17.4522 15.4281 17.6697C14.9892 17.8872 14.506 18.0002 14.0161 18H3.1759C2.68626 17.9999 2.20327 17.8866 1.76465 17.669C1.32602 17.4513 0.943652 17.1353 0.647395 16.7454C0.351138 16.3556 0.149018 15.9025 0.0568197 15.4216C-0.0353781 14.9407 -0.0151567 14.445 0.115905 13.9733L1.58661 8.67914C1.77246 8.01013 2.17241 7.42044 2.72524 7.00032C3.27806 6.5802 3.95332 6.3528 4.64767 6.35291C4.92849 6.35291 5.1978 6.46446 5.39637 6.66303C5.59494 6.8616 5.70649 7.13091 5.70649 7.41173C5.70649 7.69255 5.59494 7.96186 5.39637 8.16043C5.1978 8.359 4.92849 8.47055 4.64767 8.47055C4.41639 8.47065 4.1915 8.54647 4.00737 8.68643C3.82324 8.82639 3.69001 9.02279 3.62802 9.24561L2.15626 14.5397C2.11247 14.6972 2.1057 14.8626 2.1365 15.0231C2.1673 15.1835 2.23482 15.3347 2.33378 15.4647C2.43273 15.5948 2.56044 15.7001 2.70691 15.7726C2.85337 15.845 3.01462 15.8826 3.17802 15.8823H14.0183C14.1815 15.8822 14.3425 15.8444 14.4887 15.7719C14.6349 15.6993 14.7623 15.5939 14.861 15.4639C14.9597 15.3339 15.0271 15.1828 15.0578 15.0225C15.0884 14.8622 15.0816 14.697 15.0379 14.5397L13.5672 9.24561C13.5052 9.02262 13.3718 8.82609 13.1874 8.68611C13.0031 8.54613 12.778 8.47042 12.5465 8.47055Z"
                  fill="white"
                />
              </svg>`,
    inactiveSvg: `<svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.3031 5.59694C3.3031 8.68129 6.2731 13.7647 8.59722 13.7647C10.9213 13.7647 13.8913 8.68129 13.8913 5.59694C13.8913 2.52 11.5376 0 8.59722 0C5.65687 0 3.3031 2.52106 3.3031 5.59694ZM11.7737 5.59694C11.7737 7.66588 9.44745 11.6471 8.59722 11.6471C7.74698 11.6471 5.42075 7.66588 5.42075 5.59694C5.42075 3.65929 6.86075 2.11765 8.59722 2.11765C10.3337 2.11765 11.7737 3.65929 11.7737 5.59694Z"
                  fill="#55677C"
                />
                <path
                  d="M12.5465 8.47055C12.2657 8.47055 11.9964 8.359 11.7978 8.16043C11.5992 7.96186 11.4877 7.69255 11.4877 7.41173C11.4877 7.13091 11.5992 6.8616 11.7978 6.66303C11.9964 6.46446 12.2657 6.35291 12.5465 6.35291C13.2407 6.35303 13.9157 6.58054 14.4683 7.00064C15.0209 7.42074 15.4207 8.0103 15.6065 8.67914L17.0783 13.9733C17.2094 14.4452 17.2296 14.9411 17.1372 15.4221C17.0449 15.9032 16.8426 16.3564 16.5461 16.7462C16.2496 17.1361 15.867 17.4522 15.4281 17.6697C14.9892 17.8872 14.506 18.0002 14.0161 18H3.1759C2.68626 17.9999 2.20327 17.8866 1.76465 17.669C1.32602 17.4513 0.943652 17.1353 0.647395 16.7454C0.351138 16.3556 0.149018 15.9025 0.0568197 15.4216C-0.0353781 14.9407 -0.0151567 14.445 0.115905 13.9733L1.58661 8.67914C1.77246 8.01013 2.17241 7.42044 2.72524 7.00032C3.27806 6.5802 3.95332 6.3528 4.64767 6.35291C4.92849 6.35291 5.1978 6.46446 5.39637 6.66303C5.59494 6.8616 5.70649 7.13091 5.70649 7.41173C5.70649 7.69255 5.59494 7.96186 5.39637 8.16043C5.1978 8.359 4.92849 8.47055 4.64767 8.47055C4.41639 8.47065 4.1915 8.54647 4.00737 8.68643C3.82324 8.82639 3.69001 9.02279 3.62802 9.24561L2.15626 14.5397C2.11247 14.6972 2.1057 14.8626 2.1365 15.0231C2.1673 15.1835 2.23482 15.3347 2.33378 15.4647C2.43273 15.5948 2.56044 15.7001 2.70691 15.7726C2.85337 15.845 3.01462 15.8826 3.17802 15.8823H14.0183C14.1815 15.8822 14.3425 15.8444 14.4887 15.7719C14.6349 15.6993 14.7623 15.5939 14.861 15.4639C14.9597 15.3339 15.0271 15.1828 15.0578 15.0225C15.0884 14.8622 15.0816 14.697 15.0379 14.5397L13.5672 9.24561C13.5052 9.02262 13.3718 8.82609 13.1874 8.68611C13.0031 8.54613 12.778 8.47042 12.5465 8.47055Z"
                  fill="#55677C"
                />
              </svg>`,
  },
  {
    id: 4,
    name: "CHECK-IN",
    path: CHECKIN_URL,
    hasFeature: true,
    feature: FEATURES.CHECKIN,
    activeSvg: `<svg
                width="24"
                height="20"
                viewBox="0 0 24 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="11.8"
                  cy="10.6001"
                  r="8.39993"
                  stroke="white"
                  strokeWidth="2"
                />
                <path
                  d="M3.39998 1L1 3.39998"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M20.1999 1L22.5999 3.39998"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M8.19995 8.20009L11.6092 10.4729C11.7171 10.5449 11.8621 10.5224 11.9431 10.4211L14.1999 7.6001"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>`,
    inactiveSvg: `<svg
                width="24"
                height="20"
                viewBox="0 0 24 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="11.8"
                  cy="10.6001"
                  r="8.39993"
                  stroke="#55677C"
                  strokeWidth="2"
                />
                <path
                  d="M3.39998 1L1 3.39998"
                  stroke="#55677C"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M20.1999 1L22.5999 3.39998"
                  stroke="#55677C"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M8.19995 8.20009L11.6092 10.4729C11.7171 10.5449 11.8621 10.5224 11.9431 10.4211L14.1999 7.6001"
                  stroke="#55677C"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>`,
    // currentPath
  },
  {
    id: 5,
    name: "PROFILE",
    hasFeature: false,
    path: profileUrl,
    activeSvg: `<svg
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.914 19C17.4152 17.6038 16.316 16.37 14.7869 15.49C13.2579 14.61 11.3844 14.1331 9.45702 14.1331C7.52967 14.1331 5.65617 14.61 4.1271 15.49C2.59803 16.37 1.49884 17.6038 1 19"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <circle
                  cx="9.45714"
                  cy="5.37768"
                  r="4.37768"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>`,
    inactiveSvg: `<svg
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.914 19C17.4152 17.6038 16.316 16.37 14.7869 15.49C13.2579 14.61 11.3844 14.1331 9.45702 14.1331C7.52967 14.1331 5.65617 14.61 4.1271 15.49C2.59803 16.37 1.49884 17.6038 1 19"
                  stroke="#55677C"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <circle
                  cx="9.45714"
                  cy="5.37768"
                  r="4.37768"
                  stroke="#55677C"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>`,
    // currentPath
  },
].filter(Boolean);
