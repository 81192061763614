import styled from "styled-components/macro";
import BrandColors from "globalStyles/utils/colors";

type inputHolderType = {
  hasValue?: boolean;
  color?: string;
  type?: string;
};

export const DefaultInputDiv = styled.div<inputHolderType>`
  width: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  color: white;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  input {
    width: 100% !important;
    padding: 16px !important;
    border: 2px solid ${BrandColors.lightGrey} !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    height: unset !important;
    font-size: 16px;

    &.error {
      border: 2px solid ${BrandColors.error} !important;
      outline: none;
      font-weight: normal;
    }

    :focus {
      outline: 2px solid rgba(41, 12, 221, 0.5);
    }
  }
`;

export const InputHolder = styled.div<inputHolderType>`
  width: 100%;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  position: relative;
  border-radius: 9px;
  border: ${({ hasValue }) =>
    hasValue ? `2px solid ${BrandColors.main}` : `1px solid ${BrandColors.grey}`};

  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: grey;
    position: absolute;
    bottom: 12px;
    left: 20px;
  }

  img {
    position: absolute;
    right: 7px;
    cursor: pointer;
    /* background: white !important; */
    padding: 3px;
    margin-top: -4px;
    color: ${BrandColors.main};
  }

  input {
    background-color: ${BrandColors.background};
    border: none !important;
    color: ${({ color }) => (!color ? "white" : color)};
    padding-left: ${({ type }) => (type === "phone" ? "85px !important" : null)};

    :focus {
      outline: none;
    }
  }
`;

export const ErrMessage = styled.p`
  color: ${BrandColors.error};
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  text-align: center;
`;
