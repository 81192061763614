import {
  FoundNoUserText,
  InputArea,
  LoaderHolder,
  SearchInfoText,
  SearchInputContainer,
  SearchTabContainer,
} from "./style";
import { SEARCHICON, TYPINGSEARCHICON } from "assets/assets";
import Person from "./Person";
import ComponentLoader from "components/loaders/ComponentLoader";
import useSearchPeople from "hooks/useSearchPeople";

export default function SearchTab() {
  const {
    search,
    hasNoUser,
    loading,
    searchedUser,
    handleSearchInput,
    handleSearch,
    sendRequest,
    sendingRequest,
  } = useSearchPeople();
  return (
    <SearchTabContainer>
      <SearchInputContainer>
        <InputArea isTyping={!!search} onSubmit={handleSearch}>
          <input onChange={handleSearchInput} value={search} type="text" />
          <img
            onClick={handleSearch}
            src={!search ? SEARCHICON : TYPINGSEARCHICON}
            alt="search"
          />
        </InputArea>
      </SearchInputContainer>
      {!search && (
        <SearchInfoText>
          Type in the user ID of the person you’re trying to pair your account with
          above
        </SearchInfoText>
      )}
      {hasNoUser && <FoundNoUserText>Zero results found</FoundNoUserText>}
      {loading && (
        <LoaderHolder>
          <ComponentLoader />
        </LoaderHolder>
      )}
      {!loading && search && searchedUser.firstName && (
        <Person
          firstName={searchedUser.firstName}
          lastName={searchedUser.lastName}
          userName={searchedUser.userName}
          status={searchedUser.status}
          profilePhoto={searchedUser.profilePhoto}
          sendRequest={sendRequest}
          sendingRequest={sendingRequest}
          requestStatus={searchedUser.requestStatus}
        />
      )}
    </SearchTabContainer>
  );
}
