import { EMPTYICON } from 'assets/assets'
import { EmptyListContainer } from './style';

interface EmptyListType {
  label: string;
  description: string;
}

export default function EmptyList({label, description}: EmptyListType) {
  return (
    <EmptyListContainer>
      <img src={EMPTYICON} alt="empty" />
      <h2>{label}</h2>
      <p>{description}</p>
    </EmptyListContainer>
  )
}
