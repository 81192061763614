import {
  CONTENT_TYPES,
  makeGetRequestWithToken,
  makePostRequest,
} from "requests/requests";
import { GET_CONTACTS, UPDATE_PROFILE_IMAGE } from "routes/server";
import { ProfileStoreType } from "./type";
import { USER_PROFILE_KEY } from "constants/constants";
import { secureStorage } from "utilities/encryption";
import { ToastStoreType } from "zustandStore/toastStore/type";

export function setCurrentTab(set: Function, tabNumber: number | null) {
  set((prevState: any) => ({
    ...prevState,
    addPeople: {
      ...prevState.addPeople,
      currentTab: tabNumber,
    },
  }));
}

export function getContacts(setLoading: Function, set: Function) {
  setLoading(true);
  makeGetRequestWithToken(GET_CONTACTS)
    .then((res: any) => {
      setLoading(false);
      if (res.response.success) {
        set((prev: ProfileStoreType) => ({
          ...prev,
          contacts: res.response.data,
        }));
      }
    })
    .catch((err) => {
      setLoading(false);
      console.log({ err });
    });
}

export function uploadProfilePicture(
  set: Function,
  image: File,
  setLoading: Function,
  toast: ToastStoreType
) {
  setLoading(true);
  makePostRequest(UPDATE_PROFILE_IMAGE, { file: image }, CONTENT_TYPES.FILE)
    .then((res: any) => {
      setLoading(false);
      if (res.success) {
        toast.showSuccessToast(
          "Upload Success:",
          "your profile photo has been updated"
        );
        console.log({ res });
        const user = {
          ...res.data,
          pin: true,
        };
        secureStorage.setItem(USER_PROFILE_KEY, JSON.stringify(user));
        set((prev: ProfileStoreType) => ({
          ...prev,
        }));
      }
    })
    .catch((err) => {
      setLoading(false);
    });
}
