import ProfileHeader from "components/headers/ProfileHeader";
import {
  CirclesHolder,
  CreateCircleButton,
  EditButton,
  MainProfileArea,
  PaddedArea,
  PostArea,
  ProfileContainer,
  ProfileImageArea,
  ProfileRecord,
  ProfileRecordArea,
} from "./style";
import { ADDICON, MINIQRCODE, PROFILEIMAGEICON } from "assets/assets";
import EmptyPost from "./EmptyPost";
import Posts from "./Posts";
import { useProfile } from "hooks/useProfile";
import { ADD_PEOPLE_URL, CIRCLES_URL, CONTACTS_URL, CREATE_CIRCLE_URL } from "routes/frontend";
import { TLink, TriggerLink } from "globalStyles/triggerStyles/style";
import ComponentLoader from "components/loaders/ComponentLoader";
import useActivity, { ACTIVITY_FETCH_TYPE } from "hooks/useActivity";
import { AddPeopleButton } from "components/headers/style";

export default function Profile() {
  const { profileData, contacts, circleCount } = useProfile({
    getUserContacts: true,
    getUserCircles: true,
  });
  const { myActivities, loading } = useActivity({ fetch: ACTIVITY_FETCH_TYPE.USER });

  function renderPosts() {
    if (loading && myActivities.length < 1) {
      return <ComponentLoader />;
    }

    if (!loading && myActivities.length < 1) {
      return <EmptyPost />;
    }

    return <Posts posts={myActivities} />;
  }

  console.log(myActivities)
  return (
    <ProfileContainer>
      <ProfileHeader />
      <MainProfileArea>
        <ProfileImageArea>
          <img
            src={profileData?.profilePhoto?.secure_url || PROFILEIMAGEICON}
            alt="user"
          />
          <div>
            <h2>{`${profileData?.lastName}, ${
              profileData?.firstName && profileData?.firstName[0]
            }`}</h2>
            <p>@{profileData?.userName}</p>
          </div>
        </ProfileImageArea>
        <ProfileRecordArea>
          <CirclesHolder>
            <TLink to={CONTACTS_URL}>
              <ProfileRecord>
                <h1>{contacts.length}</h1>
                <p>Contacts</p>
              </ProfileRecord>
            </TLink>
            <TLink to={CIRCLES_URL}>
              <ProfileRecord>
                <h1>{circleCount}</h1>
                <p>Circle</p>
              </ProfileRecord>
            </TLink>
          </CirclesHolder>
          <EditButton>
            <TLink to={ADD_PEOPLE_URL}>
              <AddPeopleButton>
                <p>Add people</p>
                <img src={MINIQRCODE} alt="qrcode" />
              </AddPeopleButton>
            </TLink>
          </EditButton>
        </ProfileRecordArea>
        <PaddedArea>
          <TriggerLink to={CREATE_CIRCLE_URL}>
            <CreateCircleButton>
              <img alt="add" src={ADDICON} />
              <p>CREATE CIRCLE</p>
            </CreateCircleButton>
          </TriggerLink>
        </PaddedArea>
      </MainProfileArea>
      <PostArea>{renderPosts()}</PostArea>
    </ProfileContainer>
  );
}
