import { TriggerLink } from "globalStyles/triggerStyles/style";
import { useLocation, useNavigate } from "react-router-dom";

export default function useAppNavigator() {
  const location = useLocation();
  const navigate = useNavigate();
  function appNavigator(link: any, props: any = {}) {
    navigate(link, {
      state: { email: "", ...location.state, ...props },
    });
  }

  function AppLink({ children, to, props }: any) {
    return (
      <TriggerLink to={to} state={{ ...location.state, ...props }}>
        {children}
      </TriggerLink>
    );
  }

  return {
    appNavigator,
    browserState: { email: "", ...location.state },
    currentPath: location.pathname,
    AppLink,
  };
}
