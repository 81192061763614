import { BELLICON } from "assets/assets";
import { MainHeaderContainer } from "./style";
import { ALERT_URL } from "routes/frontend";
import { Link } from "react-router-dom";

type MainHeaderProps = {
  pageLabel: string;
  showIcon?: boolean;
};

export default function MainHeader({ pageLabel, showIcon }: MainHeaderProps) {
  return (
    <MainHeaderContainer>
      <h3>{pageLabel}</h3>
      {showIcon === false ? null : (
        <Link to={ALERT_URL}>
          <img src={BELLICON} alt="bell-notic" />
        </Link>
      )}
    </MainHeaderContainer>
  );
}
