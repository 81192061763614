export const MapPointerIcon = () => {
  return (
    <svg
      width="55"
      height="66"
      viewBox="0 0 70 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_4774_360)">
        <circle cx="35" cy="31" r="25" fill="#44A4BE" />
        <path d="M35.5 66.5L18.7617 50H51.2617L35.5 66.5Z" fill="#44A4BE" />
      </g>
      <defs>
        <filter
          id="filter0_d_4774_360"
          x="0"
          y="0"
          width="70"
          height="80.5"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4774_360"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4774_360"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
