import create, { StoreApi, UseBoundStore } from "zustand";
import { getContacts, setCurrentTab, uploadProfilePicture } from "./action";
import { ProfileStoreType } from "./type";
import { ToastStoreType } from "zustandStore/toastStore/type";

const profileObj = {
  addPeople: {
    //0 = search and 1 = scan
    currentTab: 0,
  },
  contacts: [],
  basicDetails: {
    firstName: null,
    lastName: null,
    circles: null,
    userType: null,
    _id: null,
    createdAt: null,
    updatedAt: null,
    phone: null,
    userName: null,
  },
};

const useProfileStore: UseBoundStore<StoreApi<ProfileStoreType>> = create(
  (set, get) => ({
    ...profileObj,
    setCurrentTab: (tabNumber: number) => setCurrentTab(set, tabNumber),
    getContacts: (setLoading: Function) => getContacts(setLoading, set),
    uploadProfilePicture: (
      image: File,
      setLoading: Function,
      toast: ToastStoreType
    ) => uploadProfilePicture(set, image, setLoading, toast),
  })
);

export default useProfileStore;
