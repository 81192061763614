import { useEffect } from "react";
// import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import GoogleMapReact from "google-map-react";
// import useGeocode from "hooks/useGeocode";
// import { MARKER } from "assets/assets";
// import { mapStyles } from "./mapstyle";
import { LocationStoreType, useLocationStore } from "zustandStore/locationStore";
import { GOOGLEAPIKEY } from "constants/constants";
import { MapPointer } from "./svg/mapPointer";
import { useProfile } from "hooks/useProfile";
// import { mapStyles } from "./mapstyle";
// import useUserLocation from "hooks/useLocation";

export const AnyReactComponent = ({ text }: any) => (
  <div style={{ color: "black" }}>
    {/* <img width={30} src={MARKER} alt="marker" /> */}
  </div>
);

function NormalMap() {
  // const {
  //   // locationData,
  //   // handleLocation,
  //   lat,
  //   lng,
  //   // speed,
  //   // timestamp,
  //   // accuracy,
  //   // heading,
  //   error,
  // } = useUserLocation();
  const {lat, lng, error, address}: LocationStoreType = useLocationStore()
  console.log({ error, lat, lng });
  const { profileData } = useProfile();
  // const { address } = useGeocode(lat, lng);

  useEffect(() => {}, [lat, lng]);

  const center: any = {
    lat: lat,
    lng: lng,
  };

  const handleApiLoaded = (map: any, maps: any) => {
    // use map and maps objects
    const marker = maps?.Marker?.Ne?.getplace;
    console.log({ map, maps, marker });
  };

  const defaultProps = {
    center: {
      lng: lat,
      lat: lng,
    },
    zoom: 14,
  };

  if (error?.includes("denied")) {
    return <h1>User Denied Location</h1>;
  }
  // try {
  return (
    lat &&
    lng && (
      <div style={{ height: "100vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLEAPIKEY }}
          defaultCenter={center}
          // options={{ styles: mapStyles }}
          defaultZoom={defaultProps.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          <MapPointer
            profilePhoto={profileData?.profilePhoto?.secure_url}
            lat={center.lat}
            lng={center.lng}
            text={address}
          />
          {/* <AnyReactComponent lat={center.lat} lng={center.lng} text={address} /> */}
        </GoogleMapReact>
      </div>
    )
  );
}

export default NormalMap;
