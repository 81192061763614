import BrandColors from "globalStyles/utils/colors";
import styled from "styled-components/macro";
import { SIGNALVALUES } from "./SendLocation";

type HomeStyleProps = {
  signal?: SIGNALVALUES;
};

export const HomeContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${BrandColors.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  padding: 0px 27px;
  color: white;

  button {
    width: 100%;
    background-color: ${({ activate }: any) =>
      activate === "change" ? BrandColors.main : BrandColors.grey};
    border-radius: 8px;
    padding: 15px;
    border: none;
    color: ${BrandColors.white};
    margin-top: 50px;
    cursor: pointer;

    .changeBackground {
      background-color: ${BrandColors.main};
    }
  }

  h2 {
    margin-top: 20px;
  }
`;

export const LocationBottomContainer = styled.div`
  border-top: 2px solid #41474f;
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 18px;
`;

export const LocationArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SignalText = styled.p`
  color: ${({ signal }: HomeStyleProps) => {
    if (signal === SIGNALVALUES.BAD) {
      return "red";
    } else {
      return "green";
    }
  }};
  text-transform: uppercase;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  margin-top: 4px;
`;

export const LocationArea1 = styled.div`
  padding-left: 16px;
  h2 {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  h3 {
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 4px;
  }
`;

export const LocationArea2 = styled.div`
  display: flex;
  padding-right: 21px;
  img {
    margin-left: 24px;
  }
`;

export const SendLocationContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${BrandColors.background};
  display: flex;
  flex-direction: column;
  color: white;
`;

export const LocationButton = styled.button`
  border: 1px solid #41474f !important;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${BrandColors.background} !important;
`;

export const TriggerButtonArea = styled.div`
  position: relative;
`;

export const TriggerButtonImage = styled.img``;

export const TriggerImage = styled.img`
  position: absolute;
  width: 60px;
  margin: 0 auto;
  left: 37%;
  top: 37%;
`;

export const TriggerRecordImage = styled.img`
  position: absolute;
  width: 60px;
  margin: 0 auto;
  left: 39%;
  top: 42%;
`;

export const ShadeArea = styled.div`
  background: #2a3139;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  border-radius: 0px 0px 200px 200px;
  width: 100vw;
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
`;

export const RecordingDiv = styled.div`
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PanicText = styled.p`
  color: #44a4be;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
`;

export const PanicTextArea = styled.div`
  width: 100%;
  position: fixed;
  top: 17%;

  h3 {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const CancelRecordingButton = styled.div`
  position: fixed;
  top: 10px;
  right: 20px;
`;
