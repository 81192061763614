import styled from "styled-components";

export const EmptyListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40%;

  h2 {
    font-family: "Avenir";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #abb7c6;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  p {
    font-family: "Avenir";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #979797;
  }
`;