import React from "react";
import { ContactRequestContainer, ImageArea, NameArea, TimeArea } from "./style";
import { PROFILEIMAGEICON, TIMEICON } from "assets/assets";
import { getTimeAgo } from "utilities/helpers";
import { LocationText } from "pages/profile/style";
import { useNavigate } from "react-router-dom";
import { ACTIVITY_MAP_URL } from "routes/frontend";

export default function LocationRequest({ request }: any) {
  const { createdBy, createdAt, lng, lat, accuracy, type } = request;
  const timeAgo: string = getTimeAgo(createdAt);
  const navigate = useNavigate();
  const location = {
    lng: Number(lng),
    lat: Number(lat),
    accuracy: Number(accuracy),
  };

  function handleViewLocation() {
    navigate(ACTIVITY_MAP_URL, {
      state: {
        activityLocation: { ...location },
        message: "",
        type,
        createdBy,
        createdAt,
      },
    });
  }

  return (
    <ContactRequestContainer>
      <NameArea>
        <ImageArea>
          <img
            src={
              !createdBy.profilePhoto?.secure_url
                ? PROFILEIMAGEICON
                : createdBy.profilePhoto?.secure_url
            }
            alt="userIcon"
          />
          <h2>{`${createdBy.firstName} ${createdBy.lastName}`}</h2>
        </ImageArea>
        <TimeArea>
          <small>{timeAgo}</small>
          <img src={TIMEICON} alt="userIcon" />
        </TimeArea>
      </NameArea>
      <p>{`${createdBy.firstName} ${createdBy.lastName} just shared their location`}</p>
      <LocationText onClick={handleViewLocation}>View Location</LocationText>
    </ContactRequestContainer>
  );
}
