import { ActivityProps } from "pages/activity/Activity";

export type ShortTextDropdownType = {
  value: string;
  label: string;
};

export enum ActivityOptions {
  VEHICLE_ACCIDENT = "Vehicle Accident",
  DANGEROUS_ACTIVITY = "Dangerous Activity",
  FIRE_ACCIDENT = "Fire Accident",
  FOLLOWED = `I'm being followed`,
  ARMED_ROBBERY = "Armed Robbery",
  POLICE_ACTIVITY = "Unusual Police Activity",
}

export type ActivityStoreType = {
  postShortTextList: ShortTextDropdownType[];
  postShortText: string;
  postMainText: string;
  canSendPost: boolean;
  showPostField: boolean;
  activities: ActivityProps[];
  myActivities: ActivityProps[];
  fetchMyActivities: Function | any;
  fetchActivities: Function | any;
  turnOnCreatePost: Function | any;
  turnOffCreatePost: Function | any;
  handlePostWriting: Function | any;
  handleDropdownChange: Function | any;
  deactivatePostButton: Function | any;
  activatePostButton: Function | any;
  refreshActivityStore: Function | any;
};
