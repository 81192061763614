import {
  CancelRecordingButton,
  HomeContainer,
  LocationButton,
  PanicText,
  PanicTextArea,
  RecordingDiv,
  ShadeArea,
  TriggerButtonArea,
  TriggerButtonImage,
  TriggerImage,
  TriggerRecordImage,
} from "./style";
import MainHeader from "components/headers";
import {
  TRIGGERBUTTON,
  WHITETRIGGERLOGO,
  TRIGGERRECORDBUTTON,
  RECORDICON,
  // TRIGGERLOGO,
} from "assets/assets";
import { useEffect, useState } from "react";
// import addNotification from "react-push-notification";
import { useSecureStorage } from "hooks/useSecureStorage";
import { TRIGGER_BUTTON_KEY, USER_PROFILE_KEY } from "constants/constants";
import { TLink } from "globalStyles/triggerStyles/style";
import { SEND_LOCATION } from "routes/frontend";
import PinModal from "components/modals/PinModal";
import { useAuthStore } from "pages/authPages/store";
import { makePostRequest } from "requests/requests";
import {
  SERVER_START_TRIGGER_FEATURE,
  SERVER_STOP_TRIGGER_FEATURE,
} from "routes/server";
import useUserLocation from "hooks/useLocation";
import useToastStore from "zustandStore/toastStore";
import { ToastStoreType } from "zustandStore/toastStore/type";
// import { sendAlerts } from "sockets/useAlerts";

export function Recording({ cancelFn }: any) {
  return (
    <RecordingDiv>
      <CancelRecordingButton onClick={cancelFn}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none"
        >
          <path
            d="M60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30Z"
            fill="#F44336"
          />
          <path
            d="M37.6451 22.3671C37.1718 21.8938 36.4073 21.8938 35.934 22.3671L30 28.289L24.066 22.3549C23.5927 21.8817 22.8282 21.8817 22.3549 22.3549C21.8817 22.8282 21.8817 23.5927 22.3549 24.066L28.289 30L22.3549 35.934C21.8817 36.4073 21.8817 37.1718 22.3549 37.6451C22.8282 38.1183 23.5927 38.1183 24.066 37.6451L30 31.711L35.934 37.6451C36.4073 38.1183 37.1718 38.1183 37.6451 37.6451C38.1183 37.1718 38.1183 36.4073 37.6451 35.934L31.711 30L37.6451 24.066C38.1062 23.6049 38.1062 22.8282 37.6451 22.3671Z"
            fill="white"
          />
        </svg>
      </CancelRecordingButton>
      <PanicTextArea>
        <PanicText>PANIC ALERT ACTIVE</PanicText>
        <h3>{"<< BROADCASTING LIVE LOCATION & AUDIO >>"}</h3>
      </PanicTextArea>
    </RecordingDiv>
  );
}

export default function Home() {
  const [profile] = useSecureStorage(USER_PROFILE_KEY, "");
  const [isTriggerActive, setIsTriggerActive] = useSecureStorage(
    TRIGGER_BUTTON_KEY,
    false
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const authObj: any = useAuthStore();
  const toast: ToastStoreType = useToastStore();
  const { lat, lng, accuracy, speed, timestamp } = useUserLocation();

  function handleStopTrigger(pin: string) {
    console.log({ setLoading });
    setLoading(true);
    makePostRequest(SERVER_STOP_TRIGGER_FEATURE, { pin })
      .then((res: any) => {
        console.log({ res }, "stopping");
        setLoading(false);
        toast.showSuccessToast(
          "Success:",
          "We assume you're alright and we stopped watching and sharing your location"
        );
        authObj.openPinModal(false);
        setIsTriggerActive(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.error?.error) {
          toast.showFailedToast("ERROR:", err?.error?.error);
        }
        toast.showFailedToast("ERROR:", err?.error);
        console.log({ err });
      });
  }
  // console.log({ lat, lng, accuracy, speed, timestamp, locationData });

  function handleStartTrigger() {
    const payload = {
      location: [{ lat, lng, accuracy, speed, timestamp }],
    };
    console.log({ setLoading, payload });
    if (!payload.location[0].lat || payload.location[0].lat < 1) {
      toast.showWarningToast(
        "please turn on your location:",
        ""
      );
      console.log("no location detected");
      return;
    }
    setIsTriggerActive(true);
    makePostRequest(SERVER_START_TRIGGER_FEATURE, payload)
      .then((res) => {
        console.log({ res });
      })
      .catch((err) => {
        console.log({ err });
      });
  }

  function handleCancel() {
    console.log({ setLoading });
    authObj.openPinModal(true);
  }

  useEffect(() => {
    // addNotification({
    //   title: "Warning",
    //   subtitle: "This is a subtitle",
    //   message: "This is a very long message",
    //   icon: TRIGGERLOGO,
    //   theme: "red",
    //   vibrate: 5000,
    //   native: true, // when using native, your OS will handle theming.
    // });
  }, []);

  return (
    <HomeContainer>
      <PinModal
        clickAction={handleStopTrigger}
        isLoading={loading}
        error={error}
        setError={setError}
        buttonText="CANCEL PANIC ALERT"
      />
      {isTriggerActive && <Recording cancelFn={handleCancel} />}
      <MainHeader pageLabel={`Hi, ${profile.firstName}`} />
      <ShadeArea>
        <TriggerButtonArea>
          {isTriggerActive ? (
            <div>
              <TriggerButtonImage src={TRIGGERRECORDBUTTON} />
              <TriggerRecordImage src={RECORDICON} alt="logo" />
            </div>
          ) : (
            <div onClick={handleStartTrigger}>
              <TriggerButtonImage src={TRIGGERBUTTON} />
              <TriggerImage src={WHITETRIGGERLOGO} alt="logo" />
            </div>
          )}
        </TriggerButtonArea>
      </ShadeArea>
      <TLink style={{ width: "100%" }} to={SEND_LOCATION}>
        <LocationButton>SEND MY LOCATION</LocationButton>
      </TLink>
      {/* <button onClick={() => sendAlerts(profile.userName)}>Alert</button> */}
      {/* <button onClick={askNotification}>Log Out first Abeg</button> */}
      {/* <h2>Welcome to Trigger</h2>
      <h4>App Under Construction</h4> */}
    </HomeContainer>
  );
}
