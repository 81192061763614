import { useParams } from "react-router-dom";
import { ActivityUserProfileComponent } from "./profile";
import { ActivityUserProfileStyle, ProfileLoaderStyle } from "./style";
import { useGetUserProfile } from "hooks/useGetUserProfile";
import ComponentLoader from "components/loaders/ComponentLoader";
import { useEffect } from "react";

export const ActivityUserProfile = () => {
  const { user_name } = useParams();

  const { loading, userProfileData, fetchUserProfile } = useGetUserProfile(
    user_name as string
  );

  useEffect(() => {
    fetchUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(userProfileData);
  function renderUserProfile() {
    if (loading) {
      return (
        <ProfileLoaderStyle>
          <ComponentLoader />
        </ProfileLoaderStyle>
      );
    }

    return <ActivityUserProfileComponent userProfileData={userProfileData} />;
  }
  console.log(user_name);
  return <ActivityUserProfileStyle>{renderUserProfile()}</ActivityUserProfileStyle>;
};
