import React from "react";
import {
  ContactRequestContainer,
  ImageArea,
  NameArea,
  PanicAlertDiv,
  TimeArea,
} from "./style";
import { PROFILEIMAGEICON, TIMEICON } from "assets/assets";
import { getTimeAgo } from "utilities/helpers";
import { LocationText } from "pages/profile/style";
import { useNavigate } from "react-router-dom";
import { PANIC_MAP_URL } from "routes/frontend";

export default function TriggerRequest({ request }: any) {
  const { createdBy, createdAt, location, type } = request;
  const timeAgo: string = getTimeAgo(createdAt);
  const navigate = useNavigate();

  function handleViewLocation() {
    navigate(PANIC_MAP_URL, {
      state: {
        activityLocation: location,
        message: "",
        type,
        createdBy,
        createdAt,
      },
    });
  }
  console.log({ request });
  return (
    <ContactRequestContainer>
      <NameArea>
        <ImageArea>
          <img
            src={
              !createdBy.profilePhoto?.secure_url
                ? PROFILEIMAGEICON
                : createdBy.profilePhoto?.secure_url
            }
            alt="userIcon"
          />
          <h2>{`${createdBy.firstName} ${createdBy.lastName}`}</h2>
        </ImageArea>
        <TimeArea>
          <small>{timeAgo}</small>
          <img src={TIMEICON} alt="userIcon" />
        </TimeArea>
      </NameArea>
      {request.isActive && (
        <PanicAlertDiv>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
          >
            <path
              d="M1.8898 16H16.1102C17.5644 16 18.4708 14.4289 17.7438 13.1777L10.6336 0.93841C9.90648 -0.312803 8.09352 -0.312803 7.36645 0.93841L0.256242 13.1777C-0.47083 14.4289 0.43565 16 1.8898 16ZM9 9.41467C8.48066 9.41467 8.05575 8.99133 8.05575 8.47391V6.59239C8.05575 6.07497 8.48066 5.65162 9 5.65162C9.51934 5.65162 9.94425 6.07497 9.94425 6.59239V8.47391C9.94425 8.99133 9.51934 9.41467 9 9.41467ZM9.94425 13.1777H8.05575V11.2962H9.94425V13.1777Z"
              fill="#EE3C3C"
            />
          </svg>
        </PanicAlertDiv>
      )}
      <p>{`${createdBy.firstName} ${createdBy.lastName} just sent out a panic alert`}</p>
      <LocationText onClick={handleViewLocation}>View live Location</LocationText>
    </ContactRequestContainer>
  );
}
