import { TriggerLink } from "globalStyles/triggerStyles/style";
import { CreateCircleButton, PaddedArea } from "../style";
import { CircleContainer, CircleCounter, CircleHeader } from "./style";
import { CREATE_CIRCLE_URL, PROFILE_URL } from "routes/frontend";
import { ADDICON, BACKICON } from "assets/assets";
import { BackDivArea } from "components/headers/style";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useCircleStore from "zustandStore/circle";
import { CircleStoreType } from "zustandStore/circle/type";
import Circle from "./Circle";

export default function Circles() {
  const navigate = useNavigate();
  const { circleCount, circles, getCircles }: CircleStoreType = useCircleStore();
  useEffect(() => {
    getCircles();
  }, [getCircles]);

  return (
    <CircleContainer>
      <CircleHeader>
        <BackDivArea onClick={() => navigate(PROFILE_URL)}>
          <img src={BACKICON} alt="back" />
          <h2>Circles</h2>
        </BackDivArea>
        <CircleCounter>{circleCount}</CircleCounter>
      </CircleHeader>
      <PaddedArea>
        <TriggerLink to={CREATE_CIRCLE_URL}>
          <CreateCircleButton>
            <p>CREATE A NEW CIRCLE</p>
            <img alt="add" src={ADDICON} />
          </CreateCircleButton>
        </TriggerLink>
        <Circle showEdit={false} useDefaultImage={true} key={'everyone'} _id={'everyone'} members={[]} name={`Everyone`} />
        {circles.map((circle, i) => (
          <Circle useDefaultImage={false} key={i} _id={circle._id} members={circle.members} name={circle.name} />
        ))}
      </PaddedArea>
    </CircleContainer>
  );
}
