import { USER_PROFILE_KEY } from "constants/constants";
import io from "socket.io-client";
import { secureStorage } from "utilities/encryption";
import { getAuthCookie } from "utilities/helpers";
import useAlerts from "./useAlerts";
import useCheckinSocket from "./useCheckin";
import { SERVER_URL } from "routes/server";

const user = JSON.parse(secureStorage.getItem(USER_PROFILE_KEY));

export const AppSocket = io(`${SERVER_URL}`, {
  query: { token: `${getAuthCookie()}`, userId: user?._id },
});

export default function useMainSocket() {
  useAlerts();
  useCheckinSocket();
  return {};
}
