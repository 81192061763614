import styled from "styled-components/macro";
import OtpInput from "react-otp-input";

type pinStyleProps = {
  display?: any;
  fitContent?: any;
  almostDone?: any;
};

export const ModalContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  display: ${({ display }: pinStyleProps) => `${display}`};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 300;
  justify-content: flex-end;
`;

export const ModalContent = styled.div`
  height: ${({ fitContent }: pinStyleProps) =>
    fitContent ? "fit-content !important" : "60vh !important"};
  width: 100vw;
  background: #2A3139;
  /* border: 1px solid #ebebeb; */
  box-sizing: border-box;
  border-radius: 8px;
  margin: 0 auto;
  position: relative;
  /* bottom: -30%; */
  /* color: black; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
  padding: 24px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */
    padding: 15px;
    text-align: justify;
    color: #6a6a6a;
  }
  h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    /* or 112% */
    padding: 15px;
    text-align: center;
    color: #000000;
  }

  @media (max-width: 500px) {
    width: 95%;
    min-width: 230px;
    position: absolute;
    /* bottom: 0; */
    max-height: 95%;
    overflow: scroll;
  }
`;

export const ModalButton = styled.button`
  background: #6619d2;
  border-radius: 8px;
  width: 100%;
  color: white;
  padding: 20px;
  border: none;
  margin-top: 48px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  z-index: 200;
`;

export const BottomArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;

  h4 {
    color: #6619d2;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 900;
  display: ${({ display }: pinStyleProps) => `${display}`};
`;

export const ModalMainContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

export const ModalCancelIcon = styled.div`
  position: absolute;
  right: 20px;
  top: 10px;
  color: white;
`;

export const ShowPhoneInputAreaContainer = styled.div`
  width: 100%;

  h1 {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }

  p {
    color: #6a6a6a;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`;

export const ShowOtpContainer = styled.div`
  width: 100%;

  h1 {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }

  p {
    color: #6a6a6a;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`;

export const OtpHolder = styled.div`
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  text-align: center;

  small {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #eb5757;
    margin-top: 8px;
  }
`;

export const OTPInput = styled(OtpInput)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TimerText = styled.h6`
  color: ${({ almostDone }:pinStyleProps) => (!almostDone ? "#6a6a6a" : "#55677C")};
  background-color: transparent !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const TimerError = styled.h6`
  color: #f96a57;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

export const PinText = styled.h6`
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`;
