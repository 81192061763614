import { ActivityContainer } from "./style";
import CreatePostComponent from "./CreatePostComponent";
import EmptyActivity from "./EmptyActivity";
import MainHeader from "components/headers";
import ActivityList from "./ActivityList";
import ComponentLoader from "components/loaders/ComponentLoader";
import useActivity, { ACTIVITY_FETCH_TYPE } from "hooks/useActivity";

export default function Activity() {
  const { activities, loading } = useActivity({
    fetch: ACTIVITY_FETCH_TYPE.GENERAL,
  });

  function renderActivity() {
    if (loading && activities.length < 1) {
      return <ComponentLoader />;
    }

    if (!loading && activities.length < 1) {
      return <EmptyActivity />;
    }

    return <ActivityList activities={activities} />;
  }

  return (
    <ActivityContainer>
      <MainHeader pageLabel="Activity" />
      <CreatePostComponent />
      {renderActivity()}
    </ActivityContainer>
  );
}
