import create, { StoreApi, UseBoundStore } from "zustand";
import {
  addToSelectedContacts,
  createCircle,
  deleteCircle,
  getCircles,
  refreshSelectedContacts,
  removeFromSelectedContacts,
  setCircleLoading,
  updateCircle,
} from "./actions";
import { CircleProps, CircleStoreType } from "./type";

export const circleObj = {
  circles: [],
  selectedContacts: {},
  circleCount: 0,
  isLoading: false,
};

const useCircleStore: UseBoundStore<StoreApi<CircleStoreType>> = create(
  (set, get) => ({
    ...circleObj,
    getCircles: () => getCircles(set),
    createCircle: (body: CircleProps, navigate: any) =>
      createCircle(get, body, navigate),
    updateCircle: (body: CircleProps, navigate: any, id: string) =>
      updateCircle(get, body, navigate, id),
    deleteCircle: (navigate: any, id: string) => deleteCircle(get, navigate, id),
    setCircleLoading: (value: boolean) => setCircleLoading(set, value),
    addToSelectedContacts: (contactId: string) =>
      addToSelectedContacts(contactId, get, set),
    removeFromSelectedContacts: (contactId: string) =>
      removeFromSelectedContacts(contactId, get, set),
    refreshSelectedContacts: () => refreshSelectedContacts(set),
  })
);

export default useCircleStore;
