import { CircleContainer, CircleHeader, CreateButtonArea } from "./style";
import { useNavigate } from "react-router-dom";
import { CANCELICON } from "assets/assets";
import { BackDivArea } from "components/headers/style";
import { CIRCLES_URL } from "routes/frontend";
import DefaultInput from "components/defaultInput/DefaultInput";
import { useState } from "react";
import { PaddedArea } from "../style";
import EmptyList from "components/emptyList";
import LoadingButton from "components/loaders/MainLoadingButton";
import { useProfile } from "hooks/useProfile";
import Contact from "./Contact";
import useCircleStore from "zustandStore/circle";
import { CircleStoreType } from "zustandStore/circle/type";
import BrandColors from "globalStyles/utils/colors";

export default function CreateCircle() {
  const navigate = useNavigate();
  const [circleName, setCircleName] = useState("");
  const [circleNameErr, setCircleNameErr] = useState("");
  const { contacts } = useProfile({ getUserContacts: true });
  const { selectedContacts, createCircle, isLoading }: CircleStoreType =
    useCircleStore();
  let members = Object.keys(selectedContacts);

  function handleCreateCircle() {
    if (!circleName) {
      setCircleNameErr("Add the name of this circle");
      return;
    }
    const body = {
      name: circleName,
      members,
    };
    createCircle(body, navigate);
  }

  return (
    <CircleContainer>
      <CircleHeader>
        <BackDivArea onClick={() => navigate(CIRCLES_URL)}>
          <img src={CANCELICON} alt="cancel" />
          <h2>Create a circle</h2>
        </BackDivArea>
      </CircleHeader>
      <PaddedArea>
        <DefaultInput
          label=""
          inputTextColor={BrandColors.main}
          placeholder="Type name of circle here..."
          type="text"
          name="circleName"
          handlechange={(e: any) => setCircleName(e?.target?.value)}
          required={true}
          err={circleNameErr}
          value={circleName}
          handleFocus={() => setCircleNameErr("")}
        />
        {contacts.map((contact: any, i) => (
          <Contact
            key={i}
            firstName={contact.contact.firstName}
            lastName={contact.contact.lastName}
            _id={contact.contact._id}
          />
        ))}
        {contacts.length < 1 && (
          <EmptyList
            label="NO CONTACTS FOUND"
            description="please create your circle on this page"
          />
        )}
      </PaddedArea>
      <CreateButtonArea>
        <LoadingButton
          loading={isLoading}
          onClickFn={handleCreateCircle}
          disabled={Object.keys(selectedContacts).length < 2 || isLoading}
          buttonText="CREATE"
        />
      </CreateButtonArea>
    </CircleContainer>
  );
}
