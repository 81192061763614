import create, { StoreApi, UseBoundStore } from "zustand";

import { CheckinStoreType } from "./type";
import { handleTimePickerValueType } from "pages/checkin";
import { setTimer } from "./actions";

export const checkinObj = {
  members: [],
  selectedContacts: {},
  member: 0,
  isLoading: false,
  timer: {
    hrs:0,
    min:0,
    sec:0
  },
};

const useCheckInStore: UseBoundStore<StoreApi<CheckinStoreType>> = create(
  (set, get) => ({
    ...checkinObj,
    setTimer: (data: handleTimePickerValueType) => setTimer(set, data),
  })
);

export default useCheckInStore;
