const BrandColors = {
  background: "#2a3139",
  white: "#ffffff",
  textGrey: "#ABB7C6",
  borderGrey: "#41474F",
  main: "#44a4be",
  error: "#f44336",
  disabled: "#41474F",
  black: "#000000",
  grey: "#41474F",
  lightGrey: "#ebebeb",
};

export default BrandColors;
