import { EMPTYICON } from 'assets/assets'
import { EmptyPostContainer } from './style'

export default function EmptyPost() {
  return (
    <EmptyPostContainer>
      <img src={EMPTYICON} alt="empty" />
      <h2>NO POSTS FOUND</h2>
      <p>You can create a post on your activity page</p>
    </EmptyPostContainer>
  )
}
