import { EMPTYICON } from 'assets/assets'
import { EmptyPostContainer } from './style'

export default function EmptyPost() {
  return (
    <EmptyPostContainer>
      <img src={EMPTYICON} alt="empty" />
      <h2>NO POSTS FOUND</h2>
      <p>Looks like there are no posts on this user's timeline yet</p>
    </EmptyPostContainer>
  )
}
