import { makeGetRequestWithToken } from "requests/requests";
import { activityObj } from ".";
import { ActivityStoreType, ShortTextDropdownType } from "./type";
import { SERVER_GET_ACTIVITIES, SERVER_GET_MY_ACTIVITIES } from "routes/server";
import { totalText } from "pages/activity/CreatePostComponent";

export function turnOnCreatePost(set: Function) {
  set({
    showPostField: true,
  });
}

export function turnOffCreatePost(set: Function) {
  set({
    showPostField: false,
  });
}

export function activatePostButton(set: Function, get: Function) {
  const { postMainText, postShortText }: ActivityStoreType = get();
  if (
    postMainText.length > 1 &&
    postShortText.length > 1 &&
    postShortText !== "Post Category"
  ) {
    set({
      canSendPost: true,
    });
  }
}

export function deactivatePostButton(set: Function, get: Function) {
  const { postMainText, postShortText } = get();
  if (postMainText.length < 1 || postShortText.length < 1) {
    set({
      canSendPost: false,
    });
  }
}

export function handlePostWriting(event: any, set: Function, get: Function) {
  const state = get();
  const { deactivatePostButton, activatePostButton, postMainText } = state;
  deactivatePostButton();
  activatePostButton();
  if (postMainText.length <= totalText) {
    set({
      postMainText: event?.target.value,
    });
  }
}

export function handleDropdownChange(
  option: ShortTextDropdownType,
  set: Function,
  get: Function
) {
  const state = get();
  const { deactivatePostButton, activatePostButton } = state;
  set({
    postShortText: option.value,
  });
  activatePostButton();
  deactivatePostButton();
}

export function refreshActivityStore(set: Function) {
  set((prev: ActivityStoreType) => ({
    ...prev,
    ...activityObj,
  }));
}

export function fetchActivities(set: Function, get: Function, setLoading: Function) {
  const activities = get().activities;
  activities.length < 1 && setLoading(true);
  makeGetRequestWithToken(SERVER_GET_ACTIVITIES)
    .then((res: any) => {
      setLoading(false);
      // console.log({ jest: res });
      set((prev: ActivityStoreType) => ({
        ...prev,
        activities: [...res.data],
      }));
    })
    .catch((err) => {
      setLoading(false);
    });
}

export function fetchMyActivities(
  set: Function,
  get: Function,
  setLoading: Function
) {
  const myActivities = get().myActivities;
  myActivities.length < 1 && setLoading(true);
  makeGetRequestWithToken(SERVER_GET_MY_ACTIVITIES)
    .then((res: any) => {
      setLoading(false);
      set((prev: ActivityStoreType) => ({
        ...prev,
        myActivities: [...res.data],
      }));
    })
    .catch((err) => {
      setLoading(false);
    });
}

// user proflie 
// export function fetchUserProfile(
//   set: Function,
//   get: Function,
//   setLoading: Function,
//   user_profile: string
// ) {
//   const userprofile = get().userprofile;
//   userprofile.length < 1 && setLoading(true);
//   makeGetRequestWithToken(SERVER_GET_USER_PROFILE(user_profile))
//     .then((res: any) => {
//       setLoading(false);
//       set((prev: any) => ({
//         ...prev,
//         userprofile: [...res.data],
//       }));
//     })
//     .catch((err) => {
//       setLoading(false);
//     });
// }
