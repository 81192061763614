import BrandColors from "globalStyles/utils/colors";
import styled from "styled-components/macro";
import SwipeableViews from "react-swipeable-views";

type AddInputType = {
  isTyping: boolean;
};

type RequestButtonProps = {
  isNotRequested: boolean;
};

export const AddPeopleContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${BrandColors.background};
  display: flex;
  flex-direction: column;
  color: white;
  overflow-x: hidden;
  padding-bottom: 80px;
`;

export const AddPeopleTab = styled.div`
  min-height: 100%;
  min-width: 100%;
`;

export const TabChanger = styled(SwipeableViews)`
  height: 100%;
  width: 100%;
`;

export const SearchInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ScanTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #44a4be;
    margin-top: 23px;
    width: 85%;
  }
`;

export const QrCodeHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  background: #41474f;
  border-radius: 20px;
  padding: 60px 40px 30px 40px;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #44a4be;
    margin-top: 23px;
    width: 85%;
  }
`;

export const SignatureText = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  margin-top: 40px;
`;

export const InputArea = styled.form`
  margin-top: 140px;
  background: #41474f;
  border-radius: 6px;
  height: 50px;
  width: 100%;
  display: flex;
  padding: 0 9px;
  border: ${({ isTyping }: AddInputType) =>
    isTyping && `2px solid ${BrandColors.main}`};

  img {
    width: 20px;
    cursor: pointer;
  }

  input {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    color: white;

    :focus {
      outline: none;
    }
  }
`;

export const SearchTabContainer = styled.div`
  padding: 5px 27px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PersonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  width: 100%;
  height: calc(100vh - 350px);
  position: relative;

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    margin-top: 19px;
    margin-bottom: 6px;
    text-transform: uppercase;
  }

  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #c9d2de;
  }

  img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
  }
`;

export const RequestButton = styled.button`
  width: 100%;
  background: ${({ isNotRequested }: RequestButtonProps) =>
    isNotRequested ? BrandColors.main : BrandColors.background};
  border-radius: 6px;
  border: ${({ isNotRequested }: RequestButtonProps) =>
    isNotRequested ? "none" : "1px solid #41474F"};
  padding: 10px;
  color: ${({ isNotRequested }: RequestButtonProps) =>
    isNotRequested ? "white" : "#ABB7C6"};
  height: 50px;
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg{
    width: 20px;
  }
`;

export const LoaderHolder = styled.div`
  width: 100%;
  height: calc(100vh - 400px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchInfoText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #44a4be;
  margin-top: 23px;
  width: 85%;
`;

export const FoundNoUserText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #abb7c6;
  margin-top: 23px;
  width: 85%;
`;
