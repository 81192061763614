import SimpleBackHeader from "components/headers/SimpleBackHeader";
import ContactRequest from "./ContactRequest";
import { ALERT_TYPE } from "./data";
import { AlertContainer, AlertList } from "./style";
import EmptyList from "components/emptyList";
import useAlerts from "sockets/useAlerts";
import ComponentLoader from "components/loaders/ComponentLoader";
import LocationRequest from "./LocationRequest";
import TriggerRequest from "./TriggerRequest";

export default function Alerts() {
  const { alert, loading } = useAlerts();
  console.log({alert})
  return (
    <AlertContainer>
      <SimpleBackHeader headerLabel="Alerts" />
      {loading ? (
        <ComponentLoader />
      ) : (
        <AlertList>
          {alert?.alertMessages?.length > 0 ? (
            alert?.alertMessages?.map((alert: any, i: number) => {
              switch(alert.type){
                case ALERT_TYPE.CONTACT_REQUEST:
                  return <ContactRequest key={i} alert={alert} />;
                case ALERT_TYPE.LOCATION:
                  return <LocationRequest key={i} request={alert} />;
                case ALERT_TYPE.TRIGGER:
                  return <TriggerRequest key={i} request={alert} />;
                default:
                  return null
              }
            })
          ) : (
            <EmptyList
              label="NO ALERTS FOUND"
              description="All your notifications will appear here"
            />
          )}
        </AlertList>
      )}
    </AlertContainer>
  );
}
