import { ClearSelectionArea, PeopleNumber, SelectContactsContainer } from "../style";
import { BackDivArea } from "components/headers/style";
import { BACKICON } from "assets/assets";
import { ContactsTypes } from "zustandStore/profile/type";
import { CircleHeader } from "pages/profile/circles/style";
import { PaddedArea } from "pages/profile/style";
import SearchContact from "./SearchContact";
import EmptyList from "components/emptyList";
import Contact from "pages/profile/circles/Contact";
import { useMemo, useState } from "react";
import useCircleStore from "zustandStore/circle";

type SelectContactsProps = {
  setShowModal: Function;
  showModal: boolean;
  contacts: ContactsTypes[];
};

export default function SelectContacts({
  showModal,
  setShowModal,
  contacts,
}: SelectContactsProps) {
  const [searchName, setSearchName] = useState("");
  const { selectedContacts } = useCircleStore();
  const filteredContacts = useMemo(() => {
    return contacts.filter(({ contact }: any) => {
      const searchNameLower = searchName.toLowerCase();
      return (
        contact?.lastName?.toLowerCase().includes(searchNameLower) ||
        contact?.firstName?.toLowerCase().includes(searchNameLower) ||
        contact?.phone?.includes(searchName)
      );
    });
  }, [contacts, searchName]); 
  if (!showModal) {
    return <></>;
  }

  const contactLength = contacts.length;

  return (
    <SelectContactsContainer>
      <CircleHeader style={{ borderBottom: "none" }}>
        <BackDivArea onClick={() => setShowModal(false)}>
          <img src={BACKICON} alt="cancel" />
          <h2>Check In</h2>
        </BackDivArea>
      </CircleHeader>
      <PaddedArea>
        {contactLength > 0 && (
          <SearchContact searchName={searchName} setSearchName={setSearchName} />
        )}
        {contactLength < 1 && (
          <EmptyList
            label="NO CONTACTS FOUND"
            description="please add more people to your contact from profile"
          />
        )}
        {filteredContacts.map((contact: any, i) => (
          <Contact
            key={i}
            isSelected={selectedContacts[contact.contact._id]}
            firstName={contact.contact.firstName}
            lastName={contact.contact.lastName}
            _id={contact.contact._id}
          />
        ))}
      </PaddedArea>
      <ClearSelectionArea>
        <h3>CLEAR SELECTION</h3>
        <div>
          <PeopleNumber>{Object.keys(selectedContacts).length}</PeopleNumber>
          <button onClick={() => setShowModal(false)}>ADD</button>
        </div>
      </ClearSelectionArea>
    </SelectContactsContainer>
  );
}
