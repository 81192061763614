import BrandColors from "globalStyles/utils/colors";
import styled from "styled-components/macro";
import Select from "react-select";

type PostButtonType = {
  canSend: boolean;
};

export const ActivityContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${BrandColors.background};
  display: flex;
  flex-direction: column;
  color: white;
  overflow-x: hidden;
  padding-bottom: 80px;
`;

export const CreatePostArea = styled.div`
  padding-top: 70px;
  padding-left: 27px;
  padding-right: 27px;
`;

export const PostBottomArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 14px;
  padding-left: 48px;

  h4 {
    color: ${BrandColors.main};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
  }

  div {
    border-radius: 4px;
    background: #19414b;
    padding: 4px 8px;
    text-transform: capitalize;
  }
`;

export const CreatePostButtonDiv = styled.div`
  display: flex;
  height: 40px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 16px;
  }

  button {
    width: 100%;
    background: #41474f;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    color: #abb7c6;
  }
`;

export const CreatePostComponentDiv = styled.div`

  textArea {
    max-width: 100%;
    min-width: 100%;
    min-height: 100px;
    max-height: 100px;
    background: #41474f;
    border-radius: 6px;
    color: white;
    padding: 10px;

    :focus {
      outline: none;
    }
  }
`;

export const SelectDropdown = styled(Select)`
  width: 50%;
  background: none;

  .css-1jqq78o-placeholder {
    color: #abb7c6;
  }

  .css-13cymwt-control {
    background: ${BrandColors.background};
    border: 1px solid #abb7c6;
    border-radius: 4px;
  }

  .css-t3ipsp-control {
    background: ${BrandColors.background};
    border: 1px solid #abb7c6;
    border-radius: 4px;
    box-shadow: none;
  }

  .css-1dimb5e-singleValue {
    color: white;
  }

  .css-1nmdiq5-menu {
    background-color: ${BrandColors.background};
    width: 150%;
  }

  .css-d7l1ni-option {
    background: #41474f;
    color: #abb7c6;
  }

  .css-tr4s17-option {
    background: ${BrandColors.main};
  }

  .css-1u9des2-indicatorSeparator {
    display: none;
  }
`;

export const PostOptionArea = styled.div`
  width: 100%;
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
`;

export const PostButtonArea = styled.div`
  display: flex;
  width: 45%;

  div {
    border: 1px solid #abb7c6;
    border-radius: 4px;
    color: #abb7c6;
    display: flex;
    align-items: center;
    margin-right: 4px;
    width: 40%;
    justify-content: center;
  }
`;

export const SendPostButton = styled.button`
  background: ${({ canSend }: PostButtonType) =>
    canSend ? BrandColors.main : "#41474f"};
  border-radius: 4px;
  padding: 12px;
  color: ${({ canSend }: PostButtonType) => (canSend ? "white" : "#abb7c6")};
  border: none;
  width: 100%;
`;
