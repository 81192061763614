import React, { useEffect, useState } from "react";
import { ButtomText, Form, LoginContainer, SpanLink } from "./style";
import LogoHeader from "../logoHeader/LogoHeader";
import DefaultInput from "components/defaultInput/DefaultInput";
import LoadingButton from "components/loaders/MainLoadingButton";
import { useAuthStore } from "pages/authPages/store";
import { Space } from "globalStyles/triggerStyles/style";
import { SIGNUP_URL } from "routes/frontend";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [form, setForm] = useState({ email: "", password: "" });
  const [formErr, setFormErr] = useState({ email: null, password: null });
  const [isLoading, setIsLoading] = useState(false);
  const auth: any = useAuthStore();
  const { handleLogin, clearErrorOnFocus }: any = useAuthStore();
  const navigate = useNavigate();

  function handleChange(e: any) {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(() => {
    auth.setOtp(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoginContainer>
      <LogoHeader text="Enter your login details" />
      <Form>
        <DefaultInput
          label="Email"
          placeholder="E.g stomptheyard@address.com"
          type="text"
          name="email"
          handlechange={handleChange}
          required={true}
          err={formErr.email}
          value={form.email}
          handleFocus={() => clearErrorOnFocus(setFormErr, "email")}
        />
        <DefaultInput
          label="Password"
          placeholder="**********"
          type="password"
          name="password"
          handlechange={handleChange}
          required={true}
          err={formErr.password}
          value={form.password}
          handleFocus={() => clearErrorOnFocus(setFormErr, "password")}
        />
        <Space top="50px" />
        <LoadingButton
          disabled={isLoading}
          buttonText="Sign In"
          onClickFn={(e: any) =>
            handleLogin(e, form, setFormErr, setIsLoading, navigate)
          }
          loading={isLoading}
        />
      </Form>
      <ButtomText>
        YOU DON’T HAVE AN ACCOUNT? <SpanLink to={SIGNUP_URL}>SIGN UP</SpanLink>
      </ButtomText>
    </LoginContainer>
  );
}
