import useAppNavigator from "hooks/useAppNavigator";
import { SelectContactsContainer } from "pages/checkin/style";
import React, { useMemo, useState } from "react";
import useProfileStore from "zustandStore/profile";
import { CircleHeader } from "../circles/style";
import { BackDivArea } from "components/headers/style";
import { BACKICON } from "assets/assets";
import { PaddedArea } from "../style";
import SearchContact from "pages/checkin/selectContacts/SearchContact";
import EmptyList from "components/emptyList";
import Contact from "../circles/Contact";

export default function Contacts() {
  const { contacts } = useProfileStore();
  const { appNavigator } = useAppNavigator();
  const contactLength = contacts.length;
  const [searchName, setSearchName] = useState<string>("");
  const filteredContacts = useMemo(() => {
    return contacts.filter(({ contact }: any) => {
      const searchNameLower = searchName.toLowerCase();
      return (
        contact?.lastName?.toLowerCase().includes(searchNameLower) ||
        contact?.firstName?.toLowerCase().includes(searchNameLower) ||
        contact?.phone?.includes(searchName)
      );
    });
  }, [contacts, searchName]); // Dependencies: re-compute when contacts or searchName changes

  console.log({ contacts, filteredContacts });
  return (
    <SelectContactsContainer>
      <CircleHeader style={{ borderBottom: "none" }}>
        <BackDivArea onClick={() => appNavigator(-1)}>
          <img src={BACKICON} alt="cancel" />
          <h2>Contacts</h2>
        </BackDivArea>
      </CircleHeader>
      <PaddedArea>
        {contactLength > 0 && (
          <SearchContact searchName={searchName} setSearchName={setSearchName} />
        )}
        {contactLength < 1 && (
          <EmptyList
            label="NO CONTACTS FOUND"
            description="please add more people to your contact from profile"
          />
        )}
        {filteredContacts.map((contact: any, i) => (
          <Contact
            key={i}
            isSelected={false}
            showMarker={false}
            firstName={contact.contact.firstName}
            lastName={contact.contact.lastName}
            _id={contact.contact._id}
          />
        ))}
      </PaddedArea>
    </SelectContactsContainer>
  );
}
