/* eslint-disable react-hooks/exhaustive-deps */
import {
  CancelArea,
  ModalBackground,
  OptionTimerDiv,
  PickerSelectionContainer,
  TimeOptionArea,
  TimePickerContainer,
  TimePickerPopup,
} from "./style";
import { Portal } from "react-portal";
import { CANCELICON } from "assets/assets";
import LoadingButton from "components/loaders/MainLoadingButton";
import WheelComponent from "./WheelComponent";
import useCheckInStore from "zustandStore/checkIn";

type TimePickerSelectionProps = {
  isOpen: boolean;
  setIsOpen: Function;
  timeValue: Function;
};

function TimePickerSelection({
  isOpen,
  setIsOpen,
  timeValue,
}: TimePickerSelectionProps) {
  const { timer, setTimer } = useCheckInStore();
  function getHrsValue(hrsValue: number) {
    setTimer({
      ...timer,
      hrs: hrsValue.toString(),
    });
    timeValue(timer, setTimer);
  }

  function getMinValue(minValue: number) {
    setTimer({
      ...timer,
      min: minValue.toString(),
    });
    timeValue(timer, setTimer);
  }

  function getSecValue(secValue: number) {
    setTimer({
      ...timer,
      sec: secValue.toString(),
    });
    timeValue(timer, setTimer);
  }

  const handleSave = () => {
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <Portal>
          <TimePickerPopup>
            <div onClick={() => setIsOpen(!isOpen)} />
            <ModalBackground>
              <PickerSelectionContainer>
                <CancelArea>
                  <h3>CHECK-IN TIME</h3>
                  <img onClick={handleCancel} src={CANCELICON} alt="cancel" />
                </CancelArea>
                <TimeOptionArea>
                  <OptionTimerDiv>10 minutes</OptionTimerDiv>
                  <OptionTimerDiv>30 minutes</OptionTimerDiv>
                  <OptionTimerDiv>1 hour</OptionTimerDiv>
                </TimeOptionArea>
                <TimePickerContainer>
                  <WheelComponent
                    getSelectedNumber={getHrsValue}
                    numberLength={25}
                    number={timer.hrs}
                  />
                  <WheelComponent
                    getSelectedNumber={getMinValue}
                    numberLength={60}
                    number={timer.min}
                  />
                  <WheelComponent
                    getSelectedNumber={getSecValue}
                    numberLength={60}
                    number={timer.sec}
                  />
                </TimePickerContainer>
                <LoadingButton onClickFn={handleSave} buttonText="DONE" />
              </PickerSelectionContainer>
            </ModalBackground>
          </TimePickerPopup>
        </Portal>
      )}
    </>
  );
}

export default TimePickerSelection;
