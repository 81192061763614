import { useProfile } from "hooks/useProfile";
import { QrCodeHolder, ScanTabContainer, SignatureText } from "./style";
import { QRCode } from "react-qrcode-logo";
import { base_url } from "routes/frontend";

export default function ScanTab() {
  const { profileURL, profileData } = useProfile();
  const urlValue = `${base_url}${profileURL}`;
  return (
    <ScanTabContainer>
      <QrCodeHolder>
        <QRCode bgColor="#fff" value={urlValue} />
        <SignatureText>{`${profileData.lastName}, ${profileData.firstName[0]}`}</SignatureText>
      </QrCodeHolder>
      <p>
        Scan the QR code above to pair your trigger account with that of your family,
        friends and colleagues.
      </p>
    </ScanTabContainer>
  );
}
