import useToast from 'hooks/useToast';
import {
  FailedContainer,
  InformationContainer,
  LinkArea,
  MainWarningContainer,
  SuccessContainer,
  ToastHead,
  WarningHead,
} from './style';
import forward from 'assets/images/svgs/forward.svg';
export default function Toast() {
  const {toast}  = useToast();
  const { isOpen, heading, link, linkText, messages, isError, toastType }: any = toast;
  const isInformation = toastType === 'information';
  const isWarning = toastType === 'warning';
  const isSuccess = toastType === 'success';
  const isFailed = toastType === 'failed';

  if (isInformation) {
    return (
      <InformationContainer isError={isError} show={isOpen ? 'block' : 'none'}>
        {heading && (
          <WarningHead>
            <ToastHead>{heading}</ToastHead>
            <h2 onClick={() => toast.closeResponse()}>x</h2>
          </WarningHead>
        )}
        {messages.map((message: string, i: number) => (
          <div key={i}>
            <h5>{message}</h5>
            <br />
          </div>
        ))}
        {linkText && (
          <LinkArea>
            <a href={link}>
              <h4>{linkText}</h4>
            </a>
            <img src={forward} alt="arrow" />
          </LinkArea>
        )}
      </InformationContainer>
    );
  } else if (isWarning) {
    return (
      <MainWarningContainer showError={isError} show={isOpen ? 'block' : 'none'}>
        <WarningHead>
          <ToastHead>{heading}</ToastHead>
          <h2 onClick={() => toast.closeResponse()}>x</h2>
        </WarningHead>
        {messages.map((message: string, i: number) => (
          <div key={i}>
            <h5>{message}</h5>
            <br />
          </div>
        ))}
      </MainWarningContainer>
    );
  } else if (isSuccess) {
    return (
      <SuccessContainer isError={isError} show={isOpen ? 'block' : 'none'}>
        <WarningHead>
          <ToastHead>{heading}</ToastHead>
          <h2 onClick={() => toast.closeResponse()}>x</h2>
        </WarningHead>
        {messages.map((message: string, i: number) => (
          <div key={i}>
            <h5>{message}</h5>
            <br />
          </div>
        ))}
      </SuccessContainer>
    );
  } else if (isFailed) {
    return (
      <FailedContainer isError={isError} show={isOpen ? 'block' : 'none'}>
        <WarningHead>
          <ToastHead>{heading}</ToastHead>
          <h2 onClick={() => toast.closeResponse()}>x</h2>
        </WarningHead>
        {messages.map((message: string, i: number) => (
          <div key={i}>
            <h5>{message}</h5>
            <br />
          </div>
        ))}
      </FailedContainer>
    );
  } else {
    return null;
  }
}
