import { getRespondToRequestURL } from "routes/server";
import { ALERT_TYPE } from "./data";
import {
  ButtonArea,
  ContactRequestContainer,
  ImageArea,
  NameArea,
  TimeArea,
} from "./style";
import { PROFILEIMAGEICON, TIMEICON } from "assets/assets";
import { useState } from "react";
import { makePutRequestWithAxios } from "requests/requests";
import { getTimeAgo } from "utilities/helpers";

export enum ContactRequestReaction {
  Accept = "accept",
  Reject = "reject",
}

export enum LoadingEnum {
  start = "start",
  loading = "loading",
  end = "end",
  error = "error",
}

export interface ContactRequestType {
  alert: any;
}

export default function ContactRequest({ alert }: ContactRequestType) {
  const [loading, setLoading] = useState<LoadingEnum>(LoadingEnum.start);
  const [accept, setAccept] = useState<String>("Accept");
  const [decline, setDecline] = useState<String>("Decline");
  const { sender } = alert;
  const timeAgo: string = getTimeAgo(sender.createdAt);

  if (alert.type !== ALERT_TYPE.CONTACT_REQUEST) return null;
  const respondToRequest = (reaction: ContactRequestReaction) => {
    const url = getRespondToRequestURL(alert.request_id["_id"], reaction);
    const payload = {
      alertId: alert._id,
    };
    if (reaction === ContactRequestReaction.Accept) {
      setAccept("Accepting...");
    } else {
      setDecline("Declining...");
    }
    setLoading(LoadingEnum.loading);
    makePutRequestWithAxios(url, payload)
      .then((res) => {
        setLoading(LoadingEnum.end);
        if (reaction === ContactRequestReaction.Accept) {
          setAccept("Accepted");
        } else {
          setDecline("Declined");
        }
      })
      .catch((err) => {
        if (reaction === ContactRequestReaction.Accept) {
          setAccept("Accepted");
        } else {
          setDecline("Declined");
        }
        setLoading(LoadingEnum.end);
        console.log({ err });
      });
  };

  return (
    <ContactRequestContainer>
      <NameArea>
        <ImageArea>
          <img
            src={!sender.profilePhoto?.secure_url ? PROFILEIMAGEICON : sender.profilePhoto?.secure_url}
            alt="userIcon"
          />
          <h2>{`${sender.firstName} ${sender.lastName}`}</h2>
        </ImageArea>
        <TimeArea>
          <small>{timeAgo}</small>
          <img src={TIMEICON} alt="userIcon" />
        </TimeArea>
      </NameArea>
      <p>{`${sender.userName} sent you a contact request`}</p>
      <ButtonArea>
        <button
          disabled={loading === LoadingEnum.loading || loading === LoadingEnum.end}
          onClick={() => respondToRequest(ContactRequestReaction.Reject)}
        >
          {decline}
        </button>
        <button
          disabled={loading === LoadingEnum.loading || loading === LoadingEnum.end}
          onClick={() => respondToRequest(ContactRequestReaction.Accept)}
        >
          {accept}
        </button>
      </ButtonArea>
    </ContactRequestContainer>
  );
}
