import SimpleBackHeader from "components/headers/SimpleBackHeader";
import {
  AccountInformationArea,
  FieldArea,
  FieldListArea,
  ProfileUpdateArea,
  SettingsContainer,
} from "./style";
import { useProfile } from "hooks/useProfile";
import {
  ACCOUNTICON,
  EMAILICON,
  LOCATIONICON,
  PHONEICON,
  SHIELDICON,
  UPLOADICON,
} from "assets/assets";
import { useLocationStore } from "zustandStore/locationStore";
import { getShortAddress } from "utilities/helpers";
import { useState } from "react";
import { ImagePicker, handleOpenFilePicker } from "components/imagePicker";
import LoadingButton from "components/loaders/MainLoadingButton";
import { ToastStoreType } from "zustandStore/toastStore/type";
import useToastStore from "zustandStore/toastStore";

type ImageType = {
  imageUrl: string | null | undefined;
  file: File | null | any;
};

export function Field({ icon, label, data }: any) {
  return (
    <FieldArea>
      <img src={icon} alt="icon" />
      <div>
        <small>{label}</small>
        <h3>{data}</h3>
      </div>
    </FieldArea>
  );
}

export default function AccountInformation() {
  const { profileData, uploadProfilePicture } = useProfile({
    getUserContacts: false,
    getUserCircles: false,
  });
  const [image, setImage] = useState<ImageType>({
    imageUrl: undefined,
    file: {},
  });
  const [loading, setLoading] = useState(false);
  const { address } = useLocationStore();
  const toast: ToastStoreType = useToastStore();
  const fieldData = [
    {
      label: "Full Name",
      icon: ACCOUNTICON,
      data: `${profileData.lastName} ${profileData.firstName}`,
    },
    {
      label: "User ID",
      icon: SHIELDICON,
      data: `${profileData.userName}`,
    },
    {
      label: "Email",
      icon: EMAILICON,
      data: `${profileData.email}`,
    },
    {
      label: "Phone Number",
      icon: PHONEICON,
      data: `${profileData.phone}`,
    },
    {
      label: "Location",
      icon: LOCATIONICON,
      data: address ? getShortAddress(address) : "searching...",
    },
  ];

  return (
    <SettingsContainer>
      <SimpleBackHeader headerLabel="Account Information" />
      <AccountInformationArea>
        <ProfileUpdateArea>
          <ImagePicker setImage={setImage} />
          {image.imageUrl && (
            <img
              onClick={handleOpenFilePicker}
              src={image?.imageUrl}
              alt="profile"
            />
          )}

          {!image.imageUrl && profileData?.profilePhoto?.secure_url && (
            <img
              onClick={handleOpenFilePicker}
              src={profileData?.profilePhoto?.secure_url}
              alt="userP"
            />
          )}

          {!image.imageUrl && !profileData?.profilePhoto?.url && (
            <img onClick={handleOpenFilePicker} src={UPLOADICON} alt="userM" />
          )}

          <div>
            <h2>{`${profileData.lastName}, ${profileData.firstName[0]}`}</h2>
            <p>@{profileData.userName}</p>
          </div>
        </ProfileUpdateArea>
        <FieldListArea>
          {fieldData.map((field) => (
            <Field
              key={field.label}
              icon={field.icon}
              label={field.label}
              data={field.data}
            />
          ))}
        </FieldListArea>
        <div style={{ marginTop: 100 }} />
        <LoadingButton
          onClickFn={() => uploadProfilePicture(image.file, setLoading, toast)}
          loading={loading}
          disabled={loading || !image.imageUrl}
          buttonText="Save"
          style={{
            marginTop: "100px !important",
          }}
        />
      </AccountInformationArea>
    </SettingsContainer>
  );
}
