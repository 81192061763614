import { PROFILEIMAGEICON } from "assets/assets";
import { PersonContainer, RequestButton } from "./style";
import { SearchedUserTypes } from "./types";
import ComponentLoader from "components/loaders/ComponentLoader";

function Person({
  firstName,
  lastName,
  userName,
  profilePhoto,
  requestStatus,
  sendRequest,
  sendingRequest,
  status,
}: SearchedUserTypes) {
  console.log({ status });
  return (
    <PersonContainer>
      {!profilePhoto && <img src={PROFILEIMAGEICON} alt="user" />}
      {profilePhoto && <img src={profilePhoto?.secure_url} alt="user" />}
      <h2>{`${firstName} ${lastName}`}</h2>
      <h3>@{userName}</h3>
      <RequestButton
        disabled={!requestStatus}
        onClick={sendRequest}
        isNotRequested={requestStatus}
      >
        {sendingRequest ? (
          <ComponentLoader />
        ) : requestStatus ? (
          "SEND PAIRING REQUEST"
        ) : (
          status !== 'not requested'? status.toUpperCase():"REQUEST PENDING"
        )}
      </RequestButton>
    </PersonContainer>
  );
}

export default Person;
