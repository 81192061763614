import styled from "styled-components/macro";
import { Link } from "react-router-dom";
// import { Spin } from './animations';
// import BrandColors from 'globalStyles/utils/colors';

type SpaceType = {
  top: string;
};

export const TriggerLink = styled(Link)`
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  width: 100%;
`;

export const TLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: inherit;
`;

export const Space = styled.div`
  margin-top: ${({ top }: SpaceType) => top};
`;
// export const FanDiv = styled.div`
//   width: ${({ width }) => (width ? width : null)};
//   height: ${({ height }) => (height ? height : null)};
//   margin: ${({ margin }) => (margin ? margin : null)};
//   padding: ${({ padding }) => (padding ? padding : `1rem`)};
//   display: ${({ display }) => display};
//   flex-wrap: ${({ wrap }) => wrap};
//   align-items: ${({ alignI }) => alignI};
//   align-self: ${({ align }) => (align ? align : null)};
//   justify-content: ${({ justify }) => (justify ? justify : null)};
//   background-color: ${({ backgroundColor }) =>
//     backgroundColor ? backgroundColor : `white`};
//   background-image: ${({ image }) => (image ? `url(${image})` : null)};
//   background-size: cover;
//   background-repeat: no-repeat;
//   border: ${({ border }) => (border ? `1px solid #e5e5e5;` : null)};
//   border-radius: 5px;
// `;

// export const FanImg = styled.img`
//   width: ${({ w }) => (w ? w : null)};
//   height: ${({ h }) => (h ? h : null)};
//   margin: ${({ m }) => (m ? m : null)};
//   padding: ${({ p }) => (p ? p : null)};
//   border-radius: ${({ br }) => (br ? br : null)};
//   align-self: ${({ align }) => (align ? align : null)};
// `;

// export const Button = styled.button`
//   background-color: ${({ dashboard }) =>
//     dashboard ? `${BrandColors.dashBlack}` : `${BrandColors.brandGold}`};
//   width: ${({ w }) => (w ? w : null)};
//   padding: ${({ p }) => (p ? p : '0.5rem')};
//   border: ${({ border }) => (border ? `1px solid ${border}` : `none`)};
//   border-radius: 5px;
//   outline: none;
//   font-weight: 600;
//   cursor: pointer;
//   color: ${({ color }) => (color ? color : `${BrandColors.black}`)};
//   margin: ${({ m }) => (m ? m : null)};
// `;

// export const Spinner = styled.div`
//   border: ${({ b }) => (b ? `${b}px solid #f3f3f3` : '16px solid #f3f3f3')};
//   border-radius: 50%;
//   border-top: ${({ b }) => (b ? `${b}px solid #041e41` : '16px solid #041e41')};
//   width: ${({ w }) => (w ? w : '50px')};
//   height: ${({ w }) => (w ? w : '50px')};
//   /* -webkit-animation: ${Spin} 2s linear infinite; Safari */
//   animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
//   /* ${Spin} 2s linear infinite; */
// `;

// export const FanplusMainContainer = styled.div`
//   background-color: #fafafa;
//   min-width: 100vw;
//   min-height: 100vh;
//   overflow-x: scroll;
//   overflow-y: hidden;
//   display: flex;
//   justify-content: center;
//   padding-top: 60px;
// `;

// export const FanplusMainWrapper = styled.div`
//   padding: 16px;
//   padding-bottom: 73px;
//   width: 500px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;
