import { useEffect, useState } from "react";
import { makePostRequest } from "requests/requests";
import { SERVER_CREATE_ACTIVITIES } from "routes/server";
import useActivityStore from "zustandStore/activityStore";
import { ActivityStoreType } from "zustandStore/activityStore/type";
import { useLocationStore } from "zustandStore/locationStore";
import useToastStore from "zustandStore/toastStore";
import { ToastStoreType } from "zustandStore/toastStore/type";

export enum ACTIVITY_FETCH_TYPE {
  USER = "mine",
  GENERAL = "general",
  BOTH = "both",
  NONE = "none",
}

type USE_ACTIVITY_PROPS = {
  fetch?: ACTIVITY_FETCH_TYPE;
};

export default function useActivity({
  fetch = ACTIVITY_FETCH_TYPE.NONE,
}: USE_ACTIVITY_PROPS) {
  const [loading, setLoading] = useState(false);
  const [postActivityLoading, setPostActivityLoading] = useState(false);
  const toast: ToastStoreType = useToastStore();
  const {
    myActivities,
    activities,
    postMainText,
    postShortText,
    refreshActivityStore,
    fetchMyActivities,
    fetchActivities,
  }: ActivityStoreType = useActivityStore();
  const { lat, lng, speed, timestamp, accuracy, heading } = useLocationStore();

  function handleSendPost() {
    setPostActivityLoading(true);
    const payload: any = {
      message: postMainText,
      type: postShortText,
      location: {
        lat,
        lng,
        heading,
        speed,
        accuracy,
        timestamp,
      },
    };

    makePostRequest(SERVER_CREATE_ACTIVITIES, payload)
      .then((res: any) => {
        // const newActivity: ActivityProps = { ...res.data };
        refreshActivityStore();
        setPostActivityLoading(false);
        // console.log({ res });
      })
      .catch((err) => {
        toast.showFailedToast(
          "Activity Error",
          "can not create activity at this time"
        );
        setPostActivityLoading(false);
      });
  }

  function render() {
    switch (fetch) {
      case ACTIVITY_FETCH_TYPE.USER:
        fetchMyActivities(setLoading);
        break;
      case ACTIVITY_FETCH_TYPE.GENERAL:
        fetchActivities(setLoading);
        console.log("visitingg");
        break;
      case ACTIVITY_FETCH_TYPE.BOTH:
        //work on this later
        fetchMyActivities();
        fetchActivities();
        break;
      default:
        return;
    }
  }

  useEffect(() => {
    render();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    loading,
    activities,
    myActivities,
    handleSendPost,
    postActivityLoading,
  };
}
