import styled from "styled-components/macro";
import BrandColors from "globalStyles/utils/colors";
import media from "globalStyles/utils/media";
import { Link } from "react-router-dom";

export const LoginContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${BrandColors.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  padding: 0 27px;
  overflow: scroll;

  ${media.mobile`
      position: relative;
      padding-top: 0;
      width: 100%;
  `}
`;
export const Form = styled.form`
  width: 100%;
  margin-top: 30px;

  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    margin-top: 20px;
    color: black;
  }

  button {
    width: 100%;
    background-color: ${({ activate }: any) =>
      activate === "change" ? BrandColors.main : BrandColors.grey};
    border-radius: 8px;
    padding: 15px;
    border: none;
    color: ${BrandColors.white};
    margin-top: 20px;
    cursor: pointer;

    .changeBackground {
      background-color: ${BrandColors.main};
    }
  }
`;
export const TextArea = styled.div`
  display: flex;
  color: black;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  align-items: center;

  a {
    color: ${BrandColors.main} !important;
    text-decoration: underline;
    font-size: 14px;
  }
`;

export const RememberArea = styled.div`
  display: flex;
  margin-top: 10px;

  input {
    margin-top: 4px;
    margin-right: 10px;
  }

  h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: black;
  }
`;

export const Extras = styled.div`
  display: flex;
  color: black;
  font-size: 14px;
  align-items: center;
  margin-top: 20px;
  justify-content: center;

  .span {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 15px;
    line-height: 24px;
  }

  h3 {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  a {
    color: ${BrandColors.main} !important;
    text-decoration: underline;
    width: unset !important;
    margin-left: 4px;
  }
`;

export const LinkArea = styled.div`
  display: flex;
  color: black;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  align-items: center;
  margin-top: 64.5px;
  justify-content: center;

  h6 {
    text-align: center;
    /* margin-bottom: 48px; */
  }
`;

export const ButtomText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #abb7c6;
  margin-top: 40px;
`;

export const SpanLink = styled(Link)`
  color: ${BrandColors.main};
  text-decoration: none !important;
`;
