import React from "react";
import { ProfileHeaderContainer } from "./style";
import { SETTINGSICON } from "assets/assets";
import { TLink } from "globalStyles/triggerStyles/style";
import { SETTINGS_URL } from "routes/frontend";

export default function ProfileHeader() {
  return (
    <ProfileHeaderContainer>
      <h2>Profile</h2>
      <TLink to={SETTINGS_URL}>
        <img src={SETTINGSICON} alt="settingsIcon" />
      </TLink>
    </ProfileHeaderContainer>
  );
}
