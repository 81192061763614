import MainHeader from "components/headers";
import {
  CheckInContainer,
  CheckInFormArea,
  CheckinMap,
  LocationButtonArea,
  MapDetailsArea,
  MapDetailsTexts,
  PeopleNumber,
  TimerArea,
  TimerText,
} from "./style";
import { useEffect, useState } from "react";
import TimePickerSelection from "./timepicker/components/TimePickerSelection";
import Switch from "react-switch";
import LoadingButton from "components/loaders/MainLoadingButton";
import { useProfile } from "hooks/useProfile";
import SelectContacts from "./selectContacts";
import useCircleStore from "zustandStore/circle";
import { useLocationStore } from "zustandStore/locationStore";
import { AnyReactComponent } from "pages/map/NormalMap";
import GoogleMapReact from "google-map-react";
import { CHECKIN_KEY, GOOGLEAPIKEY } from "constants/constants";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { getLocalStorageData, setLocalStorageData } from "utilities/helpers";
import useCheckin, { AddCheckinType } from "hooks/useCheckin";
import { ToastStoreType } from "zustandStore/toastStore/type";
import useToastStore from "zustandStore/toastStore";


export type handleTimePickerValueType = {
  hrs: number | string;
  min: number | string;
  sec: number | string;
};

export default function CheckIn() {
  const { contacts } = useProfile({
    getUserContacts: true,
  });
  const { createCheckin, sendingRequest } = useCheckin();
  const [counter, setCounter] = useState("00:00:00");
  const [time, setTime] = useState<handleTimePickerValueType>({
    hrs: 0,
    min: 0,
    sec: 0,
  });
  const [openValue, setOpenValue] = useState<boolean>(false);
  const hasEditedCountdown = counter !== "00:00:00";
  const [sendLocation, setSendLocation] = useState(true);
  const [message, setMessage] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const { selectedContacts } = useCircleStore();
  const toast: ToastStoreType = useToastStore();
  const selectedContactsCount = Object.keys(selectedContacts).length;
  const { timestamp, accuracy, lat, lng, address, addressComponents } =
    useLocationStore();
  const currentDate = new Date();
  const totalMilliseconds =
    Number(time.hrs) * 60 * 60 * 1000 +
    Number(time.min) * 60 * 1000 +
    Number(time.sec) * 1000;
  const checkIntime = new Date(currentDate.getTime() + totalMilliseconds);
  let starter: Function;

  function handleTimerModal() {
    setOpenValue(true);
  }

  function handleMessage(e: any) {
    setMessage(e.target.value);
  }

  async function handleStartCheckin() {
    try {
      const location = {
        lat,
        lng,
        address,
        timestamp,
        accuracy,
      };
      const payload: AddCheckinType = {
        message,
        endTime: checkIntime,
        showLocation: sendLocation,
        receivers: Object.keys(selectedContacts),
        firstLocation: location,
        pavingData: [{ ...location }],
      };
      if(true){
        toast.showFailedToast('Check-In Error','checkin functionalities still in progress')
      }
      // console.log({ checkIntime, location, starter, totalMilliseconds });
      // if (payload.receivers.length < 1) {
      //   toast.showFailedToast('Check-In Error:','Please add people to this check-in')
      // }
      const hasCreatedCheckin: boolean = await createCheckin(payload);
      if (hasCreatedCheckin) {
        setLocalStorageData(CHECKIN_KEY, payload);
        starter();
      }
    } catch (err) {
      console.log({ err });
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function checkIfTimeExist() {
    const time: string = getLocalStorageData(CHECKIN_KEY);
    console.log({ time });
    const newTime = new Date(time).getTime() - new Date().getTime();
    const hrs = new Date(time).getHours() - new Date().getHours();
    const min = new Date(time).getMinutes() - new Date().getMinutes();
    const sec = new Date(time).getSeconds() - new Date().getSeconds();
    // console.log({ newTime, hrs, min, sec });
    if (newTime > 0) {
      setTime({ hrs, min, sec });
      starter();
    }
  }

  // function clearCheckin(){
  //   removeLocalStorageData(CHECKIN_KEY)
  // }

  function handleTimePickerChange(value: handleTimePickerValueType) {
    const { hrs, min, sec } = value;
    const hours = Number(hrs) < 10 && Number(hrs) >= 0 ? `0${hrs}` : hrs;
    const minutes = Number(min) < 10 && Number(min) >= 0 ? `0${min}` : min;
    const seconds = Number(sec) < 10 && Number(sec) >= 0 ? `0${sec}` : sec;
    setTime({
      ...value,
    });
    setCounter(`${hours}:${minutes}:${seconds}`);
  }

  const center: any = {
    lat: lat,
    lng: lng,
  };

  const defaultProps = {
    center: {
      lng: lat,
      lat: lng,
    },
    zoom: 16,
  };

  const renderer = ({
    hours,
    minutes,
    seconds,
    api: { start },
  }: CountdownRenderProps) => {
    starter = start;
    // console.log({ hours, minutes, seconds });
    return (
      <TimerText edited={hasEditedCountdown}>
        {hours < 10 ? `0${hours}` : hours}:{minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
      </TimerText>
    );
  };

  useEffect(() => {
    checkIfTimeExist();
  }, [checkIfTimeExist]);

  return (
    <CheckInContainer>
      <MainHeader pageLabel="Check in" />
      <TimerArea>
        <Countdown date={checkIntime} renderer={renderer} />
        {/* <TimerText edited={hasEditedCountdown}>{counter}</TimerText> */}
        <h5 onClick={handleTimerModal}>SET TIMER</h5>
      </TimerArea>
      <CheckInFormArea>
        <textarea
          maxLength={100}
          onChange={handleMessage}
          placeholder="Write a message"
        ></textarea>
        <LocationButtonArea>
          <div>
            <h3>Location</h3>
            <Switch
              height={18}
              width={36}
              onColor="#44a4be"
              uncheckedIcon={false}
              checkedIcon={false}
              uncheckedHandleIcon={
                <div style={{ width: 30, height: 30, color: "#" }}></div>
              }
              checkedHandleIcon={
                <div style={{ width: 30, height: 30, color: "#" }}></div>
              }
              offColor="#ABB7C6"
              onChange={() => setSendLocation(!sendLocation)}
              checked={sendLocation}
            />
          </div>
          <div>
            <PeopleNumber>{selectedContactsCount}</PeopleNumber>
            <button onClick={() => setShowModal(true)}>Add People</button>
          </div>
        </LocationButtonArea>
        {sendLocation && lat && (
          <CheckinMap>
            <GoogleMapReact
              bootstrapURLKeys={{ key: GOOGLEAPIKEY }}
              defaultCenter={center}
              options={{
                // styles: mapStyles,
                zoomControl: false,
              }}
              defaultZoom={defaultProps.zoom}
              yesIWantToUseGoogleMapApiInternals
            >
              <AnyReactComponent lat={center.lat} lng={center.lng} text={address} />
            </GoogleMapReact>
            {!!addressComponents && (
              <>
                <MapDetailsArea />
                <MapDetailsTexts>
                  <h5>
                    {addressComponents[3]?.long_name},
                    {addressComponents[6]?.long_name} - LAT: {lat?.toFixed(2)}, LNG:{" "}
                    {lng?.toFixed(2)}
                  </h5>
                  <h3>{address}</h3>
                </MapDetailsTexts>
              </>
            )}
          </CheckinMap>
        )}
        <LoadingButton
          onClickFn={handleStartCheckin}
          loading={sendingRequest}
          disabled={sendingRequest || false}
          buttonText="START"
        />
      </CheckInFormArea>
      <SelectContacts
        contacts={contacts}
        setShowModal={setShowModal}
        showModal={showModal}
      />
      <TimePickerSelection
        isOpen={openValue}
        setIsOpen={setOpenValue}
        timeValue={(val: any) => handleTimePickerChange(val)}
      />
    </CheckInContainer>
  );
}
