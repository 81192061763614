import { ADD_PEOPLE_URL, PROFILE_URL } from "routes/frontend";
import { useSecureStorage } from "./useSecureStorage";
import { USER_PROFILE_KEY } from "constants/constants";
import { useEffect, useState } from "react";
import { ProfileStoreType } from "zustandStore/profile/type";
import { CircleStoreType } from "zustandStore/circle/type";
import useProfileStore from "zustandStore/profile";
import useCircleStore from "zustandStore/circle";

interface UseProfileProps {
  getUserContacts?: boolean;
  getUserCircles?: boolean;
}

export function useProfile({
  getUserContacts = false,
  getUserCircles = false,
}: UseProfileProps = {}) {
  const addPeopleURL = ADD_PEOPLE_URL;
  const profileURL = PROFILE_URL;
  const [loading, setLoading] = useState(false);
  const [profileData] = useSecureStorage(USER_PROFILE_KEY, "");
  const { getContacts, contacts, uploadProfilePicture }: ProfileStoreType =
    useProfileStore();
  const { circleCount, getCircles }: CircleStoreType = useCircleStore();
  useEffect(() => {
    getUserContacts && getContacts(setLoading);
    getUserCircles && getCircles();
  }, [getContacts, getCircles, getUserContacts, getUserCircles]);

  return {
    addPeopleURL,
    profileURL,
    profileData,
    contacts,
    circleCount,
    uploadProfilePicture,
    loading
  };
}
