import {
  makeDeleteRequestWithAxios,
  makeGetRequestWithToken,
  makePostRequestWithAxios,
  makePutRequestWithAxios,
} from "requests/requests";
import { GET_CIRCLES, updateCircleUrl } from "routes/server";
import { CircleProps, CircleStoreType } from "./type";
import { CIRCLES_URL } from "routes/frontend";

export function getCircles(set: Function) {
  makeGetRequestWithToken(GET_CIRCLES)
    .then((res: any) => {
      if (res?.response?.success) {
        set((prev: CircleStoreType) => ({
          ...prev,
          circleCount: res?.response?.data.length,
          circles: res?.response?.data,
        }));
      }
    })
    .catch((err) => {
      console.log({ err });
    });
}

export function createCircle(get: Function, body: CircleProps, navigate: any) {
  const { setCircleLoading, refreshSelectedContacts }: CircleStoreType = get();
  setCircleLoading(true);
  makePostRequestWithAxios(GET_CIRCLES, body)
    .then((res: any) => {
      setCircleLoading(false);
      if (res?.success) {
        refreshSelectedContacts()
        navigate(CIRCLES_URL);
      }
    })
    .catch((err) => {
      setCircleLoading(false);
      console.log({ err });
    });
}

export function updateCircle(
  get: Function,
  body: CircleProps,
  navigate: any,
  circleId: string
) {
  const { setCircleLoading, refreshSelectedContacts }: CircleStoreType = get();
  setCircleLoading(true);
  const url = updateCircleUrl(circleId);
  makePutRequestWithAxios(url, body)
    .then((res: any) => {
      setCircleLoading(false);
      if (res?.success) {
        refreshSelectedContacts()
        navigate(CIRCLES_URL);
      }
    })
    .catch((err) => {
      setCircleLoading(false);
      console.log({ err });
    });
}

export function deleteCircle(get: Function, navigate: any, circleId: string) {
  const { setCircleLoading, refreshSelectedContacts }: CircleStoreType = get();
  setCircleLoading(true);
  const url = updateCircleUrl(circleId);
  makeDeleteRequestWithAxios(url)
    .then((res: any) => {
      setCircleLoading(false);
      if (res?.success) {
        refreshSelectedContacts()
        navigate(CIRCLES_URL);
      }
    })
    .catch((err) => {
      setCircleLoading(false);
      console.log({ err });
    });
}

export function setCircleLoading(set: Function, value: boolean) {
  set((prev: CircleStoreType) => ({
    ...prev,
    isLoading: value,
  }));
}

export function refreshSelectedContacts(set: Function) {
  set((prev: CircleStoreType) => ({
    ...prev,
    selectedContacts: {},
  }));
}

export function addToSelectedContacts(
  contactId: string,
  get: Function,
  set: Function
) {
  const { selectedContacts }: CircleStoreType = get();
  const updated = {
    ...selectedContacts,
    [contactId]: contactId,
  };
  set((prev: CircleStoreType) => ({
    ...prev,
    selectedContacts: {
      ...prev.selectedContacts,
      ...updated,
    },
  }));
}

export function removeFromSelectedContacts(
  contactId: string,
  get: Function,
  set: Function
) {
  const { selectedContacts }: CircleStoreType = get();
  delete selectedContacts[contactId];
  set((prev: CircleStoreType) => ({
    ...prev,
    selectedContacts: {
      ...prev.selectedContacts,
    },
  }));
}
