import { CookieSetOptions } from "universal-cookie/cjs/types.d";

const COOKIETIMEINSECONDS = {
  sixMonths: 15780000,
  oneWeek: 604800,
};

export const GOOGLEAPIKEY = "AIzaSyApQp5MATSHUWicggY-ecnfsnWwe1FZFwI";
export const AUTH_TOKEN_NAME: string = "trig109cdndjk9829nfjKL873app";
export const CHECKIN_KEY: string = "cHZ8G8Vmn)98";
export const AUTH_CONFIG: CookieSetOptions = {
  path: "/",
  sameSite: "strict",
  maxAge: COOKIETIMEINSECONDS.sixMonths,
};

export enum APPP_ENV {
  PRODUCTION = "production",
  STAGING = "staging",
  LOCAL = "local",
}
export const IS_PRODUCTION = process.env.REACT_APP_ENV === APPP_ENV.PRODUCTION;
export const IS_STAGING = process.env.REACT_APP_ENV === APPP_ENV.STAGING;
export const IS_LOCAL = process.env.REACT_APP_ENV === APPP_ENV.LOCAL;
export const WELCOME_MESSAGE = "xrgtys";
export const USER_PROFILE_KEY = "S78aNdme18a";

export const TRIGGER_BUTTON_KEY = "trbcb483982bc bajc9902";
