import Login from "pages/authPages/login/Login";
import SignUp from "pages/authPages/signup/SignUp";
import NotFound from "pages/notFound";

import {
  SIGNUP_URL,
  LOGIN_URL,
  WELCOME_URL,
  OTP_URL,
  HOME_URL,
  MAP_URL,
  ACTIVITY_URL,
  CHECKIN_URL,
  PROFILE_URL,
  ADD_PEOPLE_URL,
  ALERT_URL,
  CREATE_CIRCLE_URL,
  CIRCLES_URL,
  EDIT_CIRCLE_URL,
  SETTINGS_URL,
  ACCOUNT_INFORMATION_URL,
  ACTIVITY_MAP_URL,
  PIN_URL,
  SEND_LOCATION,
  PANIC_MAP_URL,
  CONTACTS_URL,
  USER_PROFILE_URL,
} from "./frontend";
import Welcome from "pages/authPages/welcome";
import OTP from "pages/authPages/otp";
import Home from "pages/home";
import Map from "pages/map";
import Activity from "pages/activity";
import CheckIn from "pages/checkin";
import Profile from "pages/profile";
import AddPeople from "pages/profile/addPeople";
import Alerts from "pages/alerts";
import Circles from "pages/profile/circles";
import CreateCircle from "pages/profile/circles/CreateCircle";
import EditCircle from "pages/profile/circles/EditCircle";
import Settings from "pages/profile/settings";
import AccountInformation from "pages/profile/settings/AccountInformation";
import ActivityMap from "pages/map/ActivityMap";
import Pin from "pages/authPages/pin";
import SendLocation from "pages/home/SendLocation";
import PanicAlertMap from "pages/map/PanicAlertMap";
import Contacts from "pages/profile/contacts";
import { ActivityUserProfile } from "pages/activity/profile";

export const mainRoutes = [
  {
    id: 1,
    path: WELCOME_URL,
    Component: Welcome,
    exact: true,
    isProtected: false,
    hide: false,
    showBottomNav: false,
  },
  {
    id: 2,
    path: LOGIN_URL,
    Component: Login,
    exact: true,
    isProtected: false,
    hide: false,
    showBottomNav: false,
  },
  {
    id: 3,
    path: SIGNUP_URL,
    Component: SignUp,
    exact: true,
    isProtected: false,
    hide: false,
    showBottomNav: false,
  },
  {
    id: 4,
    path: OTP_URL,
    Component: OTP,
    exact: true,
    isProtected: false,
    hide: false,
    showBottomNav: false,
  },
  {
    id: 5,
    path: HOME_URL,
    Component: Home,
    exact: true,
    isProtected: true,
    hide: false,
    showBottomNav: true,
  },
  {
    id: 6,
    path: MAP_URL,
    Component: Map,
    exact: true,
    isProtected: true,
    hide: false,
    showBottomNav: true,
  },
  {
    id: 7,
    path: ACTIVITY_URL,
    Component: Activity,
    exact: true,
    isProtected: true,
    hide: false,
    showBottomNav: true,
  },
  {
    id: 8,
    path: CHECKIN_URL,
    Component: CheckIn,
    exact: true,
    isProtected: true,
    hide: false,
    showBottomNav: true,
  },
  {
    id: 12,
    path: PROFILE_URL,
    Component: Profile,
    exact: true,
    isProtected: true,
    hide: false,
    showBottomNav: true,
  },
  {
    id: 9,
    path: ADD_PEOPLE_URL,
    Component: AddPeople,
    exact: true,
    isProtected: true,
    hide: false,
    showBottomNav: false,
  },
  {
    id: 10,
    path: ALERT_URL,
    Component: Alerts,
    exact: true,
    isProtected: true,
    hide: false,
    showBottomNav: false,
  },
  {
    id: 13,
    path: CIRCLES_URL,
    Component: Circles,
    exact: true,
    isProtected: true,
    hide: false,
    showBottomNav: false,
  },
  {
    id: 14,
    path: CREATE_CIRCLE_URL,
    Component: CreateCircle,
    exact: true,
    isProtected: true,
    hide: false,
    showBottomNav: false,
  },
  {
    id: 15,
    path: EDIT_CIRCLE_URL,
    Component: EditCircle,
    exact: true,
    isProtected: true,
    hide: false,
    showBottomNav: false,
  },
  {
    id: 16,
    path: SETTINGS_URL,
    Component: Settings,
    exact: true,
    isProtected: true,
    hide: false,
    showBottomNav: false,
  },
  {
    id: 17,
    path: ACCOUNT_INFORMATION_URL,
    Component: AccountInformation,
    exact: true,
    isProtected: true,
    hide: false,
    showBottomNav: false,
  },
  {
    id: 18,
    path: ACTIVITY_MAP_URL,
    Component: ActivityMap,
    exact: true,
    isProtected: true,
    hide: false,
    showBottomNav: false,
  },
  {
    id: 19,
    path: PIN_URL,
    Component: Pin,
    exact: true,
    isProtected: true,
    hide: false,
    showBottomNav: false,
  },
  {
    id: 20,
    path: SEND_LOCATION,
    Component: SendLocation,
    exact: true,
    isProtected: true,
    hide: false,
    showBottomNav: false,
  },
  {
    id: 20,
    path: PANIC_MAP_URL,
    Component: PanicAlertMap,
    exact: true,
    isProtected: true,
    hide: false,
    showBottomNav: false,
  },
  {
    id: 20,
    path: CONTACTS_URL,
    Component: Contacts,
    exact: true,
    isProtected: true,
    hide: false,
    showBottomNav: false,
  },
  // USERS PROFILE PAGE BY KOSU
  {
    id: 21,
    path: USER_PROFILE_URL,
    Component: ActivityUserProfile,
    exact: true,
    isProtected: true,
    hide: false,
    showBottomNav: false,
  },
  {
    id: 0,
    path: "*",
    Component: NotFound,
    exact: false,
    isProtected: false,
    hide: false,
  },
];
