import {
  PROFILEIMAGEICON,
} from "assets/assets";
import {
  ActivityUserProfileComponentContainerStyle,
  ActivityUserProfileRecordArea,
  ActivityUserProfileStyle,
  AtivityUserProfileImageArea,
  CirclesHolder,
  ProfileStatusEditButton,
  PostArea,
  ProfileRecord,
} from "./style";
import { BackWithIcon } from "components/headers/BackWithIcon";
import PostMain from "./post";
import { FC } from "react";
import { ActivityProfileProps } from "./types";

export const ActivityUserProfileComponent:FC<ActivityProfileProps> = ({userProfileData}) => {
  const fullname  = `${userProfileData?.firstName} ${userProfileData?.lastName}`

  const fullnameCheck = userProfileData.firstName && userProfileData.lastName
  const profileImage = userProfileData.profilePhoto?.url;


  console.log("e",userProfileData)

  const isPaired = userProfileData?.isPaired


  return (
    <ActivityUserProfileStyle>
      <BackWithIcon backText="Profile" backUrl={-1} />
      <ActivityUserProfileComponentContainerStyle>
        <AtivityUserProfileImageArea>
          <img
            src={profileImage || PROFILEIMAGEICON}
            alt="user"
          />
          <div>
            {fullnameCheck &&  <h2>{fullname}</h2>}
            <p>{userProfileData.userName && `@${userProfileData.userName}`}</p>
          </div>
        </AtivityUserProfileImageArea>
        <ActivityUserProfileRecordArea>
          <CirclesHolder>
            <ProfileRecord>
              <h1>{userProfileData?.contactSize}</h1>
              <p>People</p>
            </ProfileRecord>
            <ProfileRecord>
              <h1>{userProfileData?.circles}</h1>
              <p>Circle</p>
            </ProfileRecord>
          </CirclesHolder>
        {isPaired&&  <ProfileStatusEditButton>
            {/* <AddPeopleButton> */}
              <p>Paired</p>
            {/* </AddPeopleButton> */}
          </ProfileStatusEditButton>}
        </ActivityUserProfileRecordArea>
      </ActivityUserProfileComponentContainerStyle>
      <PostArea>
        <PostMain />
      </PostArea>
    </ActivityUserProfileStyle>
  );
};
