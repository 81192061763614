import { toastObj } from "./index";

export function closeResponse(set: any) {
  set((prev: any) => {
    return {
      ...prev,
      ...toastObj,
    };
  });
}

export function closeResponseTimeout(get: any) {
  const closeResponse = get().closeResponse;
  setTimeout(() => closeResponse(), 6000);
}

export function showResponse(
  set: any,
  get: any,
  heading: string,
  message: string,
  isError: boolean
) {
  const closeResponseTimeout = get().closeResponseTimeout;
  const messages = get().messages;
  set({
    isOpen: true,
    isError,
    heading,
    messages: [...messages, message],
  });

  closeResponseTimeout();
}

export function showInformationToast(
  set: any,
  get: any,
  heading: string,
  message: string,
  linkText: string,
  link: string
) {
  // const closeResponseTimeout = get().closeResponseTimeout;
  const messages = get().messages;
  set({
    isOpen: true,
    toastType: "information",
    messages: [...messages, message],
    link,
    linkText: linkText?.toUpperCase(),
    heading,
  });

  // closeResponseTimeout();
}

export function showBannerToast(set: any) {
  // const closeResponseTimeout = get().closeResponseTimeout;
  set({
    isOpen: true,
  });

  // closeResponseTimeout();
}

export function showWarningToast(
  set: any,
  get: any,
  heading: string,
  message: string
) {
  const closeResponseTimeout = get().closeResponseTimeout;
  const messages = get().messages;
  set({
    isOpen: true,
    toastType: "warning",
    messages: [...messages, message],
    heading,
  });

  closeResponseTimeout();
}

export function showSuccessToast(
  set: any,
  get: any,
  heading: string,
  message: string
) {
  const closeResponseTimeout = get().closeResponseTimeout;
  const messages = get().messages;
  set({
    isOpen: true,
    toastType: "success",
    messages: [...messages, message],
    heading,
  });

  closeResponseTimeout();
}

export function showFailedToast(
  set: any,
  get: any,
  heading: string,
  message: string
) {
  const closeResponseTimeout = get().closeResponseTimeout;
  const messages = get().messages;
  set({
    isOpen: true,
    toastType: "failed",
    messages: [...messages, message],
    heading,
  });

  closeResponseTimeout();
}
