import BrandColors from "globalStyles/utils/colors";
import styled from "styled-components/macro";

export const OTPContainer = styled.div`
  background-color: ${BrandColors.background};
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 27px;

  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    margin-top: 90px;
    color: #abb7c6;
  }
`;

export const OtpHolder = styled.div`
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const OptionsArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 27px;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #44a4be;
    cursor: pointer;
  }
`;
