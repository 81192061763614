import { motion } from 'framer-motion';

export default function PageTransitionProvider({ children }: any) {
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: '100%' }}
      exit={{ x: '100%', transition: { duration: 0.7 } }}
    >
      {children}
    </motion.div>
  );
}
