import { usePosition } from "use-position";

export default function useUserLocation() {
  const options = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 1000 * 60 * 5,
  };
  // const obj: any = {
  //   timeStamp: 0,
  //   accuracy: 0,
  //   latitude: 0,
  //   longitude: 0,
  // };
  // const [locationData, setLocationData] = useState(obj);
  // const { lat, lng, speed, timestamp, accuracy, heading, error } = locationData;
  const { latitude, longitude, speed, timestamp, accuracy, heading, error } =
    usePosition(true, options);

  const locationData = {
    latitude,
    longitude,
    speed,
    timestamp,
    accuracy,
    heading,
    error,
  };
  // function successCallback(position: any) {
  //   const locationObj = {
  //     lat: position.coords.latitude,
  //     lng: position.coords.longitude,
  //     speed: position.coords.speed,
  //     timestamp: position.timestamp,
  //     accuracy: position.coords.accuracy,
  //     heading: null,
  //     error: null,
  //   };
  //   console.log({ ...locationObj });
  //   setLocationData(locationObj);
  //   // Handle the user's location updates here
  // }

  // function errorCallback(error: any) {
  //   console.log({ error });
  //   // Handle errors here
  //   switch (error.code) {
  //     case 1:
  //       console.error("Permission denied");
  //       break;
  //     case 2:
  //       console.error("Position unavailable");
  //       break;
  //     case 3:
  //       console.error("Timeout expired");
  //       break;
  //     default:
  //       console.error("An unknown error occurred");
  //       break;
  //   }
  // }

  // const lat: any = latitude;
  // const lng: any = longitude;

  // function handleLocation() {
  //   navigator.permissions.query({ name: "geolocation" }).then((result) => {
  //     // console.log({ result });
  //     result.onchange = (data) => {
  //       console.log({ data });
  //     };
  //     if (result.state === "granted") {
  //       console.log("statedd", { result });
  //       const watchId = navigator.geolocation.watchPosition(
  //         successCallback,
  //         errorCallback,
  //         options
  //       );
  //       navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  //     } else if (result.state === "prompt") {
  //       const watchId = navigator.geolocation.watchPosition(
  //         successCallback,
  //         errorCallback,
  //         options
  //       );
  //       console.log({ watchId });
  //     } else {
  //       navigator.geolocation.getCurrentPosition((location) => {
  //         // console.log({ location });
  //         // setLocationData({
  //         //   timestamp: location.timestamp,
  //         //   accuracy: location.coords.accuracy,
  //         //   lng: location.coords.longitude,
  //         //   lat: location.coords.latitude,
  //         // });
  //       });
  //     }
  //     // console.log({ locationData });
  //     // Don't do anything if the permission was denied.
  //   });
  // }

  // useEffect(() => {
  //   handleLocation();
  //   // return () => setLocationData(obj);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // console.log({ locationData });
  return {
    locationData,
    // handleLocation,
    lat: latitude,
    lng: longitude,
    speed,
    timestamp,
    accuracy,
    heading,
    error,
  };
}
