import { LOCATIONICON2, RELOADICON, SETTINGSICON } from "assets/assets";
import EmptyList from "components/emptyList";
import { BackWithIcon } from "components/headers/BackWithIcon";

import ComponentLoader from "components/loaders/ComponentLoader";
import { useProfile } from "hooks/useProfile";
import SearchContact from "pages/checkin/selectContacts/SearchContact";
import { SelectContactsContainer } from "pages/checkin/style";
import Contact from "pages/profile/circles/Contact";
import { PaddedArea } from "pages/profile/style";
import { useState } from "react";
import { HOME_URL, SETTINGS_URL } from "routes/frontend";
import useCircleStore from "zustandStore/circle";
import {
  LocationArea,
  LocationArea1,
  LocationArea2,
  LocationBottomContainer,
  SignalText,
} from "./style";
// import useUserLocation from "hooks/useLocation";
import useGeocode from "hooks/useGeocode";
import LoadingButton from "components/loaders/MainLoadingButton";
import { ToastStoreType } from "zustandStore/toastStore/type";
import useToastStore from "zustandStore/toastStore";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "pages/authPages/store";
import PinModal from "components/modals/PinModal";
import { makePostRequest } from "requests/requests";
import { SERVER_LOCATION } from "routes/server";
import { LocationStoreType, useLocationStore } from "zustandStore/locationStore";

export enum SIGNALVALUES {
  GOOD = "good",
  BAD = "bad",
}
function SendLocationButton({ selectedContacts }: any) {
  // const { lat, lng, accuracy, speed, timestamp } = useUserLocation();
  const { lat, lng, accuracy, speed, timestamp }: LocationStoreType =
    useLocationStore();
  const { addressComponents } = useGeocode(lat, lng);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const toast: ToastStoreType = useToastStore();
  const signalText: SIGNALVALUES =
    accuracy && accuracy < 9.5 ? SIGNALVALUES.GOOD : SIGNALVALUES.BAD;
  const navigate = useNavigate();
  const authObj: any = useAuthStore();
  console.log({ lat, lng, accuracy, speed, timestamp });

  function handleSendLocation(pin: string) {
    const payload = {
      lat,
      lng,
      speed,
      accuracy,
      timestamp,
      pin,
      contacts: Object.values(selectedContacts),
    };

    if (!lat || !lng) {
      toast.showWarningToast("", "We are currently getting your location...");
    }

    if (signalText === SIGNALVALUES.BAD) {
      toast.showWarningToast("", "Bad location signal");
      // return;
    }

    setLoading(true);
    makePostRequest(SERVER_LOCATION, payload)
      .then((res) => {
        setLoading(false);
        toast.closeResponse();
        toast.showSuccessToast(
          "Location Sent",
          "you've successfully sent your current location"
        );
        authObj.openPinModal(false);
        navigate(HOME_URL);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.message?.includes("pin")) {
          toast.closeResponse();
          setError(true);
          toast.showFailedToast("Incorrect Pin:", err.message);
          return;
        }
        toast.showFailedToast("", err.message);
      });
  }

  function handlePageReload() {
    navigate(window.location.pathname);
  }
  console.log({ addressComponents });

  return (
    <LocationBottomContainer>
      <PinModal
        clickAction={handleSendLocation}
        isLoading={loading}
        error={error}
        setError={setError}
      />
      <LocationArea>
        <LocationArea1>
          <h2>
            {addressComponents[0]?.long_name || "loading..."},{" "}
            {addressComponents[1]?.short_name || "loading..."},{" "}
            {addressComponents[5]?.long_name}
          </h2>
          <h3>
            LAT: {lat?.toFixed(2)} , LNG: {lng?.toFixed(2)}{" "}
          </h3>
          <h3>ACCURACY: {accuracy?.toFixed(2)}m</h3>
          <SignalText signal={signalText}>LOCATION SIGNAL: {signalText}</SignalText>
        </LocationArea1>
        <LocationArea2>
          <img src={LOCATIONICON2} alt="location" />
          <img onClick={handlePageReload} src={RELOADICON} alt="reload" />
        </LocationArea2>
      </LocationArea>
      <LoadingButton
        disabled={Object.keys(selectedContacts).length < 1}
        buttonText="SEND"
        onClickFn={() => authObj.openPinModal(true)}
      />
    </LocationBottomContainer>
  );
}

export default function SendLocation() {
  const { selectedContacts } = useCircleStore();
  const [searchName, setSearchName] = useState("");
  const { contacts, loading } = useProfile({
    getUserContacts: true,
  });
  const filteredContacts = contacts.filter(({ contact }: any) => {
    return (
      contact?.lastName?.toLowerCase()?.includes(searchName.toLowerCase()) ||
      contact?.firstName?.toLowerCase()?.includes(searchName.toLowerCase()) ||
      contact?.phone?.includes(searchName)
    );
  });
  const contactLength = filteredContacts.length;

  return (
    <SelectContactsContainer>
      <BackWithIcon
        backText="Home"
        backUrl={HOME_URL}
        linkIcon={SETTINGSICON}
        linkUrl={SETTINGS_URL}
      />
      <PaddedArea style={{ marginTop: 70 }}>
        {loading && contactLength < 1 && <ComponentLoader />}
        {contacts.length > 0 && (
          <SearchContact searchName={searchName} setSearchName={setSearchName} />
        )}
        {contactLength < 1 && !loading && (
          <EmptyList
            label="NO CONTACTS FOUND"
            description="please add more people to your contact from profile"
          />
        )}
        {filteredContacts.map((contact: any, i) => (
          <Contact
            key={i}
            isSelected={selectedContacts[contact.contact._id]}
            firstName={contact.contact.firstName}
            lastName={contact.contact.lastName}
            _id={contact.contact._id}
          />
        ))}
      </PaddedArea>
      <SendLocationButton selectedContacts={selectedContacts} />
    </SelectContactsContainer>
  );
}
