import create from "zustand";
import {
  clearErrorOnFocus,
  // attachSignin,
  clearPasswordErrorsOnFocus,
  getToken,
  handleConfirmPasswordBlur,
  // handleFacebookLogin,
  // handleForgotPassword,
  // handleGoogleLogin,
  handleLogin,
  handleLogout,
  handleOtp,
  handlePasswordBlur,
  openPinModal,
  handleRegChange,
  // handleResetPassword,
  handleSignUp,
  handleUpdatePin,
  // handleTnC,
  setCookieAndRouteToHome,
  setOtp,
} from "./actions";

const authObj = {
  makingRequest: false,
  pin: {
    openModal: false,
    number: "",
  },
  otp: null,
  token: "",
  signIn: {
    email: "",
    password: "",
    agreement: false,
  },
  signUp: {
    email: "",
    phone: "",
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    userName: "",
    agreement: true,
    referred_by: "",
  },
};

export const useAuthStore = create((set, get) => ({
  ...authObj,
  setCookieAndRouteToHome: (value: any) => setCookieAndRouteToHome(set, value),
  getToken: () => getToken(get),
  handleLogin: (
    e: any,
    data: any,
    setErr: Function,
    setLoading: Function,
    navigate: Function
  ) => handleLogin(set, e, data, setErr, setLoading, navigate),
  handleLogout: () => handleLogout(),
  openPinModal: (value: boolean) => openPinModal(set, value),
  clearErrorOnFocus: (setErr: Function, focusedKey: string) =>
    clearErrorOnFocus(setErr, focusedKey),
  handleRegChange: (e: any) => handleRegChange(set, e),
  handleConfirmPasswordBlur: (setFormErr: any) =>
    handleConfirmPasswordBlur(get, setFormErr),
  clearPasswordErrorsOnFocus: (setErr: any) => clearPasswordErrorsOnFocus(setErr),
  handlePasswordBlur: (setFormErr: any) => handlePasswordBlur(get, setFormErr),
  // handleTnC: (e) => handleTnC(get, set, e),
  setOtp: (otp: string | null) => setOtp(set, otp),
  handleSignUp: (
    e: any,
    setLoading: any,
    setFormErr: any,
    otp: string,
    navigate: any,
    toast: any
  ) => handleSignUp(get, e, setLoading, setFormErr, otp, navigate, toast),
  handleOtp: (e: any, setLoading: any, setFormErr: any) =>
    handleOtp(get, e, setLoading, setFormErr),
  handleUpdatePin: (toast: any, setLoading: any, setFormErr: any, pin: string) =>
    handleUpdatePin(toast, setLoading, setFormErr, pin),
  // handleForgotPassword: (e, data, setLoading, setRequestSent, setEmailErr) =>
  //   handleForgotPassword(e, data, setLoading, setRequestSent, setEmailErr),
  // handleResetPassword: (e, data, setFormErr, setLoading) =>
  //   handleResetPassword(e, data, setFormErr, setLoading),
  // handleFacebookLogin: (response, toast, setIsLoadingSocial) =>
  //   handleFacebookLogin(response, toast, setIsLoadingSocial),
  // handleGoogleLogin: (googleUser, setLoading, toast) =>
  //   handleGoogleLogin(get, googleUser, setLoading, toast),
  // attachSignin: (element, auth2, setIsLoadingSocial, toast) =>
  //   attachSignin(get, element, auth2, setIsLoadingSocial, toast),
}));
