import styled from "styled-components";

type Props = {
  lat?: string | number;
  lng?: string | number;
  size?: number;
};
export const MapPointerIconStyle = styled.div<Props>`
position: relative;
.map_content{
    position: relative;
    img{
        position: absolute;
        bottom: 1.65rem;
        left: .7rem;
        width: 32.5px;
        height: 32.5px;
        border-radius: 50%;
        border: 3px solid #fff;
    }
}
`