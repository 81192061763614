import BrandColors from "globalStyles/utils/colors";
import styled from "styled-components/macro";

type HeaderTypes = {
  isActive?: boolean;
};

export const MainHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 10;
  padding: 20px 27px;
  overflow: hidden;
  background-color: ${BrandColors.background};

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
  }
`;

export const ProfileHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100vw;
  position: fixed;
  top: 0;
  padding: 10px 27px;
  overflow: hidden;
  background-color: ${BrandColors.background};
  z-index: 1;

  img {
    width: 20px;
  }

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
`;

export const AddPeopleHeaderContainer = styled.div`
  justify-content: space-between;
  width: 100vw;
  position: fixed;
  top: 0;
  padding-top: 20px;
  overflow: hidden;
  background-color: ${BrandColors.background};
  z-index: 1;

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }

  p {
    margin-right: 20px;
  }
`;

export const BackWithIconContainer = styled.div`
  justify-content: space-between;
  width: 100vw;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  padding: 24px;
  overflow: hidden;
  background-color: ${BrandColors.background};
  z-index: 1;

  h2 {
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const BackDivArea = styled.div`
  display: flex;
  align-items: center;
  margin-left: 27px;
  cursor: pointer;
  width: fit-content;

  img {
    margin-right: 22px;
  }
`;

export const TabArea = styled.div`
  display: flex;
  align-items: center;
  margin-top: 38px;
  width: 100vw;
  justify-content: space-around;
  border-bottom: 1px solid #41474f;

  div {
    margin-right: 22px;
  }
`;

export const TabText = styled.h2`
  padding-bottom: 15px;
  width: 90px;
  text-align: center;
  width: calc(width + 5px);
  color: ${({ isActive }: HeaderTypes) => (isActive ? "white" : "#ABB7C6")};
  border-bottom: ${({ isActive }: HeaderTypes) =>
    isActive ? "2px solid #ABB7C6" : "#ABB7C6"};
`;

export const AddPeopleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  background: #2a3139;
  color: #44a4be;
  border: 1px solid #44a4be;
  border-radius: 6px;
  padding: 8px 12px;
  cursor: pointer;

  img {
    width: 20px;
  }

  p {
    margin-right: 10px;
  }
`;


// ACTIVTY PROFILE
export const ActivityProfileHeaderStyle = styled.div`
display: flex;
  /* justify-content: space-between; */
  width: 100vw;
  position: fixed;
  top: 0;
  padding: 10px 27px;
  overflow: hidden;
  background-color: ${BrandColors.background};
  z-index: 1;

  img {
    width: 20px;
  }

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
`