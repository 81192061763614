import { PROFILEIMAGEICON, TIMEICON } from "assets/assets";

export enum ALERT_TYPE{
  CONTACT_REQUEST = 'contact_request',
  FRIEND_POST = 'friend_post',
  PANIC_ALERT = 'panic_alert',
  CHECKIN_ALERT = 'checkin_alert',
  LOCATION = 'location',
  TRIGGER = 'trigger',
}

export interface AlertDataType {
  type : ALERT_TYPE,
  senderImage: string,
  senderAlert: string,
  timerIcon: string,
  time: Date | string,
  senderName: string
}

export interface SendAlertPayloadType {
  data: any;
  alertType: ALERT_TYPE;
}

export const alertData : AlertDataType[] = [
  {
    type: ALERT_TYPE.CONTACT_REQUEST,
    senderImage: PROFILEIMAGEICON,
    senderAlert: `Matthew just sent you a contact request`,
    senderName: 'Matthew Madman',
    timerIcon: TIMEICON,
    time: '1m'
  },
  {
    type: ALERT_TYPE.CONTACT_REQUEST,
    senderImage: PROFILEIMAGEICON,
    senderAlert: `Matthew just sent you a contact request`,
    senderName: 'Matthew Madman',
    timerIcon: TIMEICON,
    time: '1m'
  },
]