// import { USER_PROFILE_KEY } from "constants/constants";
import { USER_PROFILE_KEY } from "constants/constants";
import { SendAlertPayloadType } from "pages/alerts/data";
import { SearchedUserTypes } from "pages/profile/addPeople/types";
import { useEffect, useState } from "react";
import { GeneralResponseType } from "requests/requests";
import { SERVER_URL } from "routes/server";
import { io } from "socket.io-client";
import { secureStorage } from "utilities/encryption";
import { getAuthCookie } from "utilities/helpers";
import useToastStore from "zustandStore/toastStore";
import { ToastStoreType } from "zustandStore/toastStore/type";

const user = JSON.parse(secureStorage.getItem(USER_PROFILE_KEY));

const token = getAuthCookie();
export const alertSocket = io(`${SERVER_URL}/alerts`, {
  query: { token: `${getAuthCookie()}`, userId: user?._id },
});

// console.log({ user, alertSocket });

export function sendContactRequestAlert(
  data: SendAlertPayloadType,
  setSendingRequest: Function,
  setSearchedUser: Function
) {
  setSendingRequest(true);
  alertSocket.emit("sendAlerts", data, (response: GeneralResponseType) => {
    if (response.success) {
      setSendingRequest(false);
      setSearchedUser((prev: SearchedUserTypes) => ({
        ...prev,
        requestStatus: false,
      }));
      return response;
    }
  });
  return {};
}

export async function SendCheckinAlert(
  data: SendAlertPayloadType,
  setSendingRequest: Function
): Promise<boolean | GeneralResponseType | any> {
  console.log("alerter", { alertSocket: alertSocket.id });
  const toast: ToastStoreType = useToastStore();
  alertSocket.emit("sendAlerts", data, (response: GeneralResponseType) => {
    console.log("alert sent successfully", response);
    if (response.success) {
      setSendingRequest(false);
      toast.showSuccessToast("Check-In Success", response.message);
      return response;
    }
  });
  return {};
}

function handleReceivedAlerts(data: any, setAlerts: Function) {
  // const audio = new Audio(notifSound);
  // audio.play()
  setAlerts((prev: any) => ({
    ...prev,
    alertMessages: [...prev.alertMessages, { ...data, sender: data.requested_by }],
  }));
}

export function fetchAllAlerts(setAlert: Function, setLoading: Function) {
  alertSocket.emit("getAllAlerts", `fetch data`, (res: any) => {
    console.log({ alerts: res });
    setLoading(false);
    setAlert({ alertMessages: res.data });
  });
}

// console.log({ token });
export default function useAlerts() {
  const [alert, setAlert] = useState({
    alertMessages: [],
  });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!token && window.location.pathname === "/alerts") {
      window.location.reload();
    }
    alertSocket.on("receiveAlerts", (data: any) => {
      handleReceivedAlerts(data, setAlert);
    });
    fetchAllAlerts(setAlert, setLoading);
  }, []);

  return {
    alert,
    loading,
  };
}
