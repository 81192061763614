import { USER_PROFILE_KEY } from "constants/constants";
import { useEffect, useState } from "react";
import { SERVER_URL } from "routes/server";
import io from "socket.io-client";
import { secureStorage } from "utilities/encryption";
import { getAuthCookie } from "utilities/helpers";

const token = getAuthCookie();
const user = JSON.parse(secureStorage.getItem(USER_PROFILE_KEY));

// const token = getAuthCookie();
export const checkinSocket = io(`${SERVER_URL}/checkin`, {
  query: { token: `${getAuthCookie()}`, userId: user?._id },
});


export default function useCheckinSocket() {
  const [checkin, setCheckin] = useState({
    alertMessages: [],
  });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // if (!token && window.location.pathname === "/alerts") {
    //   window.location.reload();
    // }
    // checkinSocket.on("receiveAlerts", (data: any) =>
    //   handleReceivedAlerts(data, setAlert)
    // );
    // fetchAllAlerts(setAlert, setLoading);
  }, []);
  return {
    alert,
    loading,
    token,
    checkin,
    setLoading,
    setCheckin
  };
}
