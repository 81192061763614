import BrandColors from "globalStyles/utils/colors";
import styled from "styled-components/macro";

export const CircleContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${BrandColors.background};
  display: flex;
  flex-direction: column;
  color: white;
  overflow-x: hidden;
  padding-bottom: 80px;
`;

export const CircleDiv = styled.div`
  background-color: ${BrandColors.background};
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-between;
  margin-top: 26px;
`;

export const EditIcon = styled.img`
  width: 16px;
`;

export const DeleteIcon = styled.img`
  width: 16px;
  margin-right: 22px;
`;

export const AddText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #44a4be;
  margin-top: 16px;
`;

export const ContactsDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
`;

export const ContactsNameDiv = styled.div`
  display: flex;
  align-items: center;
  margin-left: 23px;

  img {
    width: 40px;
    margin-right: 10px;
  }

  h2 {
    text-transform: capitalize;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
  }
`;

export const CircleImageDiv = styled.div`
  display: flex;
  align-items: center;

  div {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #41474f;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
    }
  }

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    text-transform: capitalize;
  }
`;

export const CircleHeader = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #41474f;
  justify-content: space-between;

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
  }
`;

export const CircleCounter = styled.div`
  border: 1px solid #abb7c6;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  margin-right: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CreateButtonArea = styled.div`
  background-color: ${BrandColors.background};
  padding: 0 27px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding-bottom: 20px;
  padding-top: 5px;
`;
