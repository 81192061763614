import React from "react";
import BrandColors from "globalStyles/utils/colors";
import { ButtonSpinner } from "./LoadingButton";
import { Button } from "./style";

export default function LoadingButton({
  loading,
  onClickFn,
  buttonText,
  disabled,
  id,
  invert = "no",
}: any) {
  return (
    <Button
      invert={invert}
      style={{
        backgroundColor:
          disabled && invert === "no"
            ? BrandColors.grey
            : invert === "no"
            ? BrandColors.main
            : "",
      }}
      onClick={onClickFn}
      disabled={disabled || loading}
      id={id}
    >
      {loading ? <ButtonSpinner /> : buttonText}
    </Button>
  );
}
