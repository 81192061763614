import { IS_LOCAL, IS_PRODUCTION, IS_STAGING } from "constants/constants";
import { ContactRequestReaction } from "pages/alerts/ContactRequest";

const LOCAL_SERVER_URL = "http://127.0.0.1:8200";
// const DEVELOPMENT_SERVER_URL = "https://trigger-backend.onrender.com";
// const DEVELOPMENT_SERVER_URL = "http://localhost:8200";
const STAGING_SERVER_URL = "https://trigger-backend.onrender.com";
const PRODUCTION_SERVER_URL = "https://trigger-backend.onrender.com";
export const SERVER_URL = IS_LOCAL
  ? LOCAL_SERVER_URL
  : IS_STAGING
  ? STAGING_SERVER_URL
  : IS_PRODUCTION
  ? PRODUCTION_SERVER_URL
  : STAGING_SERVER_URL;
// const URL_TAG = "/api/v2";
// const URL_TAG2 = '/api/v1';
// const SERVER_AND_TAG = `${SERVER_URL}${URL_TAG}`;
// const SERVER_AND_TAG2 = `${SERVER_URL}${URL_TAG2}`;

//Auth Endpoints
export const SOCIAL_LOGIN_URL = `${SERVER_URL}/users/social_login`;
export const SERVER_LOGIN_URL = `${SERVER_URL}/auth/login`;
export const SERVER_SIGNUP_URL = `${SERVER_URL}/users/create`;
export const SERVER_OTP_URL = `${SERVER_URL}/users/otp`;
export const SERVER_LOGOUT_URL = `${SERVER_URL}/users/logout`;
export const SERVER_UPDATE_PIN_URL = `${SERVER_URL}/users/pin`;

//location
export const SERVER_LOCATION = `${SERVER_URL}/location`;

//Circles
export const GET_CIRCLES = `${SERVER_URL}/circles`;
export const updateCircleUrl = (id: string) => `${SERVER_URL}/circles/${id}`;

//Activities
export const SERVER_GET_ACTIVITIES = `${SERVER_URL}/activity/all`;
export const SERVER_GET_MY_ACTIVITIES = `${SERVER_URL}/activity/activity`;
export const SERVER_CREATE_ACTIVITIES = `${SERVER_URL}/activity/create`;

// USER PROFILE 
export const SERVER_GET_USER_PROFILE =(user_name: string)=> `${SERVER_URL}/users/profile/${user_name}`
// USER ACTIVITY /POST
export const SERVER_GET_USER_ACTIVITY =(user_name: string)=> `${SERVER_URL}/activity/profile/${user_name}`

//Search and Profile
export const GET_SEARCHED_USER = `${SERVER_URL}/users/search`;
export const GET_CONTACTS = `${SERVER_URL}/contacts`;

//contactRequest
export const REQUEST_CONTACT_BY_EMAIL_URL = `${SERVER_URL}/contact-requests/send/email`;
export const getRespondToRequestURL = (
  reqId: string,
  reaction: ContactRequestReaction
) => `${SERVER_URL}/contact-requests/${reqId}/${reaction}`;

//profile
export const UPDATE_PROFILE_IMAGE = `${SERVER_URL}/users/profile_picture`;

//checkin
export const GET_CHECKIN = `${SERVER_URL}/checkin`;

//triggerFeature
export const SERVER_START_TRIGGER_FEATURE = `${SERVER_URL}/trigger-feature/start`;
export const SERVER_STOP_TRIGGER_FEATURE = `${SERVER_URL}/trigger-feature/stop`;
