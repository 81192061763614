import { useLocation, useNavigate } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import { GOOGLEAPIKEY } from "constants/constants";
import useGeocode from "hooks/useGeocode";
import {
  ActivityArea,
  ActivityImage,
  ActivityImageCircle,
  ActivityProfileArea,
  BackButtonArea,
  MapInfoAreaContainer,
} from "./style";
import { TimeArea } from "pages/alerts/style";
import { getTimeAgo } from "utilities/helpers";
import {
  BACKICON,
  CARACCIDENT,
  DANGERICON,
  FIREICON,
  GUNICON,
  PROFILEIMAGEICON,
  TIMEICON,
} from "assets/assets";
import { ActivityOptions } from "zustandStore/activityStore/type";
import { ALERT_TYPE } from "pages/alerts/data";
import { MapPointer } from "./svg/mapPointer";
import { useState } from "react";

interface ActivityLocationState {
  activityLocation: {
    lat: number;
    lng: number;
  };
  type?: string;
  createdAt: string;
  createdBy: {
    firstName: string;
    lastName: string;
    profilePhoto?: {
      secure_url: string;
    };
  };
}

export const MapInfoArea = ({ text }: any) => {
  const location = useLocation();
  const { state } = location;
  const timeAgo: string = getTimeAgo(state.createdAt) + " ago";

  function getActivityImage(): string {
    switch (state.type) {
      case ActivityOptions.FIRE_ACCIDENT:
        return FIREICON;
      case ActivityOptions.ARMED_ROBBERY:
        return GUNICON;
      case ActivityOptions.VEHICLE_ACCIDENT:
        return CARACCIDENT;
      default:
        return DANGERICON;
    }
  }

  const img = getActivityImage();
  const { addressComponents } = useGeocode(
    state.activityLocation.lat,
    state.activityLocation.lng
  );

  console.log({ state, addressComponents });
  return (
    <MapInfoAreaContainer style={{ color: "black" }}>
      {state.type !== ALERT_TYPE.LOCATION && (
        <ActivityArea>
          <ActivityImageCircle>
            <img src={img} alt="activity" />
          </ActivityImageCircle>
          <div>
            <h1>{state.type}</h1>
            <TimeArea>
              <small>{timeAgo}</small>
              <img src={TIMEICON} alt="userIcon" />
            </TimeArea>
          </div>
        </ActivityArea>
      )}
      <ActivityProfileArea>
        <ActivityImage
          src={state?.createdBy?.profilePhoto?.secure_url || PROFILEIMAGEICON}
          alt="activity"
        />
        <div>
          <h1>{`${state.createdBy.firstName} ${state.createdBy.lastName}`}</h1>
          {addressComponents.length > 6 && (
            <h1>
              {addressComponents[1].short_name}, {addressComponents[5].long_name}
            </h1>
          )}
        </div>
      </ActivityProfileArea>
    </MapInfoAreaContainer>
  );
};

const ActivityMap: React.FC = () => {
  const location = useLocation();
  const state = location.state as ActivityLocationState; // Casting the state with your defined type
  const navigate = useNavigate();
  const [zoom, setZoom] = useState<number>(14);

  const calculateMarkerSize = (zoomLevel: number): number => {
    const baseSize = 30;
    return baseSize * (zoomLevel / 14);
  };

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <BackButtonArea onClick={() => navigate(-1)}>
        <img src={BACKICON} alt="icon" />
      </BackButtonArea>
      <MapInfoArea /* pass necessary props */ />
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLEAPIKEY }}
        defaultCenter={{
          lat: state.activityLocation.lat,
          lng: state.activityLocation.lng,
        }}
        defaultZoom={zoom}
        onChange={({ zoom }) => setZoom(zoom)}
        yesIWantToUseGoogleMapApiInternals
      >
        {/* Ensure your MapPointer component can accept and use the 'size' prop */}
        <MapPointer
          profilePhoto={state.createdBy.profilePhoto?.secure_url || PROFILEIMAGEICON}
          lat={state.activityLocation.lat}
          lng={state.activityLocation.lng}
          size={calculateMarkerSize(zoom)}
        />
      </GoogleMapReact>
    </div>
  );
};

export default ActivityMap;
