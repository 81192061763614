import styled from 'styled-components/macro';
import BrandColors from '../../../globalStyles/utils/colors';
import media from '../../../globalStyles/utils/media';

export const SignUpContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: ${BrandColors.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  padding: 50px 27px;

  ${media.mobile`
      position: relative;
      width: 100%;
  `}

  button {
    width: 100%;
    background-color: ${({ activate }: any) =>
      activate === 'change' ? BrandColors.main : BrandColors.grey};
    border-radius: 8px;
    padding: 15px;
    border: none;
    color: ${BrandColors.white};
    margin-top: 50px;
    cursor: pointer;

    .changeBackground {
      background-color: ${BrandColors.main};
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 0 21px;
  margin: 0 auto;
  /* height: 100%; */
  /* overflow-x: scroll; */
  margin-top: 48px;

  a {
    color: ${BrandColors.main} !important;
    text-decoration: underline;
    width: unset !important;
    font-size: 12px !important;
  }

  ${media.mobile`
            width: 100%;
        `}
`;

export const AgreementArea = styled.div`
  display: flex;
  margin-top: 30px;

  input {
    margin-top: 4px;
    margin-right: 10px;
  }

  h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
  }
`;

// export const SignUpWrapper = styled.div`
//   /* width: 100vw; */
//   height: 100vh;
//   /* position: absolute; */
//   padding: -20px 0 48px 0;
// `;
