import React, { useEffect, useState } from "react";
import { OTPContainer, OtpHolder } from "./style";
import LogoHeader from "../logoHeader/LogoHeader";
import OtpInput from "react-otp-input";
import BrandColors from "globalStyles/utils/colors";
import LoadingButton from "components/loaders/MainLoadingButton";
import { useAuthStore } from "pages/authPages/store";
import { HOME_URL } from "routes/frontend";
import useToast from "hooks/useToast";
import { useNavigate } from "react-router-dom";
import { useProfile } from "hooks/useProfile";

export default function Pin() {
  const [pin, setPin] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const auth: any = useAuthStore();
  const { toast } = useToast();
  const navigate = useNavigate();
  const { profileData } = useProfile();

  useEffect(() => {
    profileData.pin && navigate(HOME_URL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log({ profileData, pin });

  return (
    <OTPContainer>
      <LogoHeader logoWidth={50} text="Setup 4-digit security pin" />
      <h3>Please create your 4-digit security pin</h3>
      <OtpHolder>
        <OtpInput
          value={pin}
          onChange={(pin: string) => setPin(pin)}
          shouldAutoFocus={true}
          numInputs={4}
          separator={<span> </span>}
          hasErrored={error}
          isInputNum={true}
          containerStyle={{
            marginRight: 10,
          }}
          errorStyle={{
            border: "2px solid #F44336",
          }}
          focusStyle={{
            border: `2px solid ${BrandColors.main}`,
          }}
          inputStyle={{
            width: 50,
            height: 60,
            backgroundColor: BrandColors.background,
            borderRadius: 8,
            border: "1px solid #41474F",
            marginRight: 10,
            color: "#ffff",
            fontSize: 30,
          }}
        />
        <LoadingButton
          disabled={pin.length < 4}
          buttonText="YOU'RE DONE!"
          onClickFn={(e: any) =>
            auth.handleUpdatePin(toast, setLoading, setError, pin)
          }
          loading={loading}
        />
      </OtpHolder>
    </OTPContainer>
  );
}
