import { EMPTYICON } from 'assets/assets'
import { EmptyPostContainer } from 'pages/profile/style'

export default function EmptyActivity() {
  return (
    <EmptyPostContainer style={{marginTop: 250}}>
      <img src={EMPTYICON} alt="empty" />
      <h2>NO ACTIVITY</h2>
      <p>There no activities around you</p>
    </EmptyPostContainer>
  )
}
