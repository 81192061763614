import { useRef, useState, useEffect } from "react";
import { NumberItem, NumberList, WheelContainer } from "./style";

export type WheelComponentProps = {
  numberLength: number;
  getSelectedNumber: Function;
  number: number | string;
  forceSelected?: Function;
};

function WheelComponent({
  numberLength,
  getSelectedNumber,
  number,
  forceSelected,
}: WheelComponentProps) {
  const numbers: number[] = Array.from({ length: numberLength }, (_, i) => i);
  const [selectedNumber, setSelectedNumber] = useState(number);
  const containerRef: any = useRef(null);

  const handleScroll = () => {
    const container = containerRef.current;
    const containerRect = container.getBoundingClientRect();
    const numberItems = container.querySelectorAll("li");

    let closestNumber: number | null | any = null;
    let closestDistance = Number.POSITIVE_INFINITY;

    numberItems.forEach((numberItem: any) => {
      const numberRect = numberItem.getBoundingClientRect();
      const distance = Math.abs(
        numberRect.top - containerRect.top - containerRect.height / 2.8
      );

      if (distance < closestDistance) {
        closestNumber = numberItem.innerText;
        closestDistance = distance;
      }
    });

    const parseSelectedNumber = parseInt(closestNumber, 10);
    setSelectedNumber(parseSelectedNumber);
    getSelectedNumber(parseSelectedNumber);
  };
  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSelectedNumber]);

  const handleDragStart = (event: any, number: any) => {
    event.dataTransfer.setData("text/plain", String(number));
  };

  const handleClick = (number: any) => {
    setSelectedNumber(number);
    const numberItem = containerRef.current.querySelector(
      `li[data-number="${number}"]`
    );
    numberItem.scrollIntoView({ behavior: "smooth", block: "center" });
    getSelectedNumber(number);
  };

  return (
    <WheelContainer ref={containerRef}>
      <NumberList itemCount={numbers.length || 0}>
        {numbers.map((number) => (
          <NumberItem
            id={`${number}`}
            key={number}
            isSelected={selectedNumber === number}
            onClick={() => handleClick(number)}
            draggable
            onDragStart={(event: any) => handleDragStart(event, number)}
            data-number={number}
            num={Number(selectedNumber)}
          >
            {number < 10 ? `0${number}` : number}
          </NumberItem>
        ))}
      </NumberList>
    </WheelContainer>
  );
}

export default WheelComponent;
