import create, { StoreApi, UseBoundStore } from "zustand";

export type LocationStoreType = {
  locationData: any;
  lat: any;
  lng: any;
  speed: number;
  timestamp: number;
  accuracy: number;
  heading: any;
  error: any;
  address: string,
  addressComponents: any,
  setLocation: Function
};

const locationObj = {
  locationData: 0,
  lat: 0,
  lng: 0,
  speed: 0,
  timestamp: 0,
  accuracy: 0,
  heading: 0,
  error: 0,
  address: '',
  addressComponents:[],
};

function setLocation(set: Function, location: LocationStoreType) {
  set({
    ...location,
  });
}

export const useLocationStore: UseBoundStore<StoreApi<LocationStoreType>> = create((set) => ({
  ...locationObj,
  setLocation: (location: LocationStoreType) => setLocation(set, location),
}));

