import { AddPeopleHeaderContainer, BackDivArea } from "./style";
import { BACKICON } from "assets/assets";
import useAppNavigator from "hooks/useAppNavigator";
// import { useProfile } from "hooks/useProfile";
// import { TLink } from "globalStyles/triggerStyles/style";

type SimpleBackHeaderType = {
  headerLabel: string;
  link?: string;
};

function SimpleBackHeader({ headerLabel, link }: SimpleBackHeaderType) {
  // const { profileURL } = useProfile();
  const { appNavigator } = useAppNavigator();

  return (
    <AddPeopleHeaderContainer>
      <BackDivArea onClick={() => appNavigator(link || -1)}>
        <img src={BACKICON} alt="back" />
        <h2>{headerLabel}</h2>
      </BackDivArea>
    </AddPeopleHeaderContainer>
  );
}

export default SimpleBackHeader;
