import BrandColors from "globalStyles/utils/colors";
import styled from "styled-components/macro";

type CheckinStyleProps = {
  edited?: boolean;
  disabled?: boolean;
};

export const CheckInContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${BrandColors.background};
  display: flex;
  flex-direction: column;
  color: white;
  overflow-x: hidden;
  padding-bottom: 80px;
  padding-top: 80px;
`;

export const SelectContactsContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${BrandColors.background};
  display: flex;
  flex-direction: column;
  color: white;
  overflow-x: hidden;
  padding-bottom: 80px;
  position: fixed;
  top: 0;
  z-index: 3;
`;

export const TimerArea = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: ${BrandColors.main};
    border: 1px solid white-space;
  }
`;

export const CheckinMap = styled.div`
  position: relative;
  border-radius: 8px;
  height: 240px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -50px;
`;

export const MapDetailsArea = styled.div`
  background: rgba(68, 164, 190);
  backdrop-filter: blur(4px);
  border-radius: 8px;
  height: 70px;
  width: 97%;
  position: absolute;
  bottom: 5px;
  margin: 0 auto;
  padding: 12px;
  opacity: 0.8;
`;

export const MapDetailsTexts = styled.div`
  border-radius: 8px;
  /* height: 50px; */
  width: 97%;
  position: absolute;
  bottom: 5px;
  padding: 12px;

  h5 {
    /* position: fixed; */
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: black;
    color: #ffffff;
    width: 97%;
    margin-left: 12px;
  }

  h3 {
    /* position: fixed; */
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: black;
    color: #ffffff;
    width: 97%;
    margin-left: 12px;
  }
`;

export const TimerText = styled.h4`
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 68px;
  text-align: center;
  color: ${({ edited }: CheckinStyleProps) => (edited ? "#ffffff" : "#55677C")};
  margin-bottom: 12px;
`;

export const ClearSelectionArea = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: fixed;
  bottom: 0;
  padding: 24px;
  justify-content: space-between;

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #abb7c6;
  }

  div {
    display: flex;
    align-items: center;
  }

  button {
    color: white;
    background-color: ${BrandColors.main};
    width: 123px;
    height: 50px;
    border-radius: 8px;
    border: none;
  }
`;

export const PeopleNumber = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid #abb7c6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #abb7c6;
`;

export const LocationButtonArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 80px;

  div {
    display: flex;

    h3 {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #abb7c6;
      margin-right: 15px;
    }
  }

  button {
    background: ${({ disabled }: CheckinStyleProps) =>
      disabled ? "#41474f" : BrandColors.main};
    color: ${({ disabled }: CheckinStyleProps) => (disabled ? "#abb7c6" : "white")};
    border: none;
    height: 40px;
    width: 131px;
    border-radius: 4px;
  }
`;

export const CheckInFormArea = styled.div`
  padding: 24px;

  textArea {
    max-width: 100%;
    min-width: 100%;
    min-height: 100px;
    max-height: 100px;
    background: #41474f;
    border-radius: 6px;
    color: white;
    padding: 10px;
    border: none;

    ::placeholder {
      color: #abb7c6;
    }

    :focus {
      outline: none;
    }
  }
`;
