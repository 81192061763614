import { CircleProps } from "zustandStore/circle/type";
import { CircleDiv, CircleImageDiv, EditIcon } from "./style";
import { EDITPEN, PEOPLEICON, WHITETRIGGERLOGO } from "assets/assets";
import { useNavigate } from "react-router-dom";
import { EDIT_CIRCLE_URL } from "routes/frontend";

interface CircleTypes extends CircleProps {
  useDefaultImage?: boolean;
  showEdit?: boolean;
}

export default function Circle({
  useDefaultImage = true,
  _id,
  name,
  members,
  showEdit = true,
}: CircleTypes) {
  const navigate = useNavigate();

  function routeToEditCircle() {
    navigate(EDIT_CIRCLE_URL, { state: { _id, name, members } });
  }

  return (
    <CircleDiv onClick={showEdit ? routeToEditCircle : ()=>null}>
      <CircleImageDiv>
        <div>
          <img src={useDefaultImage ? WHITETRIGGERLOGO : PEOPLEICON} alt="icon" />
        </div>
        <h2>{name}</h2>
      </CircleImageDiv>
      {showEdit && <EditIcon src={EDITPEN} alt="icon" />}
    </CircleDiv>
  );
}
