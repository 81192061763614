import { ACCOUNTICON, BELLICON, PANICICON, SUBSCRIPTIONICON } from "assets/assets";
import { ACCOUNT_INFORMATION_URL, ALERT_URL } from "routes/frontend";

export const generalMenuData = [
  {
    label: "My Account",
    link: ACCOUNT_INFORMATION_URL,
    icon: ACCOUNTICON,
  },
  {
    label: "Panic Alerts",
    link: "",
    icon: PANICICON,
  },
  {
    label: "Notifications",
    link: ALERT_URL,
    icon: BELLICON,
  },
  {
    label: "Subscriptions",
    link: "",
    icon: SUBSCRIPTIONICON,
  },
];