import { useAuthStore } from "pages/authPages/store";
import Modal from ".";
import { ModalCancelIcon, OtpHolder, PinText } from "./style";
import OtpInput from "react-otp-input";
import { useState } from "react";
import BrandColors from "globalStyles/utils/colors";
import LoadingButton from "components/loaders/MainLoadingButton";

export interface PinTypes {
  clickAction: Function;
  data?: any;
  isLoading?: boolean;
  error?: boolean;
  buttonText?: string;
  setError: Function;
}

export default function PinModal({
  clickAction,
  isLoading,
  error,
  setError,
  buttonText = "SEND LOCATION",
}: PinTypes) {
  const authObj: any = useAuthStore();
  const [pin, setPin] = useState("");
  // const [loading, setLoading] = useState(false);

  function handleButtonClick() {
    clickAction(pin);
  }

  function handleInputChange(pin: string) {
    setError(false);
    setPin(pin);
  }

  const h3Style = {
    color: "#FFF",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 500,
  };

  return (
    <Modal showModal={authObj.pin.openModal}>
      <ModalCancelIcon onClick={() => authObj.openPinModal(false)}>
        X
      </ModalCancelIcon>
      <>
        {/* <LogoHeader logoWidth={50} text="Setup 4-digit security pin" /> */}
        <PinText style={h3Style}>Please type in the 4-digit security pin</PinText>
        <OtpHolder style={{ alignItems: "center" }}>
          <OtpInput
            value={pin}
            onChange={(pin: string) => handleInputChange(pin)}
            shouldAutoFocus={true}
            numInputs={4}
            separator={<span> </span>}
            hasErrored={error}
            isInputNum={true}
            containerStyle={{
              marginRight: 10,
            }}
            errorStyle={{
              border: "2px solid #F44336",
            }}
            focusStyle={{
              border: `2px solid ${BrandColors.main}`,
            }}
            inputStyle={{
              width: 50,
              height: 60,
              backgroundColor: BrandColors.background,
              borderRadius: 8,
              border: "1px solid #41474F",
              marginRight: 10,
              color: "#ffff",
              fontSize: 30,
            }}
          />
          <LoadingButton
            disabled={pin.length < 4}
            buttonText={buttonText}
            onClickFn={handleButtonClick}
            loading={isLoading}
          />
        </OtpHolder>
      </>
    </Modal>
  );
}
