import {  useState } from "react";
import { makeGetRequestWithToken } from "requests/requests";
import { SERVER_GET_USER_PROFILE } from "routes/server";

export const useGetUserProfile = (user_name: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [userProfileData, setUserProfileData] = useState<any>([]);

  function fetchUserProfile() {
    setLoading(true);
    makeGetRequestWithToken(SERVER_GET_USER_PROFILE(user_name))
      .then((res: any) => {
        setLoading(false);
        setUserProfileData(res?.data);
      })

      .catch((err) => {
        setLoading(false);
        Promise.reject(err);
      });
  }


  return {
    fetchUserProfile,
    loading,
    userProfileData,
  };
};