import useProfileStore from "zustandStore/profile";
import { AddPeopleHeaderContainer, BackDivArea, TabArea, TabText } from "./style";
import { BACKICON } from "assets/assets";
import { useProfile } from "hooks/useProfile";
import { TLink } from "globalStyles/triggerStyles/style";
import { useNavigate } from "react-router-dom";

function AddPeopleHeader() {
  const { setCurrentTab, addPeople } = useProfileStore();
  const { currentTab } = addPeople;
  const { profileURL } = useProfile();
  const navigate = useNavigate();

  return (
    <AddPeopleHeaderContainer>
      <TLink to={profileURL}>
        <BackDivArea onClick={() => navigate(-1)}>
          <img src={BACKICON} alt="back" />
          <h2>AddPeople</h2>
        </BackDivArea>
      </TLink>
      <TabArea>
        <TabText onClick={() => setCurrentTab(0)} isActive={currentTab === 0}>
          Search
        </TabText>
        <TabText onClick={() => setCurrentTab(1)} isActive={currentTab === 1}>
          Scan
        </TabText>
      </TabArea>
    </AddPeopleHeaderContainer>
  );
}

export default AddPeopleHeader;
