import React from "react";
import { LogoHeaderDiv } from "./style";
import { WHITETRIGGERLOGO } from "assets/assets";

type LogoHeaderType = {
  text: string;
  top?: string;
  logoWidth?: number;
};

export default function LogoHeader({ text, logoWidth }: LogoHeaderType) {
  return (
    <LogoHeaderDiv>
      <img
        width={logoWidth || 100}
        src={WHITETRIGGERLOGO}
        alt="logoPng"
        style={{ margin: "0 auto", display: "block" }}
      />
      <p>{text}</p>
    </LogoHeaderDiv>
  );
}
