import { ALERT_TYPE, SendAlertPayloadType } from "pages/alerts/data";
import {
  SearchedUserTypes,
  UseSearchPeopleTypes,
} from "pages/profile/addPeople/types";
import { useState } from "react";
import { makePostRequestWithAxios } from "requests/requests";
import { GET_SEARCHED_USER } from "routes/server";
import { sendContactRequestAlert } from "sockets/useAlerts";

function useSearchPeople(): UseSearchPeopleTypes {
  const [search, setSearch] = useState("");
  const [hasNoUser, setHasNoResult] = useState(false);
  const userObj: SearchedUserTypes = {
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    requestStatus: true,
    profilePhoto: null,
    status: "",
  };
  const [searchedUser, setSearchedUser] = useState<SearchedUserTypes>(userObj);
  const [loading, setLoading] = useState<boolean>(false);
  const [sendingRequest, setSendingRequest] = useState<boolean>(false);
  const notSentRequest = "not requested";
  const notReceivedRequest = "not received";

  function handleSearch(e: Event) {
    e.preventDefault();
    if (!search) return;
    setLoading(true);
    makePostRequestWithAxios(GET_SEARCHED_USER, { identifier: search.trimEnd() })
      .then((res: any) => {
        setLoading(false);
        setSearchedUser({
          ...res.data,
          requestStatus:
            res.data.requestStatus.sent === notSentRequest &&
            res.data.requestStatus.received === notReceivedRequest,
          status: res.data.requestStatus.sent,
        });
        return;
      })
      .catch((err) => {
        const backendMessage = "User not found";
        if (err.message === backendMessage) {
          setHasNoResult(true);
        }
        setSearchedUser(userObj);
        setLoading(false);
        console.clear();
      });
  }
  function sendRequest(e: any) {
    e.preventDefault();
    setSendingRequest(true);
    const payload: SendAlertPayloadType = {
      data: {
        email: searchedUser.email,
      },
      alertType: ALERT_TYPE.CONTACT_REQUEST,
    };
    sendContactRequestAlert(payload, setSendingRequest, setSearchedUser);
    // makePostRequestWithAxios(REQUEST_CONTACT_BY_EMAIL_URL, payload)
    //   .then((res: any) => {
    //     setSearchedUser({
    //       ...searchedUser,
    //       requestStatus: false,
    //     });
    //     setSendingRequest(false);
    //   })
    //   .catch(() => {
    //     setSendingRequest(false);
    //     console.clear();
    //   });
  }

  function handleSearchInput(e: any) {
    if (!search) {
      setSearchedUser(userObj);
    }
    setSearch(e.target.value);
    setHasNoResult(false);
  }

  return {
    handleSearchInput,
    search,
    handleSearch,
    searchedUser,
    loading,
    hasNoUser,
    sendRequest,
    sendingRequest,
  };
}

export default useSearchPeople;
