import BrandColors from "globalStyles/utils/colors";
import styled from "styled-components/macro";

export const WelcomeContainer = styled.div`
  background-color: ${BrandColors.background};
  height: 100vh;
  width: 100vw;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 27px;

  img {
    width: 100px;
  }

  h3 {
    margin-top: 100px;
  }

  p {
    margin-top: 17px;
    width: 80%;
    max-width: 375px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #abb7c6;
  }
`;

export const MainButton = styled.button`
  width: 100%;
  background-color: ${ BrandColors.main};
  border-radius: 8px;
  padding: 15px;
  border: none;
  color: ${BrandColors.white};
  margin-top: 48px;
  cursor: pointer;
`;

export const InvertedButton = styled.button`
  width: 100%;
  background-color: ${ BrandColors.background};
  border-radius: 8px;
  padding: 15px;
  border: 1px solid #44A4BE;
  color: ${BrandColors.main};
  margin-top: 20px;
  cursor: pointer;
`;
