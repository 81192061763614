import SimpleBackHeader from "components/headers/SimpleBackHeader";
import {
  HeadText,
  LogoutText,
  MenuDiv,
  SettingsContainer,
  SettingsMenu,
} from "./style";
import { useAuthStore } from "pages/authPages/store";
import { generalMenuData } from "./settings.data";
import { useNavigate } from "react-router-dom";
import { LOGOUTICON } from "assets/assets";

export default function Settings() {
  const auth: any = useAuthStore();
  const navigate = useNavigate();

  function routeTo(link: string) {
    navigate(link);
  }
  return (
    <SettingsContainer>
      <SimpleBackHeader headerLabel="Settings" />
      <SettingsMenu>
        <HeadText>GENERAL</HeadText>
        {generalMenuData.map((menu) => (
          <MenuDiv key={menu.label} onClick={() => routeTo(menu.link)}>
            <img src={menu.icon} alt="icon" />
            <div>
              <h3>{menu.label}</h3>
              {!menu.link && <button>COMING SOON</button>}
            </div>
          </MenuDiv>
        ))}
        <HeadText>FEEDBACK</HeadText>
        <MenuDiv onClick={auth.handleLogout}>
          <img src={LOGOUTICON} alt="icon" />
          <div>
            <LogoutText>Sign Out</LogoutText>
          </div>
        </MenuDiv>
      </SettingsMenu>
    </SettingsContainer>
  );
}
