import React, { ReactElement } from "react";
import styled from "styled-components";

const MobileInstructionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0; /* Set your preferred background color */
`;

const MobileInstructionContent = styled.div`
  text-align: center;
`;

const InstructionText = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const MobileIcon = styled.div`
  font-size: 40px;
  margin-bottom: 20px;
`;

const MobileInstruction: React.FC = (): ReactElement => {
  return (
    <MobileInstructionContainer>
      <MobileInstructionContent>
        <MobileIcon>📱</MobileIcon>
        <InstructionText>
          For the best experience, please switch to a mobile device.
        </InstructionText>
        {/* You can add additional information or links here */}
      </MobileInstructionContent>
    </MobileInstructionContainer>
  );
};

export default MobileInstruction;
