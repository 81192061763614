import { useState } from "react";
import { makeGetRequestWithToken } from "requests/requests";
import { SERVER_GET_USER_ACTIVITY } from "routes/server";

export const useGetUserActivityPost = (user_name: string) => {
  const [activityPost, setUserActivityPost] = useState<any>([]);

  const [activityPostLoading, setActivityPostLoading] = useState<boolean>(false);

  function getUserActivityPost() {
    makeGetRequestWithToken(SERVER_GET_USER_ACTIVITY(user_name))
      .then((response: any) => {
        setUserActivityPost(response?.data);

        setActivityPostLoading(false);
      })
      .catch((error) => {
        setActivityPostLoading(false);

        Promise.reject(error);
      });
  }

  return { activityPost, activityPostLoading, getUserActivityPost };
};
