/* eslint-disable react/prop-types */
import { ChangeEventHandler, useEffect, useState } from "react";
import { DefaultInputDiv, ErrMessage, InputHolder } from "./style";
import { EYEICON, HIDEPASSWORD } from "assets/assets";

type DefaultInputTypes = {
  label: string;
  type: string;
  name: string;
  placeholder: string;
  handlechange: ChangeEventHandler;
  required?: boolean;
  err?: string | null;
  handleBlur?: ChangeEventHandler;
  handleFocus?: ChangeEventHandler;
  value: string;
  inputTextColor?: string;
};

export default function DefaultInput({
  label,
  type,
  name,
  placeholder,
  handlechange,
  required,
  err = "",
  handleBlur,
  handleFocus,
  value,
  inputTextColor,
}: DefaultInputTypes) {
  const [showPassword, setShowPassword] = useState(false);
  function handlePasswordVisibility() {
    setShowPassword(!showPassword);
  }

  useEffect(() => {}, [value]);

  return (
    <DefaultInputDiv>
      <p>{label}</p>
      <InputHolder
        color={inputTextColor}
        hasValue={!!value}
        type={type}
        className={(err && "error") || ""}
      >
        {type === "phone" && <h3>+234 (0)</h3>}
        <input
          className={(err && "error") || ""}
          value={value}
          required={required}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handlechange}
          maxLength={type === "phone" ? 10 : 1000}
          name={name}
          type={showPassword ? "text" : type}
          placeholder={placeholder}
        />
        {type === "password" && (
          <img
            alt="eye"
            onClick={handlePasswordVisibility}
            src={showPassword ? HIDEPASSWORD : EYEICON}
          />
        )}
      </InputHolder>
      {err && <ErrMessage>{err}</ErrMessage>}
    </DefaultInputDiv>
  );
}
