import BrandColors from "globalStyles/utils/colors";
import styled from "styled-components/macro";

export const SearchContactContainer = styled.div`
  width: 100%;
  display: flex;
  color: white;
  position: relative;
  overflow-x: hidden;
  background-color: #41474f;
  border-radius: 8px !important;
  padding-right: 34px;

  img {
    position: absolute;
    right: 14px;
    top: 15px;
  }

  input {
    width: 100% !important;
    padding: 16px !important;
    color: white;
    /* border: 2px solid ${BrandColors.lightGrey} !important; */
    box-sizing: border-box !important;
    border: none;
    height: unset !important;
    font-size: 16px;
    background-color: transparent;
    &.error {
      border: 2px solid ${BrandColors.error} !important;
      outline: none;
      font-weight: normal;
    }

    ::placeholder {
      color: #abb7c6;
    }

    :focus {
      outline: none;
    }
  }
`;
