import BrandColors from "globalStyles/utils/colors";
import styled from "styled-components";
export const SettingsContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${BrandColors.background};
  display: flex;
  flex-direction: column;
  color: white;
  overflow-x: hidden;
  padding-bottom: 80px;
`;

export const SettingsMenu = styled.div`
  margin-top: 60px;
  padding: 0 24px;
`;

export const AccountInformationArea = styled.div`
  padding: 0 24px;
  margin-top: 70px;
  height: 100vh;
`;

export const ProfileUpdateArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    text-align: center;

    h2 {
      color: #fff;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 16px;
    }

    p {
      color: #abb7c6;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 4px;
    }
  }

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
`;

export const HeadText = styled.h2`
  color: #abb7c6;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 33px;
`;


export const MenuDiv = styled.div`
  display: flex;
  margin-top: 33px;
  align-items: center;
  width: fit-content;

  img {
    width: 15px;
    margin-right: 24px;
  }

  div {
    display: flex;
  }

  button {
    border: none;
    padding: 4px;
    margin-left: 17px;
    border-radius: 4px;
    background: #abb7c6;
    color: #41474f;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  h3 {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const LogoutText = styled.div`
  color: #ee3c3c;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
`;

export const FieldArea = styled.div`
  display: flex;
  margin-top: 35px;

  img {
    margin-right: 26px;
    margin-top: -10px;
  }

  div {
    small {
      color: #abb7c6;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    h3 {
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 6px;
      text-transform: capitalize;
    }
  }
`;

export const FieldListArea = styled.div`
  margin-top: 50px;
`;
