import { PROFILEIMAGEICON, TIMEICON } from "assets/assets";
import {
  LocationText,
  NameAndTimeDiv,
  NameArea,
  PostCard,
  PostText,
  PostsContainer,
  ProfileLoaderStyle,
  UserActivityBottomAreaStyle,
} from "./style";
import { useNavigate, useParams } from "react-router-dom";
import ComponentLoader from "components/loaders/ComponentLoader";
import { ActivityProfilePostProps } from "./types";
import { getTimeAgo } from "utilities/helpers";
import { ACTIVITY_MAP_URL } from "routes/frontend";
import EmptyPost from "./EmptyPost";
import { useEffect } from "react";
import { useGetUserActivityPost } from "hooks/userGetUserActivityPost";

export function Post({
  message,
  timeAgo,
  fullname,
  profileImage,
  type,
  createdBy,
  createdAt,
  location,
}: any) {
  const navigate = useNavigate();
  function handleViewLocation() {
    navigate(ACTIVITY_MAP_URL, {
      state: { activityLocation: location, message, type, createdBy, createdAt },
    });
  }
  return (
    <PostCard>
      <img
        style={{ objectFit: "cover" }}
        src={profileImage || PROFILEIMAGEICON}
        alt="profile"
      />
      <NameArea>
        <NameAndTimeDiv>
          <h3>{fullname}</h3>
          <div>
            <h4>{timeAgo}</h4>
            <img src={TIMEICON} alt="time" />
          </div>
        </NameAndTimeDiv>
        <PostText>{message}</PostText>
        <UserActivityBottomAreaStyle>
          <LocationText onClick={handleViewLocation} data-test-id={location}>
            View Location
          </LocationText>
          <div>
            <h4>{type}</h4>
          </div>
        </UserActivityBottomAreaStyle>
      </NameArea>
    </PostCard>
  );
}

export default function PostMain() {
  const { user_name } = useParams();
  const { activityPost, activityPostLoading, getUserActivityPost } =
    useGetUserActivityPost(user_name as string);

  useEffect(() => {
    getUserActivityPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderUserActivityPost = () => {
    if (activityPostLoading && activityPost.length < 1) {
      return (
        <ProfileLoaderStyle>
          <ComponentLoader />
        </ProfileLoaderStyle>
      );
    }

    if (!activityPostLoading && activityPost.length < 1) {
      return <EmptyPost />;
    }

    return (
      <>
        {activityPost?.map((activity: ActivityProfilePostProps) => {
          console.log(activity);
          const profileImage = activity.createdBy?.profilePhoto?.url;

          const fullname = `${activity?.createdBy?.firstName} ${activity?.createdBy?.lastName}`;

          const timeAgo: string = getTimeAgo(activity?.createdAt);

          const message = activity?.message;
          return (
            <main key={activity?._id}>
              <Post
                location={activity?.location}
                createdBy={activity?.createdBy}
                createdAt={activity?.createdAt}
                type={activity?.type}
                message={message}
                fullname={fullname}
                timeAgo={timeAgo}
                profileImage={profileImage}
                // activityPost={activityPost}
              />
            </main>
          );
        })}
      </>
    );
    //  <Post activityPost={activityPost} />;
  };

  return (
    <>
      <PostsContainer>{renderUserActivityPost()}</PostsContainer>
    </>
  );
}
