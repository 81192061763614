import BrandColors from "globalStyles/utils/colors";
import styled from "styled-components/macro";

export const ProfileContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${BrandColors.background};
  display: flex;
  flex-direction: column;
  color: white;
  overflow-x: hidden;
  padding-bottom: 80px;
`;

export const MainProfileArea = styled.div`
  width: 100%;
  height: 388px;
  background: #2a3139;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 20px 20px;
  padding-top: 100px;
`;

export const ProfileImageArea = styled.div`
  display: flex;
  padding: 0 27px;
  align-items: center;

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #abb7c6;
    margin-top: 5px;
  }

  img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
  }

  div {
    padding-left: 25px;
  }
`;

export const ProfileRecordArea = styled.div`
  display: flex;
  padding: 0 27px;
  margin-top: 14px;
`;

export const ProfileRecord = styled.div`
  text-align: center;
  margin-right: 36px;
  position: relative;
  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #abb7c6;
    margin-top: 10px;
  }
`;

export const CirclesHolder = styled.div`
  display: flex;
  margin-top: 15px;
`;

export const EditButton = styled.div`
  /* width: 125px; */
  height: 40px;
  border: 1.5px solid #41474f;
  border-radius: 8px;
  background-color: transparent;
  color: white;
  position: relative;
  right: -20%;
  margin-top: 15px;
`;

export const PaddedArea = styled.div`
  width: 100%;
  padding: 0 27px;
`;

export const CreateCircleButton = styled.button`
  width: 100%;
  height: 50px;
  border: 1.5px solid #41474f;
  border-radius: 8px;
  background-color: transparent;
  color: white;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 18px 16px;
  justify-content: center;
  position: relative;
  margin-top: 24px;

  img {
    position: absolute;
    left: 16px;
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
  }
`;

export const PostArea = styled.div``;

export const EmptyPostContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40%;

  h2 {
    font-family: "Avenir";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #abb7c6;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  p {
    font-family: "Avenir";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #979797;
  }
`;

export const PostsContainer = styled.div`
  padding: 0 24px;
  width: 100vw;
  font-family: "Avenir";
`;

export const PostCard = styled.div`
  display: flex;
  margin-top: 15px;

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 18px;
  }
`;

export const NameArea = styled.div`
  width: 100%;
  border-bottom: 2px solid #41474f;
  padding-bottom: 12px;
`;

export const NameAndTimeDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 12% !important;
    margin-left: 7px;
    margin-right: 0px;
  }

  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
  }

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }

  div {
    display: flex;
    align-items: center;
  }
`;

export const PostText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
`;

export const LocationText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #44a4be;
  margin-top: 16px;
`;
