import PageTransitionProvider from "utilities/PageTransition";
import { InvertedButton, MainButton, WelcomeContainer } from "./style";
import { WHITETRIGGERLOGO } from "assets/assets";
import { TriggerLink } from "globalStyles/triggerStyles/style";
import { LOGIN_URL, SIGNUP_URL } from "routes/frontend";

export default function Welcome() {
  return (
    <PageTransitionProvider>
      <WelcomeContainer>
        <img src={WHITETRIGGERLOGO} alt="welcome" />
        <h3>A peer to peer safety network</h3>
        {/* <p>
          Trigger helps you keep your family and friends safe, and allows them do the
          same for you.
        </p> */}
        <p>
          TriggerApp helps you instantly share vital
          information with everyone that matters during an emergency.
        </p>
        <TriggerLink to={LOGIN_URL}>
          <MainButton>SIGN IN</MainButton>
        </TriggerLink>
        <TriggerLink to={SIGNUP_URL}>
          <InvertedButton>SIGN UP</InvertedButton>
        </TriggerLink>
      </WelcomeContainer>
    </PageTransitionProvider>
  );
}
