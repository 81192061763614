import { SearchContactContainer } from "./style";
import { SEARCHICON } from "assets/assets";

type SearchContactProps = {
  searchName: string;
  setSearchName: Function;
};

export default function SearchContact({
  searchName,
  setSearchName,
}: SearchContactProps) {
  return (
    <SearchContactContainer>
      <input
        value={searchName}
        onChange={(e) => setSearchName(e.target.value)}
        placeholder="search contacts"
      />
      <img src={SEARCHICON} alt="search" />
    </SearchContactContainer>
  );
}
