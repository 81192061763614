import create, { StoreApi, UseBoundStore } from "zustand";
import { ActivityStoreType, ShortTextDropdownType } from "./type";
import {
  activatePostButton,
  deactivatePostButton,
  fetchActivities,
  fetchMyActivities,
  handleDropdownChange,
  handlePostWriting,
  refreshActivityStore,
  turnOffCreatePost,
  turnOnCreatePost,
} from "./actions";

export const activityObj = {
  postShortTextList: [
    {
      value: "Vehicle Accident",
      label: "Vehicle Accident",
    },
    {
      value: "Dangerous Activity",
      label: "Dangerous Activity",
    },
    {
      value: "Fire Accident",
      label: "Fire Accident",
    },
    {
      value: "I'm being followed",
      label: "I'm being followed",
    },
    {
      value: "Unusual police activity",
      label: "Unusual police activity",
    },
    {
      value: "Armed Robbery",
      label: "Armed Robbery",
    },
  ],
  postShortText: "Post Category",
  postMainText: "",
  canSendPost: false,
  showPostField: false,
  activities: [],
  myActivities: [],
};

const useActivityStore: UseBoundStore<StoreApi<ActivityStoreType>> = create(
  (set, get) => ({
    ...activityObj,
    turnOnCreatePost: () => turnOnCreatePost(set),
    refreshActivityStore: () => refreshActivityStore(set),
    turnOffCreatePost: () => turnOffCreatePost(set),
    handlePostWriting: (event: any) => handlePostWriting(event, set, get),
    fetchMyActivities: (setLoading: Function) =>
      fetchMyActivities(set, get, setLoading),
    fetchActivities: (setLoading: Function) => fetchActivities(set, get, setLoading),
    activatePostButton: () => activatePostButton(set, get),
    deactivatePostButton: () => deactivatePostButton(set, get),
    handleDropdownChange: (option: ShortTextDropdownType) =>
      handleDropdownChange(option, set, get),
  })
);

export default useActivityStore;
