import {
  CreatePostArea,
  CreatePostButtonDiv,
  CreatePostComponentDiv,
  PostButtonArea,
  PostOptionArea,
  SelectDropdown,
  SendPostButton,
} from "./style";
import { PROFILEIMAGEICON } from "assets/assets";
import useActivityStore from "zustandStore/activityStore";
import { ActivityStoreType } from "zustandStore/activityStore/type";
import ComponentLoader from "components/loaders/ComponentLoader";
import useActivity from "hooks/useActivity";
import { useProfile } from "hooks/useProfile";

function CreatePostButton() {
  const { turnOnCreatePost } = useActivityStore();
  const { profileData } = useProfile({
    getUserContacts: false,
    getUserCircles: false,
  });
  return (
    <CreatePostButtonDiv>
      <img src={profileData?.profilePhoto?.secure_url || PROFILEIMAGEICON} alt="imgProfile" />
      <button onClick={turnOnCreatePost}>Add a post</button>
    </CreatePostButtonDiv>
  );
}

export const totalText = 150
function CreatePostComponent() {
  const {
    showPostField,
    handleDropdownChange,
    postShortTextList,
    canSendPost,
    handlePostWriting,
    postMainText,
    postShortText,
  }: ActivityStoreType = useActivityStore();

  const {postActivityLoading, handleSendPost} = useActivity({})
  return (
    <CreatePostArea>
      {!showPostField && <CreatePostButton />}
      {showPostField && (
        <CreatePostComponentDiv>
          <textarea
            maxLength={totalText}
            onChange={handlePostWriting}
            placeholder="Write a message"
            value={postMainText}
          ></textarea>
          <PostOptionArea>
            <SelectDropdown
              placeholder="Post Category"
              value={{
                value: postShortText,
                label: postShortText,
              }}
              onChange={handleDropdownChange}
              isSearchable={false}
              options={postShortTextList}
            />
            <PostButtonArea>
              <div>{totalText - postMainText.length}</div>
              {!postActivityLoading? <SendPostButton
                disabled={!canSendPost}
                onClick={handleSendPost}
                canSend={canSendPost}
              >
                Send
              </SendPostButton>: <ComponentLoader />}
            </PostButtonArea>
          </PostOptionArea>
        </CreatePostComponentDiv>
      )}
    </CreatePostArea>
  );
}

export default CreatePostComponent;
