import { useLocation, Navigate } from "react-router-dom";
import { HOME_URL, PIN_URL, WELCOME_URL } from "routes/frontend";
import { getAuthCookie } from "./helpers";
import { useAuthStore } from "pages/authPages/store";
import { useProfile } from "hooks/useProfile";

const cookieToken = getAuthCookie();

export default function ProtectedRoute({ children, isProtected }: any) {
  const authToken = useAuthStore((state: any) => state.token);
  const token = authToken || cookieToken;
  const location = useLocation();
  const { profileData } = useProfile();

  if(!!token && !profileData?.pin && location.pathname !== PIN_URL){
    return <Navigate to={PIN_URL} state={{ from: location }} replace />;
  }

  if (isProtected && !token) {
    return <Navigate to={WELCOME_URL} state={{ from: location }} replace />;
  } else if (!!token && !isProtected) {
    return <Navigate to={HOME_URL} state={{ from: location }} replace />;
  } else {
    return children;
  }
}
