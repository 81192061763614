import { GOOGLEAPIKEY } from "constants/constants";
import { useEffect, useState } from "react";
import Geocode from "react-geocode";

function useGeocode(lat: any, lng: any) {
  const [address, setAddress] = useState("");
  const [addressComponents, setAddressComponents] = useState<any>([]);
  Geocode.setApiKey(GOOGLEAPIKEY);
  Geocode.setLanguage("en");
  Geocode.setRegion("es");
  Geocode.setLocationType("ROOFTOP");

  useEffect(() => {
    if (lat && lng) {
      Geocode.fromLatLng(lat, lng).then(
        (response: any) => {
          const address = response.results[0].formatted_address;
          setAddressComponents(response.results[0].address_components);
          setAddress(address);
        },
        (error: any) => {
          console.error(error);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lng]);

  return {
    address,
    setAddress,
    addressComponents,
  };
}

export default useGeocode;
