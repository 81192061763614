import DefaultInput from "components/defaultInput/DefaultInput";
import LoadingButton from "components/loaders/MainLoadingButton";
import { useEffect, useState } from "react";
import LogoHeader from "../logoHeader/LogoHeader";

import { SignUpContainer } from "./style";
import { useAuthStore } from "pages/authPages/store";
import { ButtomText, Form, SpanLink } from "../login/style";
import { LOGIN_URL, OTP_URL } from "routes/frontend";
import { useNavigate } from "react-router-dom";
import useAppNavigator from "hooks/useAppNavigator";

export default function SignUp() {
  const auth: any = useAuthStore();
  const {
    firstName,
    lastName,
    userName,
    email,
    phone,
    password,
    confirmPassword,
    agreement,
  } = auth.signUp;
  const [loading, setLoading] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [formErr, setFormErr] = useState({
    email: null,
    firstName: null,
    lastName: null,
    phone: null,
    password: null,
    confirmPassword: null,
    userName: null,
    referral: null,
  });
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("referral");
  const navigate = useNavigate();
  const { appNavigator } = useAppNavigator();
  console.log({
    firstName,
    lastName,
    userName,
    email,
    phone,
    password,
    confirmPassword,
    agreement,
    otp: auth.otp,
  });
  useEffect(() => {
    if (token) auth.signUp.referred_by = token;
    setIsFormFilled(agreement);
    auth.otp &&
      appNavigator(OTP_URL, {
        form: {
          firstName,
          lastName,
          userName,
          email,
          phone,
          password,
          confirmPassword,
          agreement,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreement, auth.signUp, auth.token, token, auth.otp, navigate]);

  return (
    <SignUpContainer>
      <LogoHeader logoWidth={70} text="Create your TriggerApp account" />
      <Form>
        <DefaultInput
          label="First Name"
          placeholder="E.g Peter"
          type="text"
          name="firstName"
          value={firstName}
          handlechange={(e) => auth.handleRegChange(e)}
          err={formErr.firstName}
          handleFocus={() => auth.clearErrorOnFocus(setFormErr, "firstName")}
        />
        <DefaultInput
          label="Last Name"
          placeholder="E.g Kamela"
          type="text"
          name="lastName"
          value={lastName}
          handlechange={(e) => auth.handleRegChange(e)}
          err={formErr.lastName}
          handleFocus={() => auth.clearErrorOnFocus(setFormErr, "lastName")}
        />
        <DefaultInput
          label="Username"
          placeholder="E.g MaximusPrime102"
          type="text"
          name="userName"
          value={userName}
          handlechange={(e) => auth.handleRegChange(e)}
          err={formErr.userName}
          handleFocus={() => auth.clearErrorOnFocus(setFormErr, "userName")}
        />
        <DefaultInput
          label="Email"
          placeholder="E.g john.doe@address.com"
          type="email"
          name="email"
          value={email}
          handlechange={(e) => auth.handleRegChange(e)}
          err={formErr.email}
          handleFocus={() => auth.clearErrorOnFocus(setFormErr, "email")}
        />
        <DefaultInput
          label="Phone"
          placeholder="E.g 08122233343"
          type="phone"
          name="phone"
          value={phone}
          handlechange={(e) => auth.handleRegChange(e)}
          err={formErr.phone}
          handleFocus={() => auth.clearErrorOnFocus(setFormErr, "phone")}
        />
        <DefaultInput
          label="Password"
          placeholder="**********"
          type="password"
          name="password"
          value={password}
          handlechange={(e) => auth.handleRegChange(e)}
          err={formErr.password}
          handleBlur={() => auth.handlePasswordBlur(setFormErr)}
          handleFocus={() => auth.clearPasswordErrorsOnFocus(setFormErr)}
        />
        <DefaultInput
          label="Confirm Password"
          placeholder="**********"
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          handlechange={(e) => auth.handleRegChange(e)}
          err={formErr.confirmPassword}
          handleBlur={() => auth.handleConfirmPasswordBlur(setFormErr)}
          handleFocus={() => auth.clearPasswordErrorsOnFocus(setFormErr)}
        />
        {/* {token && (
          <DefaultInput
            label="Referral Code (Optional)"
            placeholder="Enter Referral Code"
            type="Text"
            name="referred_by"
            value={referred_by}
            handlechange={(e) => auth.handleRegChange(e)}
            err={formErr.referral}
            handleFocus={() => auth.clearErrorOnFocus(setFormErr, "referral")}
          />
        )} */}
        {/* <AgreementArea>
          <input
            type="checkbox"
            name="agreement"
            value={agreement}
            onClick={(e) => auth.handleTnC(e)}
            defaultChecked
          />
          <h4>
            I have read the{" "}
            <a href={""} target="_blank" rel="noreferrer">
              Contest Rules
            </a>{" "}
            and accept FanBants{" "}
            <a href={""} target="_blank" rel="noreferrer">
              Terms of Conditions.
            </a>
          </h4>
        </AgreementArea> */}
        {/* <TriggerLink to={OTP_URL}> */}
        {/* <LoadingButton
            id="sign-up-id"
            disabled={!isFormFilled || loading}
            buttonText="Sign Up"
            onClickFn={(e: any) => auth.handleOtp(false)}
            loading={loading}
          /> */}
        {/* </TriggerLink> */}
        <LoadingButton
          id="sign-up-id"
          disabled={!isFormFilled || loading}
          buttonText="Sign Up"
          onClickFn={(e: any) => auth.handleOtp(e, setLoading, setFormErr)}
          loading={loading}
        />
      </Form>
      <ButtomText>
        DO YOU ALREADY HAVE AN ACCOUNT? <SpanLink to={LOGIN_URL}>SIGN IN</SpanLink>
      </ButtomText>
    </SignUpContainer>
  );
}
