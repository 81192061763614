import BrandColors from "globalStyles/utils/colors";
import styled from "styled-components/macro";

export const AlertContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${BrandColors.background};
  display: flex;
  flex-direction: column;
  color: white;
  overflow-x: hidden;
  padding-top: 50px;
`;

export const AlertList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContactRequestContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  border-bottom: 1px solid #41474f;
  padding-bottom: 20px;
  padding-top: 20px;
  position: relative;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-left: 48px;
  }
`;

export const NameArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ImageArea = styled.div`
  display: flex;
  align-items: center;

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 18px;
  }
`;

export const TimeArea = styled.div`
  display: flex;
  align-items: center;

  small {
    margin-right: 7px;
    font-size: 10px;
  }

  img {
    width: 10px;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  padding-left: 48px;

  button {
    background-color: transparent;
    color: white;
    border: 1px solid #ffffff;
    border-radius: 6px;
    min-width: 80px;
    min-height: 36px;
    margin-right: 31px;
    margin-top: 18px;
    padding:0 8px;
  }
`;

export const PanicAlertDiv = styled.div`
  position: absolute;
  bottom: 15px;
  left: 25px;
`;
