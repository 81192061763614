import {
  LocationText,
  NameAndTimeDiv,
  NameArea,
  PostCard,
  PostText,
  PostsContainer,
} from "./style";
import { PROFILEIMAGEICON, TIMEICON } from "assets/assets";
import { MAP_URL } from "routes/frontend";
import { TLink } from "globalStyles/triggerStyles/style";
import { getTimeAgo } from "utilities/helpers";

export type PhotoType = {
  asset_id: string;
  public_id: string;
  version: string;
  version_id: string;
  signature: string;
  format: string;
  resource_type: string;
  created_at: string;
  bytes: number;
  type: string;
  placeholder: string;
  url: string;
  secure_url: string;
  folder: string;
  original_filename: string;
  api_key: string;
};

type PostsType = {
  location: any;
  createdBy: any;
  message: string;
  firstName: string;
  lastName: string;
  profilePhoto: PhotoType;
  createdAt: string;
};

export function Post({ post }: any) {
  return (
    <PostCard>
      <img
        style={{ objectFit: "cover" }}
        src={post?.createdBy?.profilePhoto?.secure_url || PROFILEIMAGEICON}
        alt="profile"
      />
      <NameArea>
        <NameAndTimeDiv>
          <h3>{`${post?.createdBy?.firstName} ${post?.createdBy?.lastName}`}</h3>
          <div>
            <h4>{getTimeAgo(post.createdAt)}</h4>
            <img src={TIMEICON} alt="time" />
          </div>
        </NameAndTimeDiv>
        <PostText>{post.message}</PostText>
        <TLink data-location={post.location} to={MAP_URL}>
          <LocationText>View Location</LocationText>
        </TLink>
      </NameArea>
    </PostCard>
  );
}

export default function Posts({ posts }: any) {
  return (
    <PostsContainer>
      {posts?.map((post: PostsType, key: number) => (
        <Post post={post} key={key} />
      ))}
    </PostsContainer>
  );
}
