import {
  AddText,
  CircleContainer,
  CircleHeader,
  CreateButtonArea,
  DeleteIcon,
} from "./style";
import { useLocation, useNavigate } from "react-router-dom";
import { CANCELICON, DELETEICON } from "assets/assets";
import { BackDivArea } from "components/headers/style";
import { CIRCLES_URL } from "routes/frontend";
import DefaultInput from "components/defaultInput/DefaultInput";
import { useState } from "react";
import { PaddedArea } from "../style";
import EmptyList from "components/emptyList";
import LoadingButton from "components/loaders/MainLoadingButton";
import { useProfile } from "hooks/useProfile";
import Contact, { ContactProps } from "./Contact";
import useCircleStore from "zustandStore/circle";
import { CircleStoreType } from "zustandStore/circle/type";
import BrandColors from "globalStyles/utils/colors";

export default function EditCircle() {
  const navigate = useNavigate();
  const [circleName, setCircleName] = useState("");
  const [circleNameErr, setCircleNameErr] = useState("");
  const { contacts } = useProfile({ getUserContacts: true });
  const {
    selectedContacts,
    deleteCircle,
    updateCircle,
    isLoading,
  }: CircleStoreType = useCircleStore();
  const location = useLocation();
  const { state } = location;
  const selectedMembers = state.members;
  const members = Object.keys(selectedContacts || selectedMembers);
  const updatedContacts = contacts.map((contact: any) => {
    const foundContact = selectedMembers.find(
      (member: ContactProps) => member._id === contact.contact._id
    );
    if (foundContact) {
      return { ...contact, isSelected: true };
    } else {
      return { ...contact, isSelected: false };
    }
  });


  if (!state._id) {
    navigate(CIRCLES_URL);
  }

  function handleEditCircle() {
    if (!circleName && !state.name) {
      setCircleNameErr("Add the name of this circle");
      return;
    }
    const body = {
      name: circleName || state.name,
      members,
    };
    updateCircle(body, navigate, state._id);
  }

  return (
    <CircleContainer>
      <CircleHeader>
        <BackDivArea onClick={() => navigate(CIRCLES_URL)}>
          <img src={CANCELICON} alt="cancel" />
          <h2>Edit circle</h2>
        </BackDivArea>
        <DeleteIcon onClick={()=>deleteCircle(navigate, state._id)} src={DELETEICON} alt="delete" />
      </CircleHeader>
      <PaddedArea>
        <DefaultInput
          label=""
          inputTextColor={BrandColors.main}
          placeholder="Type name of circle here..."
          type="text"
          name="circleName"
          handlechange={(e: any) => setCircleName(e?.target?.value)}
          required={true}
          err={circleNameErr}
          value={circleName || state.name}
          handleFocus={() => setCircleNameErr("")}
        />
        <AddText>
          <b>{members.length}</b> people added
        </AddText>
        {updatedContacts.map((contact: any, i) => (
          <Contact
            key={i}
            isSelected={contact.isSelected}
            firstName={contact.contact.firstName}
            lastName={contact.contact.lastName}
            _id={contact.contact._id}
          />
        ))}
        {contacts.length < 1 && (
          <EmptyList
            label="NO CONTACTS FOUND"
            description="please create your circle on this page"
          />
        )}
      </PaddedArea>
      <CreateButtonArea>
        <LoadingButton
          loading={isLoading}
          onClickFn={handleEditCircle}
          disabled={Object.keys(selectedContacts).length < 2 || isLoading}
          buttonText="SAVE"
        />
      </CreateButtonArea>
    </CircleContainer>
  );
}
