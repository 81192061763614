import { secureStorage } from "utilities/encryption";
import { AUTH_TOKEN_NAME, USER_PROFILE_KEY } from "../../../constants/constants";
import { HOME_URL, WELCOME_URL } from "routes/frontend";
import { removeAuthCookie, setAuthCookie } from "utilities/helpers";
import * as validator from "email-validator";
import {
  // makeGetRequestWithToken,
  makePostRequestWithAxios,
  makePutRequestWithAxios,
  // makePutRequestWithAxios,
} from "requests/requests";
import {
  SERVER_LOGIN_URL,
  SERVER_SIGNUP_URL,
  // SOCIAL_LOGIN_URL,
  // SERVER_LOGOUT_URL,
  // SERVER_RESET_PASSWORD_URL,
  SERVER_OTP_URL,
  SERVER_UPDATE_PIN_URL,
} from "routes/server";
import { ToastStoreType } from "zustandStore/toastStore/type";
// import axios from "axios";

export function setOtp(set: any, otp: string | null) {
  set({
    otp,
  });
}

export function handleUpdatePin(
  toast: ToastStoreType,
  setLoading: Function,
  setFormErr: Function,
  pin: string
) {
  setLoading(true);
  makePutRequestWithAxios(SERVER_UPDATE_PIN_URL, { pin })
    .then((res: any) => {
      setLoading(false);
      if (res.success) {
        secureStorage.setItem(USER_PROFILE_KEY, JSON.stringify(res.data));
        window.location.href = "/";
        toast.showSuccessToast("Pin created Sucessfully");
        return null;
      }
    })
    .catch((err) => {
      setLoading(false);
      setFormErr(true);
      toast.showFailedToast("Unable to create pin");
    });
}

export function handleOtp(get: any, e: any, setLoading: any, setFormErr: any) {
  e?.preventDefault();
  const { signUp, setOtp } = get();
  setLoading(true);
  const { password, confirmPassword, userName, email, firstName, lastName, phone } =
    signUp;

  if (!firstName) {
    setLoading(false);
    setFormErr({
      firstName: "first name is missing",
      lastName: null,
      userName: null,
      phone: null,
      email: null,
      password: null,
      confirmPassword: null,
    });
    return;
  }

  if (!lastName) {
    setLoading(false);
    setFormErr({
      firstName: null,
      lastName: "last name is missing",
      userName: null,
      email: null,
      password: null,
      confirmPassword: null,
      phone: null,
    });
    return;
  }

  if (!phone) {
    setLoading(false);
    setFormErr({
      firstName: null,
      lastName: null,
      phone: "phone number is missing",
      userName: null,
      email: null,
      password: null,
      confirmPassword: null,
    });
    return;
  }

  if (!userName) {
    setLoading(false);
    setFormErr({
      userName: "username is missing",
      email: null,
      password: null,
      confirmPassword: null,
      phone: null,
      firstName: null,
      lastName: null,
    });
    return;
  }

  if (userName.indexOf(" ") >= 0) {
    setLoading(false);
    setFormErr({
      userName: "username cant't contain spaces",
      email: null,
      password: null,
      confirmPassword: null,
      phone: null,
      firstName: null,
      lastName: null,
    });
    return;
  }

  if (!email) {
    setLoading(false);
    setFormErr(() => ({
      userName: null,
      email: "email is missing",
      password: null,
      confirmPassword: null,
      phone: null,
      firstName: null,
      lastName: null,
    }));
    return;
  }

  if (!validator.validate(email)) {
    setLoading(false);
    setFormErr({
      userName: null,
      email: `${email} is not a valid email address`,
      password: null,
      confirmPassword: null,
      phone: null,
      firstName: null,
      lastName: null,
    });
    return;
  }

  if (password.length < 6 || confirmPassword.length < 6) {
    setLoading(false);
    setFormErr({
      username: null,
      phone: null,
      firstName: null,
      lastName: null,
      email: null,
      password: true,
      confirmPassword: "password must have at least 6 characters",
    });
    return;
  }

  if (!password || !confirmPassword) {
    setLoading(false);
    setFormErr(() => ({
      username: null,
      phone: null,
      firstName: null,
      lastName: null,
      email: null,
      password: true,
      confirmPassword: "Password doesn't match. Please use the same as above",
    }));
    return;
  }

  if (password !== confirmPassword) {
    setLoading(false);
    setFormErr(() => ({
      username: null,
      phone: null,
      firstName: null,
      lastName: null,
      email: null,
      password: true,
      confirmPassword: "Password doesn't match. Please use the same as above",
    }));
    return;
  }

  const payload = {
    email,
    userName: userName.toLowerCase(),
    phone: `+234${phone}`,
  };
  makePostRequestWithAxios(SERVER_OTP_URL, payload)
    .then((res: any) => {
      setLoading(false);
      if (res?.success) {
        setOtp(res.data);
        return null;
      } else {
        setFormErr(() => ({
          username: null,
          email: null,
          referral: null,
          password: true,
          confirmPassword: "Response error, please try again...",
        }));
      }
    })
    .catch((err: any) => {
      setLoading(false);
      console.clear();
      if (err?.message.includes("Email")) {
        setFormErr(() => ({
          username: null,
          email: err.message,
          password: null,
          referral: null,
          confirmPassword: null,
          phone: null,
        }));
        return;
      }

      if (err?.message.includes("Username")) {
        setFormErr(() => ({
          userName: err.message,
          email: null,
          password: null,
          referral: null,
          confirmPassword: null,
          phone: null,
        }));
        return;
      }

      if (err?.message?.includes("Phone")) {
        setFormErr(() => ({
          username: null,
          email: null,
          password: null,
          referral: null,
          phone: err.message,
          confirmPassword: null,
        }));
        return;
      }

      if (err?.message?.includes("otp")) {
        setFormErr(() => ({
          username: null,
          email: null,
          password: null,
          referral: null,
          phone: err.message,
          confirmPassword: null,
        }));
        return;
      }

      setFormErr(() => ({
        username: null,
        email: null,
        password: true,
        phone: null,
        confirmPassword: "Network error, please try again...",
      }));
    });
  return null;
}

export const setCookieAndRouteToHome = (set: Function, value: any) => {
  setAuthCookie(value);
  set({
    token: value,
  });
};

export const getToken = (get: Function) => {
  const token = get().token;
  return token;
};

export const handleLogout = () => {
  removeAuthCookie();
  secureStorage.removeItem(AUTH_TOKEN_NAME);
  window.location.href = WELCOME_URL;
};

export const openPinModal = (set: Function, value: boolean) => {
  set((prev: any) => ({
    pin: {
      ...prev.pin,
      openModal: value,
    },
  }));
};

export async function handleLogin(
  set: (partial: unknown, replace?: boolean | undefined) => void,
  e: any,
  data: any,
  setErr: Function,
  setLoading: Function,
  navigate: Function
) {
  try {
    e.preventDefault();
    setLoading(true);
    const res: any = await makePostRequestWithAxios(SERVER_LOGIN_URL, {
      email: data.email.toLowerCase(),
      password: data.password,
    });
    if (res?.success) {
      secureStorage.setItem(USER_PROFILE_KEY, JSON.stringify(res.data.user));
      setCookieAndRouteToHome(set, res.data.token);
      // window.location.href = "/";
      navigate("/", { user: res.data.user, token: res.data.token });
      return null;
    } else {
      setErr({
        email: null,
        password: "Network Error, Please try again...",
      });
    }
  } catch (err: any) {
    if (err?.message?.includes("incorrect")) {
      setErr({
        email: null,
        password: "Incorrect email or password.",
      });
      return;
    }
    setErr({
      email: null,
      password: "Network Error, Please try again...",
    });
  } finally {
    setLoading(false);
  }
  // if (!validator.validate(data.email)) {
  //   setErr({
  //     email: `${data.email} is not a valid email address`,
  //     password: null,
  //   });
  //   return;
  // }
}

export function clearErrorOnFocus(setErr: Function, focusedKey: any) {
  setErr((prev: any) => ({
    ...prev,
    [focusedKey]: null,
  }));
}

export function clearPasswordErrorsOnFocus(
  setErr: (arg0: (prev: any) => any) => void
) {
  setErr((prev) => ({
    ...prev,
    password: null,
    confirmPassword: null,
  }));
}

export function handleRegChange(set: Function, e: any) {
  set((prev: any) => ({
    signUp: {
      ...prev.signUp,
      [e.target.name]: e.target.value,
    },
  }));
}

export async function handleConfirmPasswordBlur(get: any, setFormErr: any) {
  const signUp = get().signUp;
  const { password, confirmPassword } = signUp;
  const doesPasswordMatch = password === confirmPassword;

  if (confirmPassword && !doesPasswordMatch) {
    setFormErr(() => ({
      username: null,
      email: null,
      password: true,
      confirmPassword: "Password doesn’t match. Please use the same as above",
    }));
    return;
  }

  if (confirmPassword.length < 6) {
    setFormErr(() => ({
      username: null,
      email: null,
      password: true,
      confirmPassword: "password must have at least 6 characters",
    }));
    return;
  }

  return;
}

export async function handlePasswordBlur(get: any, setFormErr: any) {
  const signUp = get().signUp;
  const { password, confirmPassword } = signUp;
  const doesPasswordMatch = password === confirmPassword;

  if (confirmPassword && !doesPasswordMatch) {
    setFormErr(() => ({
      username: null,
      email: null,
      password: true,
      confirmPassword: "Password doesn’t match. Please use the same as above",
    }));
    return;
  }

  if (password.length < 6) {
    setFormErr(() => ({
      username: null,
      email: null,
      password: true,
      confirmPassword: "password must have at least 6 characters",
    }));
    return;
  }
}

export function handleTnC(get: any, set: any, e: any) {
  const state = get();
  set({
    signUp: {
      ...state.signUp,
      agreement: e.target.checked,
    },
  });
}

export async function handleSignUp(
  get: any,
  e: any,
  setLoading: any,
  setFormErr: any,
  typedOtp: string,
  navigate: any,
  toast: any
) {
  e.preventDefault();
  const { signUp, otp } = get();
  setLoading(true);

  if (typedOtp !== otp) {
    setLoading(false);
    setFormErr(true);
    toast.showFailedToast("OTP:", "User OTP incorrect, try again");
    return;
  }

  const payload = {
    ...signUp,
    phone: `+234${signUp.phone}`,
    userType: "user",
  };
  makePostRequestWithAxios(SERVER_SIGNUP_URL, payload)
    .then((res: any) => {
      setLoading(false);
      if (res?.success) {
        toast.showSuccessToast(
          "Information:",
          "User Successfully Registered, Please Login"
        );
        navigate(HOME_URL);
        return null;
      } else {
        toast.showFailedToast("Error:", "Our Server is currently down");
      }
    })
    .catch((err: any) => {
      setLoading(false);
      console.clear();
      if (err.message) {
        toast.showFailedToast("Error:", err.message);
        return;
      }
      toast.showFailedToast("Error:", "You can't access OTP right now");
    });
}

// export function handleForgotPassword(
//   e: any,
//   data: any,
//   setLoading: any,
//   setRequestSent: any,
//   setEmailErr: any
// ) {
//   e.preventDefault();
//   setLoading(true);
//   makePostRequestWithAxios(SERVER_RESET_PASSWORD_URL, data)
//     .then((res: any) => {
//       setLoading(false);
//       setTimeout(() => setEmailErr(null), 6000);
//       if (res.success) {
//         setRequestSent(true);
//       }
//     })
//     .catch((err) => {
//       setLoading(false);
//       setTimeout(() => setEmailErr(null), 6000);
//       if (err?.email) {
//         setEmailErr("email does not exist");
//       } else {
//         setEmailErr("network error");
//       }
//     });
// }

// export function handleResetPassword(
//   e: any,
//   data: any,
//   setFormErr: any,
//   setLoading: any
// ) {
//   const { confirmPassword, password } = data;
//   e.preventDefault();
//   const defaultErr = {
//     confirmPassword: null,
//     password: null,
//   };

//   if (password.length < 6) {
//     setTimeout(() => setFormErr(defaultErr), 4000);
//     setFormErr({
//       confirmPassword: true,
//       password: "Password can not be less than 6 characters",
//     });
//     return;
//   }

//   if (password !== confirmPassword) {
//     setTimeout(() => setFormErr(defaultErr), 4000);
//     setFormErr({
//       confirmPassword: "Passwords do not match",
//       password: true,
//     });
//     return;
//   }

//   setLoading(true);
//   makePutRequestWithAxios(SERVER_RESET_PASSWORD_URL, data)
//     .then((res: any) => {
//       setLoading(false);
//       if (res.success) {
//         window.location.href = "/login";
//       }
//     })
//     .catch((err) => {
//       setLoading(false);
//       console.clear();
//       setTimeout(() => setFormErr(defaultErr), 6000);
//       if (err.reset_password_token) {
//         setFormErr({
//           confirmPassword: "Token is invalid or expired",
//           password: true,
//         });
//       }

//       if (err.confirmPassword) {
//         setFormErr({
//           confirmPassword: "Passwords do not match",
//           password: true,
//         });
//       }

//       if (err.password) {
//         setFormErr({
//           confirmPassword: "Passwords do not match",
//           password: true,
//         });
//       }
//     });
// }

// export function handleFacebookLogin(
//   get: any,
//   response: any,
//   toast: any,
//   setIsLoadingSocial: any
// ) {
//   const { setCookieAndRouteToHome } = get();
//   if (!response.accessToken) {
//     toast.showResponse(
//       "Error:",
//       "Oops! Something went wrong! Server request timed out. Please try again later.",
//       true
//     );
//     return response;
//   }
//   setIsLoadingSocial(true);
//   var data = {
//     provider: "facebook",
//     uid: response.userID,
//     access_token: response.accessToken,
//   };
//   const requestOptions = {
//     method: "POST",
//     headers: {
//       Authorization: `Bearer ${response.accessToken}`,
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(data),
//   };
//   fetch(SOCIAL_LOGIN_URL, requestOptions)
//     .then((response) => response.json())
//     .then((data) => {
//       if (data?.success) {
//         if (data?.data?.new_user) {
//           toast.showResponse("Success:", `Welcome ${response.email}`, false);
//           setCookieAndRouteToHome(data.data.token);
//           // setSessionData(WELCOME_MESSAGE, `Signed in as ${response.email}`);
//         } else {
//           toast.showResponse("Success:", `Welcome ${response.email}`, false);
//           setCookieAndRouteToHome(data.data.token);
//           // setSessionData(WELCOME_MESSAGE, `Signed in as ${response.email}`);
//         }
//       }
//     })
//     .catch((err) => {
//       setIsLoadingSocial(false);
//       toast.showResponse(
//         "Error:",
//         "Oops! Something went wrong! Server request timed out. Please try again later.",
//         true
//       );
//       return err;
//     });
// }

// export function handleGoogleLogin(
//   get: any,
//   googleUser: any,
//   setLoading: any,
//   toast: any
// ) {
//   const { setCookieAndRouteToHome } = get();
//   setLoading(true);
//   const body = {
//     provider: "google_oauth2",
//     uid: googleUser.Ca,
//     access_token: googleUser.xc.access_token,
//   };

//   axios
//     .post(SOCIAL_LOGIN_URL, body)
//     .then((res: any) => {
//       if (res?.data?.success) {
//         if (res?.data?.data?.new_user) {
//           toast.showResponse("Success:", `Welcome ${googleUser.wt.cu}`, false);
//           // setSessionData(WELCOME_MESSAGE, `Signed in as ${googleUser.wt.cu}`);
//           setCookieAndRouteToHome(res?.data?.data?.token);
//         } else {
//           toast.showResponse("Success:", `Welcome ${googleUser.wt.cu}`, false);
//           // setSessionData(WELCOME_MESSAGE, `Signed in as ${googleUser.wt.cu}`);
//           setCookieAndRouteToHome(res?.data?.data?.token);
//         }
//       } else {
//         toast.showResponse(
//           "Error",
//           `Oops! Something went wrong! Server request timed out. Please try again later.`,
//           true
//         );
//         setLoading(false);
//       }
//     })
//     .catch(() => {
//       setLoading(false);
//       toast.showResponse(
//         "error",
//         "Oops! Something went wrong! Server request timed out. Please try again later.",
//         true
//       );
//       return;
//     });
// }

// export function attachSignin(
//   get: any,
//   element: any,
//   auth2: any,
//   setIsLoadingSocial: any,
//   toast: any
// ) {
//   const { handleGoogleLogin } = get();
//   auth2.attachClickHandler(
//     element,
//     {},
//     (googleUser: any) => {
//       handleGoogleLogin(googleUser, setIsLoadingSocial, toast);
//     },
//     (error: any) => {
//       // toast.showResponse('Error:', 'Popup was closed by user ', true);
//       console.clear();
//     }
//   );
// }
