import Activity from "./Activity";

export interface ActivityListProps {
  activities: any[];
}

export default function ActivityList({ activities }: ActivityListProps) {
  return (
    <div>
      {activities.map(({ message, createdBy, createdAt, type, location }, key) => (
        <Activity
          key={key}
          message={message}
          createdBy={createdBy}
          location={location}
          type={type}
          createdAt={createdAt}
        />
      ))}
    </div>
  );
}
