import { navbarData } from "./data";
import { NavBarArea, NavbarContainer, NavItemContainer, NavItemText } from "./style";
import { useLocation } from "react-router-dom";
import { TriggerLink } from "globalStyles/triggerStyles/style";
import useFeatureGate from "hooks/useFeatureGate";

type BottomNavType = {
  isProtected: boolean | undefined;
};

function NavItems() {
  const { pathname } = useLocation();
  const { isUserEligibleForFeature } = useFeatureGate();
  return (
    <NavBarArea>
      {navbarData.map((nav: any, i: number) => {
        if (nav.hasFeature && isUserEligibleForFeature(nav?.feature)) {
          return (
            <TriggerLink to={nav.path} key={i}>
              <NavItemContainer>
                <div>
                  {pathname === nav.path || pathname === nav.sub ? (
                    <div dangerouslySetInnerHTML={{ __html: nav?.activeSvg }} />
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: nav?.inactiveSvg }} />
                  )}
                </div>
                <NavItemText
                  isActive={pathname === nav.path || pathname === nav.sub}
                >
                  {nav.name}
                </NavItemText>
              </NavItemContainer>
            </TriggerLink>
          );
        } else if (!nav.hasFeature) {
          return (
            <TriggerLink to={nav.path} key={i}>
              <NavItemContainer>
                <div>
                  {pathname === nav.path || pathname === nav.sub ? (
                    <div dangerouslySetInnerHTML={{ __html: nav?.activeSvg }} />
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: nav?.inactiveSvg }} />
                  )}
                </div>
                <NavItemText
                  isActive={pathname === nav.path || pathname === nav.sub}
                >
                  {nav.name}
                </NavItemText>
              </NavItemContainer>
            </TriggerLink>
          );
        } else {
          return null;
        }
      })}
    </NavBarArea>
  );
}

export default function BottomNavigation({ isProtected }: BottomNavType) {
  return <NavbarContainer>{isProtected && <NavItems />}</NavbarContainer>;
}
