import BrandColors from "globalStyles/utils/colors";
import media from "globalStyles/utils/media";
import styled from "styled-components/macro";

export type BottomNavStyleTypes = {
  isActive?: boolean;
  z?: any;
};

export const FullTop = styled.div`
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 10;
`;

export const TopCenterWrapper = styled.div`
  width: 500px;
  height: 64px;
  display: flex;
  z-index: ${({ z }: BottomNavStyleTypes) => z};
  align-items: center;
  background-color: white;
  padding: 0 20px;
  border: 1px solid #ebebeb;
  position: relative;
  /* left: 0; */
  z-index: 1;
  margin: 0 auto;

  img {
    cursor: pointer;
    width: unset !important;
    height: unset !important;
  }

  ${media.mobile`
    width: 100%;
  `}
`;

export const ImageHolder = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BottomCenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  /* position: fixed;
  bottom: 0;
  left: 0; */
`;

export const NavbarContainer = styled.div`
  width: 100%;
  height: 100%;
  z-index: 200;
  overflow: hidden;
  /* position: fixed; */
`;

export const TopNavDiv = styled.div`
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20px;
  overflow: hidden;

  @media (max-width: 720px) {
    width: 100%;
  }

  img {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const NavBarArea = styled.div`
  height: 70px;
  width: 500px;
  position: fixed;
  bottom: 0;
  margin: 0 auto;
  background-color: ${BrandColors.background}};
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* border-top: 1px solid #ebebeb; */

  @media (max-width: 720px) {
    width: 100%;
  }
`;

export const NavItemContainer = styled.div`
  /* width: 100px; */
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;


  div {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
  }
`;

export const NavItemText = styled.p`
  color: ${({ isActive }: BottomNavStyleTypes) =>
    isActive ? "#FFFFFF" : "#55677C"};
  font-size: 11px;
  font-weight: 500;
  text-align: center;
`;

export const NavItemImage = styled.img`
  margin: 0 aut0;
`;
