import styled from "styled-components/macro";
import BrandColors from "globalStyles/utils/colors";

type MainButtonTypes = {
  invert: string
}

export const Button = styled.button`
  width: 100%;
  background-color: ${({ invert }: MainButtonTypes) =>
    invert === "yes" ? BrandColors.white : BrandColors.main};
  border-radius: 8px;
  padding: 15px;
  border: ${({ invert }: MainButtonTypes) =>
    invert === "yes" ? `1px solid rgba(0, 0, 0, 0.4);` : "none"};
  color: ${({ invert }: MainButtonTypes) =>
    invert === "yes" ? BrandColors.main : BrandColors.white};
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  font-weight: bold;
  font-size: 18px;

  .changeBackground {
    background-color: ${BrandColors.main} !important;
  }

  img {
    width: 30px;
  }
`;

export const LoadingButtonDiv = styled.div`
  margin-top: 0px;
`;

export const LoadingScreenDiv = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  flex-direction: column;
  z-index: 100;
  padding: 16px;
  position: fixed;
  top: 0;
  left: 0;

  div {
    width: 100%;
  }

  h1 {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
  }

  h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin-top: 16px;
  }
`;
