import { PROFILEIMAGEICON, TIMEICON } from "assets/assets";
import {
  ContactRequestContainer,
  ImageArea,
  NameArea,
  TimeArea,
} from "pages/alerts/style";
import { getTimeAgo } from "utilities/helpers";
import { PostBottomArea } from "./style";
import { ACTIVITY_MAP_URL, getUserProfiile } from "routes/frontend";
import { useNavigate } from "react-router-dom";


export interface ActivityProps {
  message: string;
  type: string;
  createdBy: any;
  createdAt: Date;
  location: any;
}

export default function Activity({
  message,
  type,
  createdBy,
  createdAt,
  location,
}: ActivityProps) {
  const timeAgo: string = getTimeAgo(createdAt);
  const navigate = useNavigate();

  function handleViewLocation() {
    navigate(ACTIVITY_MAP_URL, {
      state: { activityLocation: location, message, type, createdBy, createdAt },
    });
  }
  console.log(createdBy)

  const viewUserProfile =() => navigate(getUserProfiile(createdBy?.userName))
  
  return (
    <ContactRequestContainer>
      <NameArea>
        <ImageArea>
          <img
          onClick={viewUserProfile}
            src={createdBy?.profilePhoto?.secure_url || PROFILEIMAGEICON}
            alt="userIcon"
          />
          <h2 onClick={viewUserProfile}>{`${createdBy?.firstName} ${createdBy?.lastName}`}</h2>
        </ImageArea>
        <TimeArea>
          <small>{timeAgo}</small>
          <img src={TIMEICON} alt="userIcon" />
        </TimeArea>
      </NameArea>
      <p>{message}</p>
      <PostBottomArea>
        <div style={{ background: "none" }} onClick={handleViewLocation}>
          <h4 data-test-id={location}>View Location</h4>
        </div>
        <div>
          <h4>{type}</h4>
        </div>
      </PostBottomArea>
    </ContactRequestContainer>
  );
}
