import BrandColors from "globalStyles/utils/colors";
import styled from "styled-components/macro";

type HourStyleTypes = {
  isSelected?: boolean;
  isHidden?: boolean;
  itemCount?: number;
  num?: number;
};

export const PickerSelectionContainer = styled.div`
  margin: auto 0;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 11px 15px #0005;
  animation: fade-in 250ms ease-in-out;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: ${BrandColors.background};
  padding: 30px;
  width: 90%;

  @keyframes fade-in {
   0% {
      transform: translateY(150px);
      opacity: 0;
   }
   100% {
      transform: translateY(0);
      opacity: 1;
   }
}
`;

export const TimePickerContainer = styled.div`
  background: ${BrandColors.background};
  width: 100%;
  display: flex;
  position: relative;
  margin-top: 50px;
`;

export const TimePickerPopup = styled.div`
  position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   display: flex;
   justify-content: center;
   align-items: flex-end;
   z-index: 99998;
`;

export const ModalBackground = styled.div`
  background: rgba(0, 0, 0, 0.4);
  height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
`;

export const TimeOptionArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 28px;
`;

export const HourTimePicker = styled.div`
  position: relative;
  width: 60px;
  overflow: hidden;
  z-index: 100;
  /* margin-right: 5px; */
`;

export const CellHour = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: end;
  align-items: center;
  /* user-select: none; */
  transition: all 100ms linear;
`;

export const InnerCellHour = styled.div`
  width: fit-content;
  height: 100%;
  transition: all 100ms linear;
  cursor: pointer;
  border-radius: 7px;
  line-height: 35px;
  text-align: center;
  display: flex;
  justify-content: end;
  align-items: center;
  font-size: ${({ isSelected }: HourStyleTypes) => (isSelected ? "38px" : "34px")};
  color: ${({ isSelected }: HourStyleTypes) => (isSelected ? "#f7f7f7" : "#666")};
  padding: 0 10px;
`;

export const CancelArea = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
  }
`;

export const OptionTimerDiv = styled.div`
  border: 1px solid #44a4be;
  border-radius: 36px;
  padding: 4px 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #abb7c6;
  cursor: pointer;
  margin-right: 9px;
`;

export const WheelContainer = styled.div`
  height: 170px;
  width: 100px;
  overflow: scroll;
  position: relative;
  margin-top: -10px;
  margin-bottom: 30px;
  height: 150px;

  ::-webkit-scrollbar {
    width: 0.8rem;
    display:none;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 0.4rem;
    display:none;
  }
`;

export const NumberList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  height: 10px;
  flex-direction: column;
  justify-content: center;
  height: ${({ itemCount }:HourStyleTypes) => !itemCount? null : itemCount * 50}px;
`;

export const NumberItem = styled.li`
  font-size: 38px;
  font-weight: ${({ isSelected }:HourStyleTypes) => (isSelected ? 'bold' : 'normal')};
  color: ${({ isSelected }:HourStyleTypes) => (isSelected ? 'white' : '#55677C')};
  cursor: pointer;
  user-select: none;
  padding: 5px;
  text-align: center;
  height: 50px;
  margin-top: ${({ num, isSelected }:HourStyleTypes) =>(num === 0 && isSelected ? '100px' : null)};
`;