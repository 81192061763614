import { FC } from "react";
import { MapPointerIconStyle } from "./style";
import { PROFILEIMAGEICON } from "assets/assets";
import { MapPointerIcon } from "assets/svg";

type Props = {
  lat: string | number;
  lng: string | number;
  size?: string | number;
  text?: any;
  profilePhoto?: string | null;
};

export const MapPointer: FC<Props> = (props) => {
  const avatar = !props?.profilePhoto ? PROFILEIMAGEICON : props?.profilePhoto;
  return (
    <MapPointerIconStyle lat={props.lat} lng={props.lng}>
      <div className="map_content">
        <MapPointerIcon />
        <img src={avatar} alt="marker" />
      </div>
    </MapPointerIconStyle>
  );
};
 