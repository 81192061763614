import {
  makeDeleteRequestWithAxios,
  makePostRequestWithAxios,
  makePutRequestWithAxios,
} from "requests/requests";
import { GET_CIRCLES, updateCircleUrl } from "routes/server";
import { CIRCLES_URL } from "routes/frontend";
import { handleTimePickerValueType } from "pages/checkin";

export function setTimer(set: Function, data:handleTimePickerValueType) {
  set({
    timer: data
  })
}

export function createCheckin(get: Function, body: any, navigate: any) {
  const { setCircleLoading, refreshSelectedContacts }: any = get();
  setCircleLoading(true);
  makePostRequestWithAxios(GET_CIRCLES, body)
    .then((res: any) => {
      setCircleLoading(false);
      if (res?.success) {
        refreshSelectedContacts()
        navigate(CIRCLES_URL);
      }
    })
    .catch((err) => {
      setCircleLoading(false);
      console.log({ err });
    });
}

export function getCheckin(
  get: Function,
  body: any,
  navigate: any,
  circleId: string
) {
  const { setCircleLoading, refreshSelectedContacts }: any = get();
  setCircleLoading(true);
  const url = updateCircleUrl(circleId);
  makePutRequestWithAxios(url, body)
    .then((res: any) => {
      setCircleLoading(false);
      if (res?.success) {
        refreshSelectedContacts()
        navigate(CIRCLES_URL);
      }
    })
    .catch((err) => {
      setCircleLoading(false);
      console.log({ err });
    });
}

export function deleteCircle(get: Function, navigate: any, circleId: string) {
  const { setCircleLoading, refreshSelectedContacts }: any = get();
  setCircleLoading(true);
  const url = updateCircleUrl(circleId);
  makeDeleteRequestWithAxios(url)
    .then((res: any) => {
      setCircleLoading(false);
      if (res?.success) {
        refreshSelectedContacts()
        navigate(CIRCLES_URL);
      }
    })
    .catch((err) => {
      setCircleLoading(false);
      console.log({ err });
    });
}

export function setCircleLoading(set: Function, value: boolean) {
  set((prev: any) => ({
    ...prev,
    isLoading: value,
  }));
}

export function refreshSelectedContacts(set: Function) {
  set((prev: any) => ({
    ...prev,
    selectedContacts: {},
  }));
}

export function addToSelectedContacts(
  contactId: string,
  get: Function,
  set: Function
) {
  const { selectedContacts }: any = get();
  const updated = {
    ...selectedContacts,
    [contactId]: contactId,
  };
  set((prev: any) => ({
    ...prev,
    selectedContacts: {
      ...prev.selectedContacts,
      ...updated,
    },
  }));
}

export function removeFromSelectedContacts(
  contactId: string,
  get: Function,
  set: Function
) {
  const { selectedContacts }: any = get();
  delete selectedContacts[contactId];
  set((prev: any) => ({
    ...prev,
    selectedContacts: {
      ...prev.selectedContacts,
    },
  }));
}
