import BrandColors from "globalStyles/utils/colors";
import styled from "styled-components/macro";

export const MapContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${BrandColors.background};
  display: flex;
  flex-direction: column;
  color: white;
  overflow-x: hidden;
  padding-bottom: 80px;
`;

export const MapInfoAreaContainer = styled.div`
  width: 280px;
  /* height: 150px; */
  border-radius: 10px;
  background: rgba(68, 164, 190, 0.5);
  backdrop-filter: blur(4px);
  position: fixed;
  /* top: 50%; */
  left: 50%;
  transform: translate(-50%);
  bottom: 3%;
  z-index: 9;
  padding: 16px;
`;

export const ActivityArea = styled.div`
  width: 100%;
  color: white !important;
  border-bottom: 2px solid white !important;
  display: flex;
  align-items: center;
  padding-bottom: 8px;

  div {
    h1 {
      text-transform: uppercase;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 12px;
    }
  }
`;

export const ActivityImageCircle = styled.div`
  width: 50px;
  height: 50px;
  border: 2px solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;

export const ActivityImage = styled.img`
  width: 50px;
  height: 50px;
  border: 2px solid white;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
`;

export const ActivityProfileArea = styled.div`
  width: 100%;
  color: white !important;
  display: flex;
  align-items: center;
  /* border-top: 2px solid white; */
  padding-top: 8px;

  div {
    h1 {
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 4px;
    }
  }
`;

export const BackButtonArea = styled.div`
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(4px);
  border-radius: 10px;
  position: fixed;
  left: 60px;
  transform: translate(-50%);
  top: 2%;
  z-index: 9;
  color: white;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;


export const MapPointerIconStyle = styled.div``
