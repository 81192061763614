import { IS_PRODUCTION, USER_PROFILE_KEY } from "constants/constants";
import useAppNavigator from "./useAppNavigator";
import { useSecureStorage } from "./useSecureStorage";

const TRIGGER_TEAM: string[] = ["letsgettechnical007@gmail.com"];

const OUTSIDE_TRIGGER: string[] = [
  // 'adenekandamilola400@gmail.com',
  // 'piusfestus3@gmail.com',
  // 'tomiogunsiji@gmail.com',
  // 'christopher.adingupu@gmail.com',
  // 'balogunmumeen1@gmail.com',
  // 'onibokunsamuel63@gmail.com',
  // 'ohakweprincechukwuemeka@gmail.com',
];

export const FEATURES = {
  CHECKIN: {
    allowedUsers: [...TRIGGER_TEAM, ...OUTSIDE_TRIGGER],
    allowAllUsers: false,
  },
  NEW_CONTEST_CARDS: {
    allowedUsers: [...TRIGGER_TEAM, ...OUTSIDE_TRIGGER],
    allowAllUsers: false,
  },
};

function useFeatureGate() {
  const [profile] = useSecureStorage(USER_PROFILE_KEY, "");
  const { browserState } = useAppNavigator();
  const userEmail =
    profile?.email || browserState?.email || browserState?.user?.email;
  const isUserEligibleForFeature = (feature: any) => {
    if (!userEmail) return false;
    console.log({ feature });
    if (IS_PRODUCTION && !feature.allowAllUsers) {
      console.log({ userEmail });
      return feature.allowedUsers.includes(userEmail.toLowerCase());
    }

    return true;
  };

  return {
    isUserEligibleForFeature,
  };
}

export default useFeatureGate;
