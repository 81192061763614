import { AddPeopleContainer, AddPeopleTab, TabChanger } from "./style";
import PageTransitionProvider from "utilities/PageTransition";
import useProfileStore from "zustandStore/profile";
import SearchTab from "./Search";
import ScanTab from "./ScanTab";
import AddPeopleHeader from "components/headers/AddPeopleHeader";

export default function AddPeople() {
  const { setCurrentTab, addPeople } = useProfileStore();
  const { currentTab } = addPeople;

  function handleChangeIndex(swipedIndex: number) {
    setCurrentTab(swipedIndex);
  }

  return (
    <PageTransitionProvider>
      <AddPeopleContainer>
        <AddPeopleHeader />
        <TabChanger
          index={currentTab}
          onChangeIndex={handleChangeIndex}
          enableMouseEvents
        >
          <AddPeopleTab>
            <SearchTab />
          </AddPeopleTab>
          <AddPeopleTab>
            <ScanTab />
          </AddPeopleTab>
        </TabChanger>
      </AddPeopleContainer>
    </PageTransitionProvider>
  );
}
