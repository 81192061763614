import { MapContainer } from "./style";
import NormalMap from "./NormalMap";

// const AnyReactComponent = ({ text }: any) => <div>{text}</div>;

// const googleKey = `AIzaSyApQp5MATSHUWicggY-ecnfsnWwe1FZFwI`;

export default function TriggerMap() {
  return (
    <MapContainer>
      <NormalMap />
    </MapContainer>
  );
}
